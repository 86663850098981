@import "../../../../../../styles/rootStyles.scss";

#heatmap {
  height: 804px;

  @media (max-width: $medium) {
    height: 676px;
  }

  .heatmap-side-panel {
    position: absolute;
    background: #ffffff;
    border: 1px solid #dfe1e6;
    height: 804px;
    @media (max-width: $medium) {
      height: 676px;
    }
    width: 500px;
    z-index: 1;
    border-radius: 6px 0px 0px 0px;
    .heatmap-side-panel-header {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 25px 54px;
      border-bottom: 2px dashed #dfe1e6;
      .dow {
        background-color: #6464ff;
        height: 32px;
        width: 32px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #ffffff;
        text-align: center;
        padding: 4px;
      }
      .hour {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #24446a;
      }
      .total {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #36a0c9;
        margin-left: 20px;
      }
      .icon--close {
        background-color: #000000;
        margin-left: auto;
        cursor: pointer;
      }
    }
    .heatmap-side-panel-data {
      padding: 36px 54px;
      height: 654px;
      @media (max-width: $medium) {
        height: 526px;
      }
      .heatmap-side-panel-filters {
        display: flex;
        border-bottom: 1px solid #484554;
        padding-bottom: 14px;
        .heatmap-side-panel-filter {
          display: flex;
          align-items: center;
          gap: 10px;
          flex-grow: 1;
          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.05em;
            color: #000000;
            margin: 0;
          }
          .treble-icon {
            cursor: pointer;
          }
        }
        .icon--filters {
          background-color: #b2b2b2;
          cursor: pointer;
          &:hover {
            background-color: #000000;
          }
        }
        .ant-dropdown {
          width: fit-content !important;
          min-width: 146px !important;
        }
      }
      .heatmap-side-panel-data-display {
        margin: 32px 0px;
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .heatmap-side-panel-data-point {
          display: flex;
          align-items: center;
          .heatmap-side-panel-count {
            margin-left: 30%;
          }
        }
      }
    }
  }

  .graph-actions {
    position: relative;
    left: calc(100% - 72px);
    width: fit-content;
    top: 36px;
  }

  .graph-data {
    gap: 30px;
    align-items: center;
    justify-content: space-evenly;

    .heatmap-headers {
      display: flex;
      gap: 40px;
      margin-right: auto;
      padding: 0px 40px;
      .heatmap-header {
        padding-right: 40px;
        border-right: 1px solid #dfe1e6;
        display: flex;
        flex-direction: column;
        gap: 12px;
        p {
          margin: 0;
          &.heatmap-header-title {
            font-size: 16px;
            line-height: 21px;
            color: #232b30;
          }
          &.heatmap-header-data {
            font-size: 27px;
            line-height: 35px;
            color: #030101;
          }
        }
        &:last-child {
          border: none;
        }
      }
    }

    .heatmap-data {
      display: flex;
      align-items: center;
      width: 85vw;
      justify-content: center;
      @media (max-width: 1180px) {
        justify-content: left;
      }
    }

    .heatmap-legend {
      padding: 16px;
      border: 1px dashed #b2b2b2;
      display: flex;
      gap: 12px;
      align-items: center;
      width: fit-content;

      p {
        margin: 0;
      }

      .heats {
        display: flex;
        gap: 4px;
        .heat {
          width: 30px;
          height: 30px;
          border-radius: 2px;
          &-1 {
            background-color: #00003d;
          }
          &-2 {
            background-color: #1e1e53;
          }
          &-3 {
            background-color: #4b4b76;
          }
          &-4 {
            background-color: #7b7b9a;
          }
          &-5 {
            background-color: #dddde5;
          }
          &-6 {
            background-color: #f9f9fa;
          }
        }
      }
    }
  }
}
