.country-dropdown .dropdown {
  .tr-icon {
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }

  .dropdown-trigger {
    .button {
      padding: 8px 17px;
      height: auto;

      font-family: "HKGrotesk";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;

      .dropdown-title {
        width: 112px;

        text-align: left;
      }
    }
  }

  .dropdown-menu {
    padding-top: 12px;

    .dropdown-content {
      padding: 0;
    }

    .country-dropdown-body {
      ul {
        li {
          .tr-icon {
            margin-top: 2px;
            height: 18px;
            width: 18px;
          }

          display: flex;
          margin-bottom: 11px;

          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }

          font-family: "HelveticaNeue";

          .country-code {
            margin-left: auto;
          }
        }
      }

      font-size: 14px;
      line-height: 22px;

      padding: 14px 20px;
    }
  }
}
