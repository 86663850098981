.treble-button {
  @import "../../styles/rootStyles.scss";
  background-color: transparent !important;
  border-color: $gray-light !important;
  border-radius: 1px;
  &:hover {
    border-color: black !important;
  }
  margin: 32px 24px;

  .icon {
    height: 12px;
    width: 12px;
    margin: 0px 12px 4px 0px !important;
  }

  p {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: $black-bis;
  }
}
