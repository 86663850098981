.country-selector-v2 {
  @import "../../styles/rootStyles.scss";
  color: rgba(0, 0, 0, 0.85);
  background-color: $white;
  flex-grow: 0;
  border: 1px solid #e6e6e6;
  border-right-width: 0px;
  .m-dropdown {
    width: 100%;
  }
  .m-dropdown__toggle {
    padding: 0px 0px 0px 12px;
  }
  .m-dropdown__wrapper {
    margin-top: 16px;
    width: fit-content;
    background-color: $white-ter;
    .m-dropdown__inner {
      height: 207px;
      overflow-y: scroll;
      overflow-x: hidden;
      .m-dropdown__body {
        background-color: $white-ter;
        padding: 0px;
        .dropdown-item {
          a {
            color: rgba(0, 0, 0, 0.85);
          }
          height: 32px;
          width: 280px;
          font-family: $family-primary;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 21px;
          padding-top: 8px;
          padding-left: 12px;
          img {
            height: 20px;
            width: 20px;
          }
          .country-item-name {
            padding-left: 8px;
          }
          .country-item-phone {
            right: 10px;
            position: absolute;
          }
        }
      }
    }
  }

  .input-group {
    .input-group-prepend {
      display: flex;
      width: 100px;
      height: 40px;

      border-radius: 2px 0px 0px 2px;

      p {
        color: $black;
      }
      .drop-content {
        .icon {
          background: #b2b2b2;
        }
      }
    }
  }
}

.flag {
  width: 20px;
  padding-bottom: 2px;
}
