@import "../../styles/rootStyles.scss";

.treble-hover-button {
  background-color: transparent !important;
  height: 32px;
  width: 32px;
  border: none !important;
  padding: 0 !important;
  &:hover {
    background-color: $gray-light !important;
    .icon {
      filter: brightness(0) saturate(100%);
    }
    @media (max-width: $tablet - 1) {
      background-color: transparent !important;
      .icon {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
          saturate(15%) hue-rotate(268deg) brightness(103%) contrast(106%);
      }
    }
  }
  @media (max-width: $tablet - 1) {
    .icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
        saturate(15%) hue-rotate(268deg) brightness(103%) contrast(106%);
    }
  }
}
.__react_component_tooltip {
  background-color: $black !important;
  font-family: $family-primary;
  font-size: 14px;
  line-height: 22px;
  &::after {
    border-bottom-color: $black !important;
  }
  border-radius: 4px !important;
  color: $white !important;
}
