.label-input-text {
  min-width: 222px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    color: #202020;
    font-size: 16px;
    font-family: "HKGrotesk";
    line-height: 20px;
  }

  .label-input-text-input-container {
    display: flex;
    form {
      width: 100%;
    }
    input {
      border: 1px solid #e6e6e6;
      border-radius: 2px;
      height: 55px;
      width: -webkit-fill-available;
      outline: #000000;
      font-family: "HKGrotesk";
      color: #1a1a1a;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      padding-left: 16px;
      &:focus {
        border: 1px solid #6464ff;
      }
    }
    .label-input-text-icon-container {
      position: relative;
      .icon {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 20px;
        height: 16px;
        background-color: rgba(214, 214, 214, 1);
        cursor: pointer;
        &:hover {
          background-color: rgba(15, 15, 15, 1);
        }
      }
    }
    &.invalid-input {
      outline: 1px solid #eb4128;
    }
  }
  .label-input-text-invalid {
    color: #eb4128;
    font-size: 15px;
    line-height: normal;
  }
}
