$iconsPath: "../../../../../assets/icons";
.tail-datetime-calendar {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: block;
  overflow: hidden;
  margin: auto;
  border-radius: 2px;
  .calendar-navi {
    border-radius: 2px;
    display: flex;
    height: 40px;
    .button-prev {
      background: url(#{$iconsPath}/Prev.svg);
      height: 10px;
      width: 10px;
      display: inline-block;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-right: auto;
      margin-top: 16px;
    }
    .button-next {
      background: url(#{$iconsPath}/Next.svg);
      height: 10px;
      width: 10px;
      display: inline-block;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-left: auto;
      margin-top: 16px;
    }
    .calendar-label {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 41px;
      color: #6464ff;
    }
    .init {
      margin-right: auto;
    }
    .end {
      margin-left: auto;
    }
  }
  .calendar-date {
    margin-top: 34px;
    height: 248px;
  }
  .calendar-days {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 276px;

    .calendar-day-name {
      display: flex;
      flex-direction: column;
      width: 32px;
      height: 28px;
      text-align: center;
      padding: 0px 8px;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
      }
    }
  }
  .calendar-week {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 276px;
    text-align: center;
    align-items: center;
    margin-top: 5px;

    .calendar-day {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
      height: 32px;
      width: 32px;
      border-radius: 2px;
      padding: 2px 20px;
      &.empty {
        color: #d6d6d6;
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        cursor: pointer;
        background: #ebebff;
      }
      &.today {
        background: #262626;
        color: white;
      }
      &.on-selection {
        background: #ebebff;
      }
    }
  }
}
