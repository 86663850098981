@import "../../../../assets/scss/variables.scss";
$assets_path: "../../../../assets/images";
$icons_path: "../../../../assets/icons";

.chat-labels {
  display: block;
  &.chat-labels-container {
    padding: 8px 27px;
  }
  .label-item {
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    padding: 5px 11px;
    margin-right: 9px;
    background: #ffffff;
    display: flex;
    align-items: center;

    &.label-default {
      color: #949494;
      border: 1px solid #949494;
    }
    &.label-red {
      color: #f06450;
      border: 1px solid #f06450;
    }
    &.label-orange {
      color: #f88923;
      border: 1px solid #f88923;
    }
    &.label-yellow {
      color: #caaa03;
      border: 1px solid #caaa03;
    }
    &.label-green {
      color: #4aaa99;
      border: 1px solid #4aaa99;
    }
    &.label-blue {
      color: #0046e1;
      border: 1px solid #0046e1;
    }
    &.label-lavender {
      color: #6464ff;
      border: 1px solid #6464ff;
    }
    &.label-classic {
      color: #115a71;
      border: 1px solid #115a71;
    }
  }
}

.color {
  &.default {
    background: #949494;
  }
  &.red {
    background: #f06450;
  }
  &.orange {
    background: #f88923;
  }
  &.yellow {
    background: #caaa03;
  }
  &.green {
    background: #4aaa99;
  }
  &.blue {
    background: #0046e1;
  }
  &.lavender {
    background: #6464ff;
  }
  &.classic {
    background: #115a71;
  }
}

.color-options {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  .color-box {
    width: 32px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      background: #b9bbbf !important;
      width: 20.81px;
      height: 20.81px;
      cursor: pointer;
    }
    .color {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      cursor: pointer;
      &.selected,
      &:hover {
        border: 1px solid #000;
      }
    }
  }
}

.icon-item {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  width: 16px;
  margin-right: 8px;
  &.label-default {
    background-image: url($assets_path + "/tag-alt-default.svg");
  }
  &.label-red {
    background-image: url($assets_path + "/tag-alt-red.svg");
  }
  &.label-orange {
    background-image: url($assets_path + "/tag-alt-orange.svg");
  }
  &.label-yellow {
    background-image: url($assets_path + "/tag-alt-yellow.svg");
  }
  &.label-green {
    background-image: url($assets_path + "/tag-alt-green.svg");
  }
  &.label-blue {
    background-image: url($assets_path + "/tag-alt-blue.svg");
  }
  &.label-lavender {
    background-image: url($assets_path + "/tag-alt-lavender.svg");
  }
  &.label-classic {
    background-image: url($assets_path + "/tag-alt-classic.svg");
  }
  &.check {
    background-image: url($assets_path + "/ok-green.svg");
  }
}

.edit-labels-dropdown {
  padding: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 10px 0px;
    .dropdown-item {
      cursor: pointer;
      &:hover {
        background: #f2f2f2;
      }
    }
  }
}

.create-chat-labels {
  .options {
    padding: 17px 16px 0px 16px;
    display: flex;
    align-items: center;
    .option {
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      color: #9dacb9;
      padding-bottom: 13px;
      margin-right: 20px;
      &.is-active {
        color: #acacb3;
        border-bottom: 2px solid #6464ff;
      }
    }
    &.edit-tag {
      cursor: pointer;
      padding-top: 0;
      height: 56px;
      .icon {
        background: #000000;
        &--arrow-back {
          width: 22px;
          margin-right: 15px;
        }
        &--close {
          margin-left: auto;
        }
      }
    }
  }
  .create-tag {
    .tag-options {
      padding: 2px 24px 20px 19px;
      border-top: 1px solid #dfe1e6;
      border-bottom: 1px solid #dfe1e6;
      .text {
        font-weight: 500;
        font-size: 15px;
        color: #000000;
        margin-top: 25px;
      }
      .input-tag {
        width: 291px;
        height: 42px;
        margin-top: 13px;
        background: #fcfcfc;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        padding-left: 11px;
        font-weight: 500;
        font-size: 14px;
        color: #262626;
        &:focus-within {
          outline: 1px solid #6464ff;
        }
      }
      .color-options {
        margin-top: 25px;
        justify-content: flex-start;
      }
    }
    .buttons {
      height: 76px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .button {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        border: none;
        &.cancel {
          padding: 0;
          margin-right: 26px;
          color: #262626;
        }
        &.create {
          background: #020a24;
          border-radius: 2px;
          color: #ffffff;
          padding: 3px 23px;
          margin-right: 16px;
        }
      }
    }
  }
  .filter {
    background: rgba(223, 225, 230, 0.24);
    padding: 19px 0px 23.2px 0px;
    .filter-options {
      display: flex;
      justify-content: center;
      align-items: center;
      .searchbar {
        width: 308px;
        margin: 0;
        .icon--search {
          background: #3c5a73 !important;
        }
        &:focus-within {
          outline: 1px solid #5a8bb9;
        }
      }
      .dropdown {
        .dropdown-trigger {
          .icon {
            background: #9dacb9 !important;
          }
        }
        .icon {
          &--order-by-time-desc {
            width: 21px;
          }
        }
        &.is-active {
          .dropdown-menu {
            width: 308px;
            display: inline-block;
            transform: translateX(0%) !important;
            top: calc(100% + 4px) !important;
            .dropdown-content {
              box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
              border-radius: 2px;
              padding: 12px 0px 12px 0px;
              .item {
                cursor: pointer;
                height: 49px;
                color: #3c5a73;
                &:hover {
                  background: #f4f6fa;
                }
                .icon {
                  margin-left: 16.13px;
                  background: #3c5a73 !important;
                }
              }
            }
          }
        }
        .dropdown-menu {
          display: none;
        }
        .item {
          display: flex;
          align-items: center;
          color: #9dacb9;
          font-weight: 500;
          font-size: 16px;
          .icon {
            margin: 0;
            margin-right: 15.35px;
          }
        }
        .button {
          width: 251px;
          height: 45px;
          border-radius: 1px solid #dfe1e6;
          border-radius: 4px;
          justify-content: unset;
          padding-left: 16.25px;
          padding-right: 17px;
          &:focus {
            border-color: #dfe1e6;
          }
          .item {
            .text {
              text-align: left;
              width: 165px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .icon {
            margin-left: auto;
          }
        }
      }
      .bar {
        height: 28px;
        border: 1px solid #dfe1e6;
        margin: 0px 14px 0px 14px;
      }
      .icon {
        width: 18px;
        height: 18px;
      }
      .lookup-icon {
        .icon {
          cursor: pointer;
          width: 18px;
          height: 18px;
          background: #9dacb9 !important;
          &--search {
            &:hover {
              background: #3c5a73 !important;
            }
          }
        }
      }
    }
  }
  .labels {
    .search-label {
      padding: 0px 16px;
      margin-bottom: 16px;
    }
    .create-label {
      .text-label {
        cursor: pointer;
        padding: 4px 16px;
        background: #fafafa;
        display: flex;
        align-items: center;
        .icon-item {
          margin-left: 16px;
        }
      }
    }
    .list-labels {
      max-height: 150px;
      overflow-y: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      .label-item {
        display: flex;
        align-items: center;
        height: 35px;
        padding-left: 26px;
        .icon--edit-tag {
          width: 18px;
          height: 18px;
          margin-right: 13.2px;
        }
        .check-box {
          width: 23px;
          height: 23px;
          margin-right: 15px;
          border: 1px solid #9dacb9;
          display: flex;
          align-items: center;
          justify-content: center;
          &.delete-label,
          &.edit-label {
            &:hover {
              border: none;
            }
          }
          &.delete-label {
            border: 1px solid #ff5a5a;
            &:hover {
              background: #ff5a5a;
              .icon {
                background: #ffffff !important;
              }
            }
            .icon {
              background: #ff5a5a;
            }
          }
          &.edit-label {
            .icon {
              width: 12px;
              height: 12px;
              background: #1981db;
            }
            &:hover {
              background: #1981db;
              .icon {
                background: #ffffff;
              }
            }
          }
          &.set-label {
            &.show .icon {
              background: #1981db;
            }
            &.not-show .icon {
              display: none;
            }
            &:hover {
              &.show {
                border: none;
                background: #1981db !important;
                .icon {
                  background: #ffffff !important;
                }
              }
              &.not-show {
                .icon {
                  display: initial;
                  background: #cedce9 !important;
                }
              }
            }
          }
          .icon {
            &--sent {
              width: 11.45px;
              height: 8.41px;
              -webkit-mask: url(#{$icons_path}/sent.svg) no-repeat center;
              mask: url(#{$icons_path}/sent.svg) no-repeat center;
            }
            &--trash {
              width: 9px;
              height: 12px;
            }
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #f3f3f3;
        }
        .label-text {
          margin-right: auto;
          max-width: 170px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.add-labels-dropdown {
  width: 376px;
  &.on-progress {
    width: 425px;
  }
  .dropdown-content {
    max-height: 60vh;
    overflow-y: auto;
    padding: 0;
    box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.15);
    border-radius: 2px;
  }
}

.labels-modal {
  .modal-body {
    padding: 0;
    .list-labels {
      max-height: 100%;
    }
  }
}
