$iconsPath: "../../../../../assets/icons";

.ant-select-dropdown {
  width: 330px !important;
  max-height: 322px !important;

  box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
  border-radius: 4px;

  .rc-virtual-list-holder {
    max-height: 200px !important;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #000000;
      border-radius: 800px;
      height: 46px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0px 10px 0px 0px;
    }
  }

  .ant-select-item {
    padding: 10px 22px;

    .ant-select-item-option-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      .label-text {
        flex-grow: 1;
        margin: 0;
      }
      height: 30px;
    }
  }

  .ant-select-item-option-selected {
    background-color: #ffffff;
    font-weight: normal;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.has-search {
    .ant-input-prefix {
      display: none;
    }
  }

  .treble-select-searchbar {
    padding: 16px;
    .ant-input-affix-wrapper-focused {
      &:hover,
      &:focus,
      &:active,
      &:focus-within {
        border-color: #6464ff !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }

    .ant-input-affix-wrapper {
      height: 42px;
      &:hover,
      &:focus,
      &:active {
        border-color: #6464ff !important;
        box-shadow: none !important;
        outline: none !important;
      }
      .ant-input-prefix {
        margin-right: 14px;
      }
      .ant-input-suffix {
        .ant-input-clear-icon {
          .anticon-close-circle {
            svg {
              fill: #000000;
            }
          }
        }
      }
    }
  }

  .select-all {
    height: 40px;
    padding: 4px 38px 10px 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .label-text {
      flex-grow: 1;
      margin: 0;
    }
    border-bottom: 1px solid #dfe1e6;
  }

  &.ant-select-dropdown-empty {
    color: #262626;
  }

  .treble-empty {
    padding: 5px 10px;
    color: #262626;
  }

  .rc-virtual-list-scrollbar-thumb {
    background-color: #000000 !important;
  }
}

.ant-select-open .ant-select-selector {
  border-color: #6464ff !important;
  box-shadow: none !important;
  width: 188px;
  color: #767676 !important;
}

.ant-select {
  height: 50px;
  width: 188px;
  flex-shrink: 0;
  &:hover,
  &:active {
    border-color: #6464ff !important;
    .ant-select-selector {
      border-color: #6464ff !important;
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
      border-color: #6464ff !important;
    }
  }

  .ant-select-arrow {
    .icon {
      background-color: #767676;
    }
  }
  &.has-value {
    .ant-select-selector {
      border-color: #6464ff;
      color: #6464ff;
    }
    .ant-select-arrow {
      .icon {
        background-color: #6464ff;
      }
    }
  }
  .ant-select-selector {
    height: 100% !important;
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
      .label-text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .treble-icon {
        display: none;
      }
    }
  }
}
