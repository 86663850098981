@import "../../styles/rootStyles.scss";

.modalv2 {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  z-index: 100;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;

  .icon-close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    &.icon--close {
      background-color: black;
      width: 18px;
      height: 18px;
      -webkit-mask-size: contain;
    }
  }

  .overlay {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: -10;
    position: absolute;
    background: #0a0b2b;
    opacity: 0.63;
  }
  .modal-content {
    margin-top: 170px;
    width: 500px;
    height: max-content;
    max-height: 700px;
    background-color: #ffffff;
    overflow: auto;
    box-shadow: 0px 20px 28px rgba(15, 29, 44, 0.35);
    border-radius: 4px;
    @media (max-height: 900px) {
      margin: 50px;
      max-height: calc(100vh - 150px);
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 800px;
      background: #9dacb9;
    }
    .modal-header {
      width: -webkit-fill-available;
      padding-left: 36px;
      padding-right: 36px;
      height: 100px;
      display: flex;
      align-items: center;
    }
    .modal-body {
      width: -webkit-fill-available;
      padding: 28px 36px;
    }
    .modal-footer {
      width: -webkit-fill-available;
      height: 100px;
      display: flex;
      align-items: center;
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (max-width: $tablet - 1) {
      width: 100% !important;
      height: 100% !important;
      max-height: none;
      margin: 0;
      border-radius: unset;
      padding: 30px 17px;
      .modal-body {
        padding: 28px 0px;
      }
      .modal-footer {
        height: fit-content;
      }
    }
  }
}
