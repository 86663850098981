@import "../../../../../../styles/rootStyles.scss";

#conversation-count {
  height: 700px !important;
  display: block !important;

  .conversation-count {
    z-index: 1;
    .conversation-header {
      display: flex;
      gap: 55px;
      background-color: transparent !important;
      padding: 32px 57px 0px 50px !important;
      justify-content: flex-start;
      flex-direction: row;
      min-width: 1164px;

      .disabled {
        color: #b2b2b2 !important;
      }

      .header-item {
        display: flex;
        flex-direction: column;
        color: black;
        font-style: normal;

        p {
          font-weight: 400;
          font-size: 24px;
          line-height: 31px;

          &.inline {
            display: inline-block;
          }
        }

        .small-info {
          span {
            font-size: 16px;
            font-weight: 400;

            b {
              font-weight: 500;

              &.end {
                margin-left: 23px;
              }
            }
          }
        }

        &.middle {
          padding-left: 55px;
          border-left: 1px solid #dfe1e6;
        }

        .line {
          width: 34px;
          display: inline-block;
          margin: 0px 0px 5px 11px;
          &-red {
            background: #ff4668;

            &.disabled {
              background: #b2b2b2;
            }
          }

          &-dashed {
            background: transparent;
            border-bottom: 3px dashed #00003d;

            &.disabled {
              border-bottom: 3px dashed #b2b2b2;
            }
          }
        }

        .icon--filters {
          background-color: #b2b2b2;
          cursor: pointer;
          &:hover {
            background-color: #000000;
          }
        }

        &.end {
          margin-left: auto;

          .actions {
            display: flex;
            flex-direction: column;

            a {
              .icon {
                background-color: #b2b2b2;
                display: inline-flex;
                margin-top: 22px;
                cursor: pointer;
                &:hover {
                  background-color: #000000;
                }
              }
            }
          }
        }
      }
    }

    .conversation-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 144px;
      padding: 65px 110px 50px 50px;
      justify-content: center;

      .conversation-sidebar {
        display: flex;
        flex-direction: column;
        height: 295px;
        overflow-y: auto;
        padding-right: 10px;

        &.disabled {
          color: #b2b2b2 !important;
        }

        .sidebar-item {
          display: flex;
          flex-direction: row;
          font-weight: 500;

          &.middle {
            margin-top: 56px;
          }

          .label-color {
            width: 4px;
            height: 62px;
            display: flex;
            flex-direction: column;
          }

          .info {
            display: flex;
            flex-direction: column;

            p {
              font-size: 18px;
              margin-left: 12px;
              margin-bottom: 12px;
            }

            .small-info {
              margin-left: 12px;
              span {
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;

                b {
                  font-weight: 500;
                }

                &.end {
                  margin-left: 12px;
                }
              }
            }
          }
        }
      }

      .conversation-chart {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .chart-options {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .ant-select {
            width: 124px;

            .ant-select-selection-placeholder {
              color: #232b30;
            }

            .ant-select-selector {
              border: 1px solid #d9d9d9;
              color: #232b30;
            }
          }

          .icon {
            background-color: #000000;
          }
        }

        .chart-body {
          height: 346px;
          margin-top: 30px;
          overflow-x: auto;
          overflow-y: hidden;

          .recharts-wrapper {
            .recharts-surface {
              .recharts-curve {
                &.recharts-tooltip-cursor {
                  display: none;
                }
              }
              .recharts-active-dot {
                circle {
                  box-shadow: 0px 0px 14px #fe4668;
                  cursor: pointer;
                }
              }
            }
            .recharts-legend-wrapper {
              display: none;
            }
            .recharts-tooltip-wrapper {
              .custom-tooltip {
                display: flex;
                flex-direction: column;
                background: #ffffff;
                box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
                border-radius: 4px;

                .row {
                  display: flex;
                  flex-direction: row;
                }

                .col {
                  display: flex;
                  flex-direction: column;
                }

                .tooltip-header {
                  p {
                    margin-left: 12px;
                    font-size: 16px;
                    font-weight: 500;
                  }
                  padding: 21px 25px 18px 22px;
                  border-bottom: 1px dashed #dfe1e6;
                }

                .tooltip-body {
                  padding: 22px 25px 0px 22px;
                  font-family: "HK Grotesk";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 20px;

                  p {
                    font-size: 15px;
                    display: inline;
                    margin-bottom: 31px;

                    &.value {
                      margin-left: 96px;
                    }
                  }

                  .item {
                    align-items: center;
                    text-align: center;
                    margin-bottom: 21px;
                    .item-color {
                      height: 13px;
                      width: 13px;
                    }

                    p {
                      text-align: center;
                      margin: 0px 0px 0px 12px;
                    }

                    .value {
                      margin-left: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
