@import "../../../assets/scss/variables.scss";

// .main-content{
// 	background-color: #F8F8F8;
// }

.chat-stats {
  background-color: white;
  overflow: auto;

  .general-stats-container {
    margin-top: 12px;
  }

  .columns {
    .column:first-child {
      padding-left: 0px;
    }
    .column:last-child {
      padding-right: 0px;
    }
  }

  .container.is-fluid {
    margin-left: 0px;
    margin-right: 0px;
  }

  #conversation-stats-navbar {
    padding: 24px;
    padding-bottom: 0;

    margin-bottom: 48px;

    .DateRangePicker {
      height: 40px;

      margin-right: 16px;

      .DateRangePickerInput {
        height: 40px;
        overflow: hidden;

        .DateInput {
          height: 100%;

          input {
            height: 100%;

            font-family: HKGrotesk;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;

            line-height: 22px;
            padding: 5px 11px;
          }
        }

        button {
          padding: 5px 11px;

          svg {
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }

  #conversation-stats-header {
    padding: 0px 24px;

    h1 {
      font-family: "SpaceGrotesk";
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 22px;
      color: $black;
      margin-bottom: 6px;
    }

    h2 {
      font-family: "HKGrotesk";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
    }

    .selected-chats {
      display: flex;
      align-items: center;
      gap: 10px 20px;
      h2 {
        color: #5087ff;
      }
      .treble-hover-button {
        border: 1px solid #e9e9e9 !important;
        border-radius: 2px;
      }
    }
  }

  #conversation-stats-filters {
    margin-top: 16px;
    padding: 0px 24px;
    .dropdown-search {
      margin-right: auto;
      .button {
        border: 1px solid #e9e9e9;
      }
      input {
        background-color: white;
        border: 1px solid transparent;
        &.focus-visible {
          box-shadow: none;
          border-color: black;
        }
      }
    }
    .multiple-select-dropdown {
      &.is-active {
        .dropdown-trigger {
          button {
            border-color: black;
          }
        }
      }
      .dropdown-trigger {
        button:focus {
          border-color: black;
        }
      }
      .dropdown-menu {
        .dropdown-content {
          .dropdown-item {
            .ico-check {
              display: none;
              &.active {
                display: block;
              }
            }
          }
        }
      }
    }
    .time-dropdown {
      .dropdown-content {
        a {
          margin-right: 0;
        }
      }
    }
    a {
      margin-right: 24px;
      color: rgba(54, 54, 54, 0.85);
      text-decoration-line: underline;
      font-weight: 300;
      &:hover {
        font-weight: 500;
      }
    }
  }

  .stat-box {
    padding: 16px 23px;
    border-radius: 2px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;

    p {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
    }

    h1 {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;

    .ico-check {
      opacity: 0;
      width: 11px;
      height: 9px;
      margin-right: 14px;
      background-image: url("../../../assets/images/check.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      &.active {
        opacity: 1;
      }
    }
  }

  .table-container {
    margin: 24px;
    background-color: white;

    > div {
      overflow-x: initial;
      overflow-y: initial;
    }

    .rdt_TableHead {
      .rdt_TableHeadRow {
        background-color: #f7f8f9;
        border-bottom: none;
      }
    }

    .rdt_TableCol {
      padding: 18px 16px;
      font-size: 15px;

      div {
        white-space: initial;
      }

      &:last-child {
        border-right: none;
      }
    }

    .rdt_TableCell {
      padding: 24px 16px;
      font-size: 15px;

      &:last-child {
        border-right: none;
      }

      .react-data-table--cell-content {
        white-space: initial;
        word-wrap: break-word;
        overflow: visible;
      }

      input {
        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .rdt_TableRow {
      &:hover {
        .rdt_TableCell {
          background-color: rgba(45, 216, 129, 0.05);
        }
      }
      .rdt_TableCell {
        div {
          .chat-labels {
            display: flex;
            flex-wrap: wrap;
            .label-item {
              width: fit-content;
              word-wrap: break-word;
              hyphens: auto;
              white-space: normal;
            }
          }
        }
      }
    }

    header.rdt_TableHeader {
      display: none;
      // padding: 0px;
      // font-size: 18px;
      // padding-left: 4px;
      // div{
      // 	font-weight: 600;
      // }
    }

    header:not(.rdt_TableHeader) {
      padding: 0px;
      margin-bottom: 24px;
    }

    .table-header {
      width: 100%;
      display: flex;
      align-items: center;

      h1 {
        margin-left: 4px;
        margin-right: auto;
        font-size: 18px;
        color: $black-color;
        font-weight: 600;
      }

      .dropdown-search {
        margin-right: 16px;
      }

      .header-dropdown {
        .dropdown-trigger {
          button {
            // background-color: red;
            height: 33px;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
              max-width: 160px;
              overflow: hidden;
            }

            span {
              margin-left: auto;
            }
          }
        }

        margin-right: 16px;

        &:last-child {
          margin-right: 0px;
        }
      }

      .DateRangePicker {
        height: 33px;
        margin-right: 16px;

        .CalendarDay__selected,
        .CalendarDay__selected:active,
        .CalendarDay__selected:hover {
          background: #120338;
          border: 1px double #120338;
        }

        .CalendarDay__selected_span {
          background: rgba(18, 3, 56, 0.2);
          border: 1px double rgba(18, 3, 56, 0.2);
          color: rgba(0, 0, 0, 0.65);
        }

        .CalendarDay__hovered_span,
        .CalendarDay__hovered_span:hover {
          background: rgba(18, 3, 56, 0.2);
          border: 1px double rgba(18, 3, 56, 0.2);
          color: rgba(0, 0, 0, 0.65);
        }

        .DateRangePickerInput__withBorder {
          border-radius: 4px;
        }

        .DateRangePickerInput {
          height: 33px;
          display: flex;
          align-items: center;

          button {
            padding: 0px;
            line-height: 0px;
            margin-right: 9px;

            &:focus {
              outline: none;
            }
          }

          .DateInput {
            width: unset;

            .DateInput_input {
              font-size: 15px;
              padding: 0px;
              width: 80px;
            }

            &:last-child {
              margin-right: 16px;
            }
          }

          .DateRangePickerInput_arrow {
            margin: 0px 5px;
            line-height: 0px;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  .panel .panel-navbar {
    border-bottom: none;
    h1 {
      font-family: HKGrotesk;
      font-size: 21px;
      line-height: 24px;
    }
  }
}

.time-dropdown {
  margin-right: 16px;
}

.dropdown-search {
  display: flex;
  height: 33px;
  border: 1px solid $light-gray-border-color;
  border-radius: 4px;

  .dropdown {
    .dropdown-trigger {
      button {
        // background-color: red;
        background-color: #fafafa;
        padding: 0px 16px;
        height: 31px;
        border: none;
        border-radius: 0px;
        font-size: 15px;
      }
    }
  }

  input {
    padding: 5px 16px;
    font-size: 15px;
    height: 31px;
    border: none;
  }
}

.transfers {
  .transfer-item {
    margin: 5px 10px 5px 10px;
    padding-bottom: 5px;
    border-bottom: 1px #f3f3f3 solid;
  }
}
