.searchbar {
  width: -webkit-fill-available;
  height: 43px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  input {
    width: calc(100% - 80px);
    height: 36px;
    background-color: #ffffff;
    border: none;
    padding: 0px;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
      color: #3c5a73;
    }
    &:focus {
      outline: none;
    }
  }
  .icon {
    &--search {
      background: #3c5a73;
      height: 18px;
      width: 18px;
      margin-left: 17.25px;
      margin-right: 11.26px;
    }
    &--search-cancel {
      cursor: pointer;
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }
  }
  &:focus-within {
    outline: 1px solid #6464ff;
  }
}
