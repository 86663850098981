@import "../../assets/scss/variables.scss";
$assets_path: "../../assets/images";

.alert-loader {
  width: 425px;
  padding: 38px 32px;
  display: flex;
  align-items: center;
  border-right: 3px solid #6aaaaa;
  p {
    color: #020a24;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    max-width: 354px;
  }
  .icon {
    width: 32px !important;
    height: 32px !important;
  }
  .loading {
    background-image: url(#{$assets_path}/loader.svg);
    animation: spin infinite 2s linear;
  }
  .success {
    background-image: url(#{$assets_path}/success.svg);
  }
  .error {
    background-image: url(#{$assets_path}/error.svg);
  }
}
