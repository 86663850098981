#main-container {
  @import "../../styles/rootStyles.scss";
  display: grid;
  grid-template-columns: 1fr 1fr;

  .content-container {
    padding: 50px;
    padding-right: 60px;
    .over-title {
      color: #a346ff;
      font-size: 17px;
    }
    .title {
      font-size: 22px;
      margin: 40px 0px;
    }
    .content-paragraph {
      font-size: 18px;
    }
    .treble-cta {
      padding: 12px;
      width: 80%;
      background-color: #6464ff;
      border-radius: 4px;
      margin-top: 48px;
      cursor: pointer;

      .front-cta {
        color: #ffffff;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .image-container {
    background-color: #6464ff;
  }
}

.footer {
  display: flex;
  column-gap: 20px;
  justify-content: flex-end;
  align-content: center;
  padding: 20px;
}

.ant-modal {
  width: 960px !important;
  top: calc(50% - 467px / 2);
  margin: auto;

  .ant-modal-content {
    width: 960px;
    .ant-modal-close-x {
      .ant-modal-close-icon {
        background: white;
        padding: 5px;
        border-radius: 50%;
        box-shadow: -3px 3px 8px;
        svg {
          width: 9px;
          height: 9px;
        }
      }
    }
    .ant-modal-body {
      padding: 0px;
    }
  }
}
