@import "../../../styles/rootStyles.scss";

.recharts-tooltip-wrapper {
  transition: none !important;

  &:focus-visible {
    outline: none;
  }
}

.metrics {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    min-width: 1376px;
  }

  background-color: #fcfcfc;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 800px;
    height: 46px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px 10px 0px 0px;
  }

  .header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;

    display: flex;
    align-items: center;

    background-color: #1a1a1a;
    padding: 20px;

    color: #ffffff;
    font-family: $family-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
  }
  .content {
    display: flex;
    flex-direction: column;
    .filters {
      position: -webkit-sticky;
      position: sticky;

      z-index: 100;

      display: flex;
      flex-direction: row;

      padding: 25px;

      background-color: #fbfbff;

      border-bottom: 1px dashed #dfe1e6;

      &.add-shadow {
        top: 70px;
        box-shadow: 0px 4px 24px rgba(100, 100, 255, 0.26);
      }

      .main-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 28px;

        padding: 0px 18px;

        border-right: 1px dashed #dfe1e6;

        p {
          margin-bottom: 0;
          font-weight: 500;
        }
        .object-filter {
          width: 168px;
          .ant-select-selector {
            background-color: #eaf3f3;
          }
        }
      }

      .secondary-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 28px;

        padding: 0px 18px;
        flex-wrap: wrap;

        .date-filter {
          height: 50px;
        }
      }

      .icon--clean-filters {
        cursor: pointer;
        background-color: #b2b2b2;
        min-height: 20px;
        min-width: 20px;
        &:hover {
          background-color: #000000;
        }
      }

      .apply-filter {
        min-width: 204px;
        height: 50px;
        background-color: #000000;
        border-radius: 2px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
    .graphs {
      display: flex;
      flex-direction: column;
      gap: 30px;

      padding: 32px 20px;
      .graph,
      .empty-graph {
        width: 100%;
        min-height: 560px;
        height: 600px;
        display: flex;

        background-color: #ffffff;
        border-bottom: 1px solid #dfe1e6;
        border-radius: 6px 6px 0px 0px;

        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          cursor: pointer;
        }
        ::-webkit-scrollbar-thumb {
          background-color: rgba(220, 230, 235, 0.48);
          border-radius: 800px;
          height: 46px;
          &:hover {
            background-color: #3c5a73;
          }
        }
        ::-webkit-scrollbar-track {
          background-color: #fcfcfc;
          border-radius: 0px 10px 0px 0px;
        }

        .graph-actions {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .icon {
            cursor: pointer;
            background-color: #b2b2b2;
            &:hover {
              background-color: #000000;
            }
          }
        }

        .graph-data {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          overflow: auto;
        }

        .loading {
          margin: 0;
          height: 100%;
          width: 100%;
          .lf-player-container {
            height: 100%;
            display: flex;
            align-items: center;
            #lottie {
              height: 90px;
              width: 90px;
            }
          }
        }
      }
      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .empty-graph {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 584px;
        padding: 36px 50px;
        gap: 100px;
        .empty-graph-header {
          width: 100%;
          padding: 20px 24px;
          background-color: rgba(235, 235, 255, 0.22);
          border: 1px solid #6464ff;
          color: #042c54;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .empty-graph-title {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .treble-icon {
          height: 190px;
          width: 198px;
        }
      }
    }
  }
  .loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    p {
      font-weight: 500;
      font-size: 19px;
      line-height: 25px;
      color: #242424;
    }
  }
  .mobile {
    display: none;
    @media (max-width: $tablet - 1) {
      display: flex !important;
      margin-top: 100px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      .icon--close {
        background-color: #000000;
        position: absolute;
        top: 74px;
        right: 20px;
      }
      .treble-icon {
        height: 160px;
        width: 160px;
      }
      p {
        width: 80%;
        text-align: center;
      }
      .return-button {
        width: 90%;
        padding: 10px 60px;
        cursor: pointer;
        background-color: #6464ff;
        border-radius: 4px;
        color: #ffffff;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
      }
    }
  }
}

.ant-select-dropdown {
  &.object-filter {
    width: 280px !important;
  }
}

.ant-select-focused.object-filter .ant-select-selector,
.object-filter .ant-select-selector:focus,
.object-filter .ant-select-selector:active,
.object-filter .ant-select-selector:hover,
.ant-select-open.object-filter .ant-select-selector {
  border-color: #215d5d !important;
  box-shadow: none !important;
  color: #215e5e;
}

.ant-select {
  &.object-filter {
    .ant-select-arrow {
      .icon {
        background-color: #215e5e;
      }
    }
    .ant-select-selector {
      border-color: transparent !important;
    }
    &.has-value {
      &:hover,
      &.ant-select-open {
        .ant-select-selector {
          border-color: #215d5d !important;
        }
      }
      .ant-select-selector {
        border-color: transparent !important;
        color: #215e5e;
      }
      .ant-select-arrow {
        .icon {
          background-color: #215e5e;
        }
      }
    }
  }
}
