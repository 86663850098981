$fontsPath: "../fonts";

// HK Grotesk

@font-face {
  font-family: "HKGrotesk";
  src: url(#{$fontsPath}/HKGrotesk/HKGrotesk-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "HKGrotesk";
  src: url(#{$fontsPath}/HKGrotesk/HKGrotesk-SemiBold.otf);
  font-weight: 500;
}

@font-face {
  font-family: "HKGrotesk";
  src: url(#{$fontsPath}/HKGrotesk/HKGrotesk-Bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "HKGrotesk-Medium";
  src: url(#{$fontsPath}/HKGrotesk/HKGrotesk-Medium.otf);
}

// Space Grotesk

@font-face {
  font-family: "SpaceGrotesk";
  src: url(#{$fontsPath}/SpaceGrotesk/SpaceGrotesk-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url(#{$fontsPath}/SpaceGrotesk/SpaceGrotesk-SemiBold.otf);
  font-weight: 500;
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url(#{$fontsPath}/SpaceGrotesk/SpaceGrotesk-Bold.ttf);
  font-weight: 600;
}

// Helvetica Neue

@font-face {
  font-family: "HelveticaNeue";
  src: url(#{$fontsPath}/HelveticaNeue/HelveticaNeueLt.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url(#{$fontsPath}/HelveticaNeue/HelveticaNeueMed.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url(#{$fontsPath}/HelveticaNeue/HelveticaNeueBd.ttf);
  font-weight: 600;
}
