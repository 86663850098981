@import "../../styles/rootStyles.scss";

$assets_icons: "../../assets/icons";

.file-modal {
  align-items: center;
  z-index: 101;
  .modal-content {
    position: relative;
    width: fit-content;
    max-width: 1051px;
    height: fit-content;
    margin: 0;
    .icon-close {
      position: fixed;
      top: 56px;
      right: 66px;
      width: 45px;
      height: 45px;
    }
    .modal-footer,
    .modal-header {
      display: none;
    }
    .modal-body {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0;
      overflow: hidden;
      width: fit-content;
      height: calc(100vh - 150px);
      max-height: 500px;
      .download {
        cursor: pointer;
        width: 243px;
        height: 58.62px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #272727;
        background: #ffffff;
        border-radius: 12px 0px 0px 0px;
        .icon {
          cursor: pointer;
          width: 16.15px;
          height: 19.61px;
          margin-left: 17.84px;
        }
      }
    }
    @media (max-width: $tablet - 1) {
      .icon-close {
        position: absolute;
        top: 24px;
        right: 24px;
      }
      .icon--modal-close {
        background: #000000;
        -webkit-mask: url(#{$assets_icons}/close.svg) no-repeat center;
        mask: url(#{$assets_icons}/close.svg) no-repeat center;
      }
      .modal-header,
      .modal-footer {
        display: block;
      }
      .modal-body {
        position: inherit;
        align-items: center;
        height: unset;
        margin: auto;
        img {
          max-width: 100%;
          height: auto;
          overflow: inherit;
        }
        .download {
          display: none;
        }
      }
      .modal-footer {
        display: block;
        margin-top: 100px;
        .button {
          width: -webkit-fill-available;
          border: none;
          background: #020a24;
          color: #ffffff;
        }
      }
    }
  }
}
