@charset "utf-8";

@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$black-bis: #1a1a1a;
$grey: #b2b2b2;
$grey-20: #202020;
$grey-26: #262626;
$grey-39: #393939;
$teal: #96c3c3;
$blue-light: #8cafff;
$white-ter: #f5f5f5;
$blue: #5087ff;
$lavender: #9b9bff;
$white: #ffffff;
$classic-light: #afc3d2;
$yellow-lighter: #fffae6;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

$main-color-dark: #120338;
$main-color-normal: #22075e;

$stripe-color: #ff7864;

$black-color: rgba(0, 0, 0, 0.85);
$gray-color: rgba(0, 0, 0, 0.45);

$light-gray-color: #f8f8f8;
$light-gray-color-selected: #f2f2f2;
$light-gray-border-color: #e9e9e9;
$gray-color: #c4c4c4;

$purple-color: #9b9bff;
$green-color: #5ab978;
$red-color: #f06450;
$red: red;

$black: #000000;
$black-bis: #1a1a1a;
$black-light: #171717;
$gray-light: #e6e6e6;
$gray-dark: #767676;

$tablet: 768px;
$desktop: 1024px;

$large: 1920px;
$medium: 1440px;
$small: 1280px;

$family-primary: "HKGrotesk";

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

@import "./textStyles.scss";
@import "./elements.scss";
@import "./classes.scss";
