$light-gray-border-color: #e9e9e9;
$black-color: rgba(0, 0, 0, 0.85);

.login-screen {
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  background-color: #afc3d2;

  .login {
    width: 100%;
    max-width: 360px;

    display: flex;
    flex-direction: column;
    align-items: center;

    p.login {
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 23px;
      line-height: 34px;
      color: #000000;
    }

    .treble-logo {
      width: 134px;
      height: 106px;
      background-image: url("../../assets/images/Treble_agents.svg");
    }
    .invalid-hubspot-agent {
      background: #ffebe6;
      border: 1px solid #eb4128;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      h6 {
        margin-bottom: 8px;
      }
    }

    .error-logout {
      padding: 24px;
      border-radius: 4px;
      margin-top: 50px;
      background-color: #e17768;
      .error-message {
        margin-bottom: 16px;
        font-weight: bold;
      }
    }

    .input-container {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .label-input {
      color: #1a1a1a;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 16px;
    }

    .input-text {
      border: 1px solid #000000;
      border-radius: 2px;
      background: none;
      outline: none;
    }

    .input-text:focus {
      border: 2px solid #000000;
      background: white;
    }

    .msg-error {
      color: #eb4128;
    }

    .btn-dark {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 24px;
      height: 48px;
    }

    .link {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: $black-color;
      text-decoration: underline;
    }
  }
}
