@import "../../../../styles/rootStyles.scss";

.contact-modal {
  .modal-content {
    width: 495px;
    .modal-header {
      align-items: center;
      height: 100px;
      font-size: 22px;
      font-weight: 500;
      color: #262626;
      border-bottom: 1px solid #dfe1e6;
    }
    .modal-body {
      h6 {
        margin: 0 !important;
        margin-bottom: 12px !important;
        font-weight: 600;
        font-size: 16px;
        color: #1a1a1a;
      }
      .name-input,
      .cellphone-input {
        height: 56px;
        border: 1px solid #dfe1e6;
        border-radius: 4px;
        &:focus {
          border: 1px solid #6464ff;
        }
      }
      .cellphone {
        margin: 0;
        .contact-phone {
          display: flex;
          align-items: center;
          .flag-span {
            margin-right: 6px;
            img {
              border-radius: 4px;
            }
          }
        }
        .input-group {
          input {
            border-radius: 0px 4px 4px 0px;
          }
          .country-selector-v2 {
            border-radius: 4px 0px 0px 4px;
            background: #fcfcfc;
            display: flex;
            align-items: center;
            .input-group-prepend {
              background: #fcfcfc;
              .m-dropdown__toggle {
                padding: 0;
                padding-top: 13px;
              }
              .drop-content {
                align-items: center;
                p {
                  padding: 0px 6px 0px 6px;
                }
                .flag-span {
                  width: 19px;
                  img {
                    width: 100%;
                    border-radius: 4px;
                  }
                }
                .icon {
                  width: 9px;
                  height: 5px;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
    .modal-footer {
      padding-top: 34px;
      padding-bottom: 34px;
      .footer-buttons {
        margin-left: auto;
      }
      button {
        font-weight: 400 !important;
        font-size: 18px !important;
        margin-left: 35px;
        border: none;
        &:focus {
          box-shadow: none;
        }
      }
      .treble {
        background: #1a1a1a;
        padding: 0px 27px;
        color: #ffffff;
      }
      .danger,
      .none {
        padding: 0;
        background: none;
      }
      .grey {
        background: #b2b2b2;
        color: #ffffff;
      }
    }

    @media (max-width: $tablet - 1) {
      padding: 0;
      .modal-header {
        height: 75px;
        padding: 0px 17px;
        .session {
          display: flex;
          align-items: center;
          .icon {
            width: 19px;
            height: 19px;
          }
          span,
          .text {
            font-size: 16px;
            color: #3c5a73;
          }
          .text {
            font-weight: 400;
            margin-right: 17px;
            margin-left: 14px;
          }
          span {
            font-weight: 700;
          }
        }
      }
      .modal-body {
        margin: 30px 17px 0px 17px;
        padding: 0;
        padding-bottom: 26px;
        border-bottom: 1px dashed #dfe1e6;
        .contact-info {
          .contact-photo,
          .link-helpdesk,
          h4 {
            display: flex;
            justify-content: center;
          }
          .contact-body,
          .contact-owner,
          hr {
            display: none;
          }
        }
        .files-and-docs {
          .month-groups {
            margin: 0;
          }
        }
        .chat-labels {
          flex-wrap: unset;
          display: flex;
          padding: 0;
          margin-top: 27px;
          margin-bottom: 18px;
          overflow: auto;
          .label-item {
            min-width: fit-content;
          }
        }
      }
      .modal-footer {
        padding: 0px 17px;
        justify-content: center;
        align-items: unset;
        .footer-buttons {
          margin: 0;
          display: flex;
          flex-flow: column;
          width: -webkit-fill-available;
        }
        button {
          margin: 0;
          margin-top: 41px;
          height: 53px;
        }
        .treble {
          order: 0;
        }
        .danger {
          order: 1;
        }
        .none {
          order: 2;
        }
      }
    }
  }
}
