@import "../../../../../../styles/rootStyles.scss";

#satisfaction {
  height: 660px;

  .selection-banner {
    width: 100%;
    display: flex;
    background-color: #fffbe5;
    padding: 14px 28px;
    align-items: center;
    gap: 16px;
    .icon {
      background-color: #000000;
      cursor: pointer;
    }
  }

  .satisfaction-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .satisfaction-data {
    display: flex;
    width: 100%;
    .satisfaction-side-panel {
      min-width: 370px;
      border-right: 1px dashed #e8e8e8;
      padding: 36px 42px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .satisfaction-side-panel-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        color: #030101;
        margin: 0;
      }
      .ratings {
        display: flex;
        flex-direction: column;
        padding: 50px 40px;
        width: 100%;
        gap: 32px;
        .rating {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.date-rating {
            p {
              color: #1f6c8a;
            }
          }
          p {
            margin: 0;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #232b30;
          }
          span {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }
      }
      .click-tooltip {
        width: 270px;
        border: 1px dashed #b2b2b2;
        padding: 12px 20px;
      }
    }

    .graph-actions {
      position: relative;
      left: calc(100% - 72px);
      width: fit-content;
      top: 36px;
      z-index: 2;
    }

    .graph-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: hidden;

      .graph-extra {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: sticky;
        margin-bottom: 24px;
        z-index: 1;

        .satisfaction-header {
          padding: 32px 52px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
          flex-wrap: wrap;

          &.is-hovering {
            p {
              color: #b2b2b2;
            }
            .satisfaction-trend {
              .trend {
                &.positive,
                &.negative {
                  color: #b2b2b2;
                  border-color: #b2b2b2;
                  .icon {
                    background-color: #b2b2b2;
                  }
                }
              }
              p {
                color: #b2b2b2;
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 24px;
            line-height: 31px;
            margin: 0;
            color: #030101;
          }

          .satisfaction-trend {
            display: flex;
            gap: 20px;
            align-items: center;

            .trend {
              padding: 4px 8px;
              background: rgba(255, 255, 255, 0.1);
              border-radius: 2px;
              display: flex;
              gap: 8px;
              &.positive {
                border: 1.3px solid #008b6c;
                color: #008b6c;
                .icon {
                  background-color: #008b6c;
                }
              }
              &.negative {
                border: 1.3px solid #f10432;
                color: #f10432;
                .icon {
                  background-color: #f10432;
                }
              }
            }

            p {
              font-weight: 300;
              font-size: 15px;
              line-height: 20px;
              color: #232b30;
            }
          }
        }
        .treble-select {
          width: fit-content;
          min-width: 124px;
          margin: 0 120px 0 auto;
          height: 40px;
          max-height: fit-content;
          .ant-select-selector {
            border-radius: 4px;
          }
          .ant-select-selection-placeholder {
            color: #232b30;
          }
          .ant-select-dropdown {
            width: 280px !important;
            z-index: 2;
          }
        }
      }
      .recharts-graph {
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0px 24px;
        .recharts-wrapper {
          .recharts-tooltip-wrapper {
            .satisfaction-tooltip {
              width: 252px;
              max-height: 208px;
              height: fit-content;
              background-color: #ffffff;
              box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
              border-radius: 4px;
              p {
                margin: 0;
              }
              .satisfaction-tooltip-header {
                display: flex;
                gap: 12px;
                align-items: center;
                justify-content: flex-start;
                padding: 16px 22px;
                border-bottom: 1px dashed #dfe1e6;
              }
              .satisfaction-tooltip-body {
                padding: 12px 22px 16px 22px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                .satisfaction-average {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }

                .satisfaction-trend {
                  display: flex;
                  flex-direction: column;
                  gap: 12px;

                  .trend {
                    padding: 4px 8px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 2px;
                    display: flex;
                    gap: 8px;
                    width: 100%;
                    justify-content: center;
                    &.positive {
                      border: 1.3px solid #008b6c;
                      color: #008b6c;
                      .icon {
                        background-color: #008b6c;
                      }
                    }
                    &.negative {
                      border: 1.3px solid #f10432;
                      color: #f10432;
                      .icon {
                        background-color: #f10432;
                      }
                    }
                  }

                  p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    color: #3c5a73;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
