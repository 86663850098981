@import "../../../styles/rootStyles.scss";

$assets_path: "../../../assets/images";

.snippet-container {
  background: #ffffff;
  height: -webkit-fill-available;
  overflow: auto;
  .header {
    width: -webkit-fill-available;
    height: 81px;
    background: #1a1a1a;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-weight: 600;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 24px;
    button {
      cursor: pointer;
      width: 218px;
      height: 42px;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      background: #6464ff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      .icon {
        width: 14px;
        height: 14px;
        margin-right: 13px;
      }
    }
  }

  .dropdown {
    .button {
      height: 45px;
      padding-right: 20px;
      background-color: #ffffff;
      border: 1px solid #dfe1e6;
      box-sizing: border-box;
      border-radius: 4px;
      color: #767676;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          margin: 0px;
          margin-right: 11px;
          width: 18px;
          height: 18px;
        }
      }
      .icon {
        &--dropdown-row {
          background: #000000;
          margin: 0px;
          width: 10px;
          height: 5px;
        }
      }
    }
    .dropdown-content::-webkit-scrollbar {
      display: none;
    }
    .dropdown-content {
      padding-top: 12px;
      padding-bottom: 12px;
      color: #767676;
      box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
      overflow-y: scroll;
      max-height: 217px;
      .item {
        cursor: pointer;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 400;
        height: 49px;
        display: flex;
        align-items: center;
        .icon {
          margin: 0px;
          width: 15px;
          height: 15px;
          -webkit-mask-size: contain;
          &--check {
            margin-left: auto;
          }
        }
        &:hover {
          background: #f4f6fa;
        }
      }
    }
  }

  .content {
    margin: 28px 40px;
    .empty-snippets {
      background: #fafafa;
      box-sizing: border-box;
      border: 1px solid #dfe1e6;
      border-radius: 4px;
      padding: 60px;
      .title {
        font-size: 30px;
        font-weight: 600;
        line-height: 39px;
        align-items: center;
        color: #b2b2b2;
        display: flex;
        justify-content: center;
        margin-bottom: 0px;
      }
      .body {
        font-size: 18px;
        line-height: 30px;
        color: #b2b2b2;
        width: 830px;
        margin-top: 27px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      .footer {
        margin-top: 43px;
        padding: 0px;
        display: flex;
        justify-content: center;
        button {
          cursor: pointer;
          width: 245px;
          height: 50px;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          color: #ffffff;
          border: none;
          background: #6464ff;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            width: 14px;
            height: 14px;
            margin-right: 13px;
          }
        }
      }
    }

    .filter-options {
      display: flex;
      .searchbar {
        padding-left: 17px;
        width: 416px;
        height: 45px;
        background-color: #ffffff;
        border: 1px solid #dfe1e6;
        box-sizing: border-box;
        border-radius: 4px;
        color: #767676;
        display: flex;
        align-items: center;
        input {
          width: 100%;
          height: 36px;
          color: #000000;
          font-size: 15px;
          line-height: 22px;
          border: none;
          background-color: #ffffff;
          &:focus {
            outline: none;
          }
        }
        .icon {
          margin-right: 11px;
        }
      }
      .order-by {
        margin-left: 20px;
        .button {
          width: 307px;
        }
        .icon {
          background: #000000;
        }
        .dropdown-content {
          width: 307px;
          .item {
            .icon {
              margin-right: 11px;
              width: 18px;
              height: 18px;
              &--order-by-time-desc {
                width: 21px;
              }
            }
          }
        }
      }
      .order-tag {
        .button {
          width: 198px;
        }
      }
    }

    .no-results {
      margin-top: 125px;
      padding-bottom: 100px;
      .image {
        width: 228.89px;
        height: 228.16px;
        margin: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(#{$assets_path}/noElements.svg);
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 53.83px;
      }
    }

    .snippets-grid {
      margin-top: 35px;
      padding-bottom: 40px;
      display: grid;
      grid-template-columns: repeat(3, 32%);
      grid-gap: 20px;
      @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .snippet {
        background: #ffffff;
        border: 1px solid #dfe1e6;
        box-sizing: border-box;
        height: 242px;
        .header {
          height: 57.23px;
          margin: 0px;
          padding-left: 25px;
          padding-right: 25px;
          font-weight: 600;
          font-size: 16px;
          display: flex;
          align-items: center;
          color: #ffffff;
          background-color: #5087ff;
          .text {
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .options {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: auto;
            .button {
              width: -webkit-fill-available;
              padding: 0px;
              background-color: #5087ff;
              border: none;
              .icon {
                width: 24px;
                height: 24px;
                margin: 0px;
              }
            }
            .dropdown-content {
              width: 145px;
              .item {
                .icon {
                  width: 16px;
                  height: 16px;
                  margin-right: 20px;
                }
                &:hover {
                  background: #f4f6fa;
                }
              }
            }
            .icon {
              &--snippet-edit {
                width: 33px;
                height: 33px;
                margin-left: 20px;
              }
            }
          }
        }
        .body {
          height: 120.76px;
          padding: 20px 23px;
          background-color: #ffffff;
          .text {
            width: 356px;
            height: 84px;
            font-size: 14px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            color: #1a1a1a;
          }
        }
        .footer {
          padding: 0px;
          height: 62px;
          display: flex;
          background-color: #fcfcfc;
          border-top: 1px solid #dfe1e6;
          .time {
            padding-left: 25px;
            display: flex;
            align-items: center;
            width: 150px;
            height: -webkit-fill-available;
            border-right: 1px solid #dfe1e6;
            font-weight: 600;
            font-size: 12px;
            color: #262626;
            text-transform: capitalize;
            .icon {
              margin-right: 13px;
            }
          }
          .tags {
            padding-right: 25px;
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-bottom: 0px;
            .tag {
              margin-left: 13px !important;
              height: 30px;
              font-size: 12px;
              font-weight: 600;
              border-radius: 38px;
              display: flex;
              align-items: center;
              margin: 0px;
              padding: 0px 16px 0px 16px;
              &--rose {
                color: #ff0f6b;
                background: #fff2f7;
              }
              &--grey {
                color: #4d4d4d;
                background: #e6e6e6;
              }
            }
          }
        }
      }
    }
  }
}
