.treble-popup {
  display: flex;
  padding: 0 20px;
  min-width: 460px;
  width: max-content;
  height: 50px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  position: absolute;
  z-index: 10000;
  transform: translate(-50%, -50%);
  top: 15%;
  left: 50%;

  .logo-popup {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center center;
    margin-right: 9px;

    &.error {
      background-image: url("../../assets/images/error-exclamation.svg");
    }

    &.success {
      background-image: url("../../assets/images/check-green.svg");
    }
  }
}
