$imagesPath: "../assets/images";
$iconsPath: "../assets/icons";

.input {
  background: #fafafa;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 3px;
  height: 32px;

  &::placeholder {
    color: #b2b2b2;
    font-size: 15px;
  }
}

button.btn,
.btn {
  height: 32px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 16px;

  &:focus,
  &.is-focused {
    box-shadow: none !important;
  }

  &:hover {
    opacity: 1;
  }

  &.sm {
    padding: 4px;
  }

  p {
    font-weight: normal;
    font-size: 15px;
  }

  &--black {
    background-color: #000000;

    p {
      color: white;
    }
  }

  &--white {
    background-color: white;
    border: 1px solid #e6e6e6;

    p {
      color: black;
    }
  }

  &--grey {
    background: #555555;
    color: $white;

    border: none;
  }

  &--grey-20 {
    background-color: $grey-20;
    color: $white-ter;
  }

  &--has-icon {
    .icon {
      margin-right: 8px;
    }
  }

  &.m {
    padding: 9px;

    height: auto;

    font-size: 15px;
    line-height: 22px;
  }
}

.profile-image {
  width: 32px;
  height: 32px;
  background-color: #beafff;
  border: 1px solid #e6e6e6;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  border: 1px solid #e6e6e6;

  p {
    font-weight: 500;
    font-size: 13px;
    color: white;
    line-height: 22px;
  }
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  width: 24px;
  height: 24px;

  &.size-16 {
    width: 16px;
    height: 16px;
  }

  &.size-18 {
    width: 18px;
    height: 18px;
  }

  &.size-28 {
    width: 28px;
    height: 28px;
  }

  &--language {
    background: url(#{$iconsPath}/language.svg);
  }
  &--snippet-search {
    background-image: url(#{$iconsPath}/snippet-search.svg);
  }
  &--order-by-AZ {
    -webkit-mask: url(#{$iconsPath}/order-by-AZ.svg) no-repeat center;
    mask: url(#{$iconsPath}/order-by-AZ.svg) no-repeat center;
  }
  &--order-by-time-asc {
    -webkit-mask: url(#{$iconsPath}/order-by-time-asc.svg) no-repeat center;
    mask: url(#{$iconsPath}/order-by-time-asc.svg) no-repeat center;
  }
  &--order-by-time-desc {
    -webkit-mask: url(#{$iconsPath}/order-by-time-desc.svg) no-repeat center;
    mask: url(#{$iconsPath}/order-by-time-desc.svg) no-repeat center;
  }
  &--snippet-options {
    background-image: url(#{$iconsPath}/snippet-options.svg);
  }
  &--snippet-edit {
    background-image: url(#{$iconsPath}/snippet-edit.svg);
  }
  &--snippet-time {
    background-image: url(#{$iconsPath}/snippet-time.svg);
  }
  &--check {
    -webkit-mask: url(#{$iconsPath}/check.svg) no-repeat center;
    mask: url(#{$iconsPath}/check.svg) no-repeat center;
  }
  &--edit {
    -webkit-mask: url(#{$iconsPath}/Vector.svg) no-repeat center;
    mask: url(#{$iconsPath}/Vector.svg) no-repeat center;
  }
  &--save {
    -webkit-mask: url(#{$iconsPath}/Save.svg) no-repeat center;
    mask: url(#{$iconsPath}/Save.svg) no-repeat center;
  }
  &--dropdown-row {
    -webkit-mask: url(#{$iconsPath}/dropdown-row.svg) no-repeat center;
    mask: url(#{$iconsPath}/dropdown-row.svg) no-repeat center;
  }
  &--snippet-copy {
    background-image: url(#{$iconsPath}/snippet-copy.svg);
  }
  &--snippet-delete {
    background-image: url(#{$iconsPath}/snippet-delete.svg);
  }
  &--close {
    -webkit-mask: url(#{$iconsPath}/close.svg) no-repeat center;
    mask: url(#{$iconsPath}/close.svg) no-repeat center;
  }
  &--image {
    -webkit-mask: url(#{$iconsPath}/image.svg) no-repeat center;
    mask: url(#{$iconsPath}/image.svg) no-repeat center;
  }
  &--video {
    -webkit-mask: url(#{$iconsPath}/video.svg) no-repeat center;
    mask: url(#{$iconsPath}/video.svg) no-repeat center;
  }
  &--hubspot-dashboard {
    -webkit-mask: url(#{$iconsPath}/hubspot-dashboard.svg) no-repeat center;
    mask: url(#{$iconsPath}/hubspot-dashboard.svg) no-repeat center;
  }
  &--contact-info {
    -webkit-mask: url(#{$iconsPath}/contact-info.svg) no-repeat center;
    mask: url(#{$iconsPath}/contact-info.svg) no-repeat center;
  }
  &--snippet-templates {
    -webkit-mask: url(#{$iconsPath}/snippet-templates.svg) no-repeat center;
    mask: url(#{$iconsPath}/snippet-templates.svg) no-repeat center;
  }
  &--files-and-docs {
    -webkit-mask: url(#{$iconsPath}/files-and-docs.svg) no-repeat center;
    mask: url(#{$iconsPath}/files-and-docs.svg) no-repeat center;
  }
  &--download-file {
    -webkit-mask: url(#{$iconsPath}/download-file.svg) no-repeat center;
    mask: url(#{$iconsPath}/download-file.svg) no-repeat center;
  }
  &--file-message {
    -webkit-mask: url(#{$iconsPath}/file-message.svg) no-repeat center;
    mask: url(#{$iconsPath}/file-message.svg) no-repeat center;
  }
  &--contact-message {
    -webkit-mask: url(#{$iconsPath}/contact-message.svg) no-repeat center;
    mask: url(#{$iconsPath}/contact-message.svg) no-repeat center;
  }
  &--scroll-to-message {
    background-image: url(#{$iconsPath}/scroll-to-message.svg);
  }
  &--arrow-map {
    -webkit-mask: url(#{$iconsPath}/arrow-map.svg) no-repeat center;
    mask: url(#{$iconsPath}/arrow-map.svg) no-repeat center;
  }
  &--none-icon {
    -webkit-mask: url(#{$iconsPath}/none-icon.svg) no-repeat center;
    mask: url(#{$iconsPath}/none-icon.svg) no-repeat center;
  }
  &--download-image {
    background-image: url(#{$iconsPath}/download-image.svg);
  }
  &--modal-close {
    background-image: url(#{$iconsPath}/modal-close.svg);
  }
  &--pushpin {
    -webkit-mask: url(#{$iconsPath}/pushpin.svg) no-repeat center;
    mask: url(#{$iconsPath}/pushpin.svg) no-repeat center;
  }
  &--add-highlighted {
    background-image: url(#{$iconsPath}/add-highlighted.svg);
  }
  &--allow-microphone-arrow {
    background-image: url(#{$iconsPath}/allow-microphone-arrow.svg);
  }
  &--allow-microphone-denied {
    background-image: url(#{$iconsPath}/allow-microphone-denied.svg);
  }
  &--stop-audio {
    background-image: url(#{$iconsPath}/stop-audio.svg);
  }
  &--play-audio {
    background-image: url(#{$iconsPath}/play-audio.svg);
  }
  &--send-record {
    background-image: url(#{$iconsPath}/send-record.svg);
  }
  &--stop-record {
    background-image: url(#{$iconsPath}/stop-record.svg);
  }
  &--trash {
    background-image: url(#{$iconsPath}/trash.svg);
  }
  &--record {
    -webkit-mask: url(#{$iconsPath}/voice.svg) no-repeat center;
    mask: url(#{$iconsPath}/voice.svg) no-repeat center;
  }
  &--send {
    -webkit-mask: url(#{$iconsPath}/send.svg) no-repeat center;
    mask: url(#{$iconsPath}/send.svg) no-repeat center;
  }
  &--dropdown-arrow {
    -webkit-mask: url(#{$iconsPath}/dropdown-arrow.svg) no-repeat center;
    mask: url(#{$iconsPath}/dropdown-arrow.svg) no-repeat center;
  }
  &--emoji {
    -webkit-mask: url(#{$iconsPath}/emoji.svg) no-repeat center;
    mask: url(#{$iconsPath}/emoji.svg) no-repeat center;
  }
  &--clip {
    -webkit-mask: url(#{$iconsPath}/clip.svg) no-repeat center;
    mask: url(#{$iconsPath}/clip.svg) no-repeat center;
  }
  &--whatsapp {
    background-image: url(#{$iconsPath}/whatsapp.svg);
  }
  &--whatsapp-expired {
    background-image: url(#{$iconsPath}/whatsapp-expired.svg);
  }
  &--chevron-down {
    background-image: url(#{$iconsPath}/chevron-down.svg);
  }
  &--chevron-left {
    background-image: url(#{$iconsPath}/chevron-left.svg);
  }
  &--templates {
    -webkit-mask: url(#{$iconsPath}/templates.svg) no-repeat center;
    mask: url(#{$iconsPath}/templates.svg) no-repeat center;
  }
  &--notes {
    -webkit-mask: url(#{$iconsPath}/notes.svg) no-repeat center;
    mask: url(#{$iconsPath}/notes.svg) no-repeat center;
  }
  &--delete {
    -webkit-mask: url(#{$iconsPath}/trash-mask.svg) no-repeat center;
    mask: url(#{$iconsPath}/trash-mask.svg) no-repeat center;
  }
  &--clock {
    -webkit-mask: url(#{$iconsPath}/small-clock.svg) no-repeat center;
    mask: url(#{$iconsPath}/small-clock.svg) no-repeat center;
  }
  &--jump-chat-arrow {
    -webkit-mask: url(#{$iconsPath}/jump-chat-arrow.svg) no-repeat center;
    mask: url(#{$iconsPath}/jump-chat-arrow.svg) no-repeat center;
  }
  &--search {
    -webkit-mask: url(#{$iconsPath}/search.svg) no-repeat center;
    mask: url(#{$iconsPath}/search.svg) no-repeat center;
  }
  &--download {
    -webkit-mask: url(#{$iconsPath}/download.svg) no-repeat center;
    mask: url(#{$iconsPath}/download.svg) no-repeat center;
  }
  &--filters {
    -webkit-mask: url(#{$iconsPath}/filters.svg) no-repeat center;
    mask: url(#{$iconsPath}/filters.svg) no-repeat center;
  }
  &--clean-filters {
    -webkit-mask: url(#{$iconsPath}/clean-filters.svg) no-repeat center;
    mask: url(#{$iconsPath}/clean-filters.svg) no-repeat center;
  }
  &--search-cancel {
    background-image: url(#{$iconsPath}/search-cancel.svg);
  }
  &--search-cancel-grey {
    background-image: url(#{$iconsPath}/search-cancel-grey.svg);
  }
  &--add-contact {
    background-image: url(#{$iconsPath}/add-contact.svg);
  }
  &--cancel-file {
    background-image: url(#{$iconsPath}/cancel-file.svg);
  }
  &--finish-chat {
    -webkit-mask: url(#{$iconsPath}/finish-chat.svg) no-repeat center;
    mask: url(#{$iconsPath}/finish-chat.svg) no-repeat center;
  }
  &--select-arrow {
    -webkit-mask: url(#{$iconsPath}/select-arrow.svg) no-repeat center;
    mask: url(#{$iconsPath}/select-arrow.svg) no-repeat center;
  }
  &--edit-tag {
    -webkit-mask: url(#{$iconsPath}/edit-tag.svg) no-repeat center;
    mask: url(#{$iconsPath}/edit-tag.svg) no-repeat center;
  }
  &--transfer-agent {
    -webkit-mask: url(#{$iconsPath}/transfer-agent.svg) no-repeat center;
    mask: url(#{$iconsPath}/transfer-agent.svg) no-repeat center;
  }
  &--full-screen {
    -webkit-mask: url(#{$iconsPath}/full-screen.svg) no-repeat center;
    mask: url(#{$iconsPath}/full-screen.svg) no-repeat center;
  }
  &--collapse-screen {
    -webkit-mask: url(#{$iconsPath}/collapse-screen.svg) no-repeat center;
    mask: url(#{$iconsPath}/collapse-screen.svg) no-repeat center;
  }
  &--collapse-options {
    background-image: url(#{$iconsPath}/collapse-options.svg);
  }
  &--edit-contact {
    -webkit-mask: url(#{$iconsPath}/edit-contact.svg) no-repeat center;
    mask: url(#{$iconsPath}/edit-contact.svg) no-repeat center;
  }
  &--edit-label {
    -webkit-mask: url(#{$iconsPath}/edit-label.svg) no-repeat center;
    mask: url(#{$iconsPath}/edit-label.svg) no-repeat center;
    -webkit-mask-size: contain;
  }
  &--user-circle {
    background-image: url(#{$iconsPath}/user-circle.svg);
  }
  &--user-add {
    background-image: url(#{$iconsPath}/user-add.svg);
  }
  &--sent {
    background-image: url(#{$iconsPath}/sent.svg);
  }
  &--delivered {
    background-image: url(#{$iconsPath}/delivered.svg);
  }
  &--read {
    background-image: url(#{$iconsPath}/read.svg);
  }
  &--error {
    background-image: url(#{$iconsPath}/error.svg);
  }
  &--chat {
    -webkit-mask: url(#{$iconsPath}/chat.svg) no-repeat center;
    mask: url(#{$iconsPath}/chat.svg) no-repeat center;
  }
  &--chart {
    -webkit-mask: url(#{$iconsPath}/chart.svg) no-repeat center;
    mask: url(#{$iconsPath}/chart.svg) no-repeat center;
  }
  &--inbox {
    -webkit-mask: url(#{$iconsPath}/inbox.svg) no-repeat center;
    mask: url(#{$iconsPath}/inbox.svg) no-repeat center;
  }
  &--support {
    -webkit-mask: url(#{$iconsPath}/support.svg) no-repeat center;
    mask: url(#{$iconsPath}/support.svg) no-repeat center;
  }
  &--snippets {
    -webkit-mask: url(#{$iconsPath}/snippets.svg) no-repeat center;
    mask: url(#{$iconsPath}/snippets.svg) no-repeat center;
  }
  &--exit {
    -webkit-mask: url(#{$iconsPath}/exit.svg) no-repeat center;
    mask: url(#{$iconsPath}/exit.svg) no-repeat center;
  }
  &--bell-notification {
    -webkit-mask: url(#{$iconsPath}/bell-notification.svg) no-repeat center;
    mask: url(#{$iconsPath}/bell-notification.svg) no-repeat center;
  }
  &--arrow-back {
    -webkit-mask: url(#{$iconsPath}/arrow-back.svg) no-repeat center;
    mask: url(#{$iconsPath}/arrow-back.svg) no-repeat center;
  }
  &--is-redirected {
    background-image: url(#{$iconsPath}/is-redirected.svg);
  }
  &--end-all-chats {
    background-image: url(#{$iconsPath}/end-all-chats.svg);
  }
  &--user-active {
    background-image: url(#{$iconsPath}/user-active.svg);
  }
  &--user-inactive {
    background-image: url(#{$iconsPath}/user-inactive.svg);
  }
  &--active {
    background-image: url(#{$iconsPath}/active.svg);
  }
  &--inactive {
    background-image: url(#{$iconsPath}/inactive.svg);
  }
  &--arrow-left {
    background-image: url(#{$iconsPath}/arrow-left.svg);
  }
  &--lock {
    background-image: url(#{$iconsPath}/lock.svg);
  }
  &--trash {
    -webkit-mask: url(#{$iconsPath}/trash.svg) no-repeat center;
    mask: url(#{$iconsPath}/trash.svg) no-repeat center;
  }
  &--treble {
    background-image: url(#{$iconsPath}/treble.svg);
  }
  &--cancel {
    background-image: url(#{$iconsPath}/cancel.svg);
  }
  &--chevrons {
    &-right {
      background-image: url(#{$iconsPath}/chevrons-right.svg);
    }
    &-left {
      background-image: url(#{$iconsPath}/chevrons-left.svg);
    }
  }
  &--reload {
    background-image: url(#{$iconsPath}/reload.svg);
  }
  &--trend-up {
    -webkit-mask: url(#{$iconsPath}/trend-up.svg) no-repeat center;
    mask: url(#{$iconsPath}/trend-up.svg) no-repeat center;
  }
  &--trend-down {
    -webkit-mask: url(#{$iconsPath}/trend-down.svg) no-repeat center;
    mask: url(#{$iconsPath}/trend-down.svg) no-repeat center;
  }
  &--double-arrow {
    -webkit-mask: url(#{$iconsPath}/double-arrow.svg) no-repeat center;
    mask: url(#{$iconsPath}/double-arrow.svg) no-repeat center;
  }
  &--hubspot-logo {
    -webkit-mask: url(#{$iconsPath}/hubspot-logo.svg) no-repeat center;
    mask: url(#{$iconsPath}/hubspot-logo.svg) no-repeat center;
  }
  &--check-circle {
    background-image: url(#{$iconsPath}/check-circle.svg);
  }
  &--group {
    -webkit-mask: url(#{$iconsPath}/group.svg) no-repeat center;
    mask: url(#{$iconsPath}/group.svg) no-repeat center;
  }
  &--salesperson {
    -webkit-mask: url(#{$iconsPath}/salesperson.svg) no-repeat center;
    mask: url(#{$iconsPath}/salesperson.svg) no-repeat center;
  }
  &--download-data {
    -webkit-mask: url(#{$iconsPath}/download-data.svg) no-repeat center;
    mask: url(#{$iconsPath}/download-data.svg) no-repeat center;
  }
  &--view {
    -webkit-mask: url(#{$iconsPath}/View-Icon.svg) no-repeat center;
    mask: url(#{$iconsPath}/View-Icon.svg) no-repeat center;
  }
  &--download-arrow {
    -webkit-mask: url(#{$iconsPath}/download-arrow.svg) no-repeat center;
    mask: url(#{$iconsPath}/download-arrow.svg) no-repeat center;
  }
}
