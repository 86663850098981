$loader-width: 70px;

.treble-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);

  .m-loader {
    width: $loader-width;
    height: $loader-width;
  }

  ::before {
    width: $loader-width;
    height: $loader-width;
    top: 0px;
    left: 0px;
  }

  .treble-logo {
    animation: spin infinite 2s linear;
    height: $loader-width;
    width: $loader-width;
    z-index: 1001;
    background-image: url("../../assets/images/Treble_purple.svg");
    background-size: contain;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
