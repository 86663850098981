@import "./variables.scss";

$imagesPath: "../images";

.flex {
  display: flex;
  align-items: center;

  .fill {
    flex-grow: 1;
  }
}

.btn {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  a {
    color: inherit;
    text-decoration: none;
  }

  &.small {
    border-radius: 4px;
    padding: 5px 11px;

    .icon {
      height: 16px;
      width: 16px;
    }
  }

  &.dark {
    background-color: $black;
    color: $white;
  }

  .icon {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 9px;

    &.download {
      background-image: url(#{$imagesPath}/icons/download.svg);
    }

    &.search {
      background-image: url(#{$imagesPath}/search-white.svg);
    }
  }
}

// icons
.tr-icon {
  height: 1.5rem;
  width: 1.5rem;

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;

  &.earth {
    background-image: url(#{$imagesPath}/icons/earth.svg);
  }

  // flags
  &.flag {
    &.ad {
      background-image: url(#{$imagesPath}/flags/ad.svg);
    }

    &.ae {
      background-image: url(#{$imagesPath}/flags/ae.svg);
    }

    &.af {
      background-image: url(#{$imagesPath}/flags/af.svg);
    }

    &.ag {
      background-image: url(#{$imagesPath}/flags/ag.svg);
    }

    &.ai {
      background-image: url(#{$imagesPath}/flags/ai.svg);
    }

    &.al {
      background-image: url(#{$imagesPath}/flags/al.svg);
    }

    &.am {
      background-image: url(#{$imagesPath}/flags/am.svg);
    }

    &.an {
      background-image: url(#{$imagesPath}/flags/an.svg);
    }

    &.ao {
      background-image: url(#{$imagesPath}/flags/ao.svg);
    }

    &.aq {
      background-image: url(#{$imagesPath}/flags/aq.svg);
    }

    &.ar {
      background-image: url(#{$imagesPath}/flags/ar.svg);
    }

    &.as {
      background-image: url(#{$imagesPath}/flags/as.svg);
    }

    &.at {
      background-image: url(#{$imagesPath}/flags/at.svg);
    }

    &.au {
      background-image: url(#{$imagesPath}/flags/au.svg);
    }

    &.aw {
      background-image: url(#{$imagesPath}/flags/aw.svg);
    }

    &.ax {
      background-image: url(#{$imagesPath}/flags/ax.svg);
    }

    &.az {
      background-image: url(#{$imagesPath}/flags/az.svg);
    }

    &.ba {
      background-image: url(#{$imagesPath}/flags/ba.svg);
    }

    &.bb {
      background-image: url(#{$imagesPath}/flags/bb.svg);
    }

    &.bd {
      background-image: url(#{$imagesPath}/flags/bd.svg);
    }

    &.be {
      background-image: url(#{$imagesPath}/flags/be.svg);
    }

    &.bf {
      background-image: url(#{$imagesPath}/flags/bf.svg);
    }

    &.bg {
      background-image: url(#{$imagesPath}/flags/bg.svg);
    }

    &.bh {
      background-image: url(#{$imagesPath}/flags/bh.svg);
    }

    &.bi {
      background-image: url(#{$imagesPath}/flags/bi.svg);
    }

    &.bj {
      background-image: url(#{$imagesPath}/flags/bj.svg);
    }

    &.bl {
      background-image: url(#{$imagesPath}/flags/bl.svg);
    }

    &.bm {
      background-image: url(#{$imagesPath}/flags/bm.svg);
    }

    &.bn {
      background-image: url(#{$imagesPath}/flags/bn.svg);
    }

    &.bo {
      background-image: url(#{$imagesPath}/flags/bo.svg);
    }

    &.bq {
      background-image: url(#{$imagesPath}/flags/bq.svg);
    }

    &.br {
      background-image: url(#{$imagesPath}/flags/br.svg);
    }

    &.bs {
      background-image: url(#{$imagesPath}/flags/bs.svg);
    }

    &.bt {
      background-image: url(#{$imagesPath}/flags/bt.svg);
    }

    &.bv {
      background-image: url(#{$imagesPath}/flags/bv.svg);
    }

    &.bw {
      background-image: url(#{$imagesPath}/flags/bw.svg);
    }

    &.by {
      background-image: url(#{$imagesPath}/flags/by.svg);
    }

    &.bz {
      background-image: url(#{$imagesPath}/flags/bz.svg);
    }

    &.ca {
      background-image: url(#{$imagesPath}/flags/ca.svg);
    }

    &.cc {
      background-image: url(#{$imagesPath}/flags/cc.svg);
    }

    &.cd {
      background-image: url(#{$imagesPath}/flags/cd.svg);
    }

    &.cf {
      background-image: url(#{$imagesPath}/flags/cf.svg);
    }

    &.cg {
      background-image: url(#{$imagesPath}/flags/cg.svg);
    }

    &.ch {
      background-image: url(#{$imagesPath}/flags/ch.svg);
    }

    &.ci {
      background-image: url(#{$imagesPath}/flags/ci.svg);
    }

    &.ck {
      background-image: url(#{$imagesPath}/flags/ck.svg);
    }

    &.cl {
      background-image: url(#{$imagesPath}/flags/cl.svg);
    }

    &.cm {
      background-image: url(#{$imagesPath}/flags/cm.svg);
    }

    &.cn {
      background-image: url(#{$imagesPath}/flags/cn.svg);
    }

    &.co {
      background-image: url(#{$imagesPath}/flags/co.svg);
    }

    &.cr {
      background-image: url(#{$imagesPath}/flags/cr.svg);
    }

    &.cu {
      background-image: url(#{$imagesPath}/flags/cu.svg);
    }

    &.cv {
      background-image: url(#{$imagesPath}/flags/cv.svg);
    }

    &.cw {
      background-image: url(#{$imagesPath}/flags/cw.svg);
    }

    &.cx {
      background-image: url(#{$imagesPath}/flags/cx.svg);
    }

    &.cy {
      background-image: url(#{$imagesPath}/flags/cy.svg);
    }

    &.cz {
      background-image: url(#{$imagesPath}/flags/cz.svg);
    }

    &.de {
      background-image: url(#{$imagesPath}/flags/de.svg);
    }

    &.dj {
      background-image: url(#{$imagesPath}/flags/dj.svg);
    }

    &.dk {
      background-image: url(#{$imagesPath}/flags/dk.svg);
    }

    &.dm {
      background-image: url(#{$imagesPath}/flags/dm.svg);
    }

    &.do {
      background-image: url(#{$imagesPath}/flags/do.svg);
    }

    &.dz {
      background-image: url(#{$imagesPath}/flags/dz.svg);
    }

    &.ec {
      background-image: url(#{$imagesPath}/flags/ec.svg);
    }

    &.ee {
      background-image: url(#{$imagesPath}/flags/ee.svg);
    }

    &.eg {
      background-image: url(#{$imagesPath}/flags/eg.svg);
    }

    &.eh {
      background-image: url(#{$imagesPath}/flags/eh.svg);
    }

    &.er {
      background-image: url(#{$imagesPath}/flags/er.svg);
    }

    &.es {
      background-image: url(#{$imagesPath}/flags/es.svg);
    }

    &.et {
      background-image: url(#{$imagesPath}/flags/et.svg);
    }

    &.eu {
      background-image: url(#{$imagesPath}/flags/eu.svg);
    }

    &.fi {
      background-image: url(#{$imagesPath}/flags/fi.svg);
    }

    &.fj {
      background-image: url(#{$imagesPath}/flags/fj.svg);
    }

    &.fk {
      background-image: url(#{$imagesPath}/flags/fk.svg);
    }

    &.fm {
      background-image: url(#{$imagesPath}/flags/fm.svg);
    }

    &.fo {
      background-image: url(#{$imagesPath}/flags/fo.svg);
    }

    &.fr {
      background-image: url(#{$imagesPath}/flags/fr.svg);
    }

    &.ga {
      background-image: url(#{$imagesPath}/flags/ga.svg);
    }

    &.gb {
      background-image: url(#{$imagesPath}/flags/gb.svg);
    }

    &.gd {
      background-image: url(#{$imagesPath}/flags/gd.svg);
    }

    &.ge {
      background-image: url(#{$imagesPath}/flags/ge.svg);
    }

    &.gf {
      background-image: url(#{$imagesPath}/flags/gf.svg);
    }

    &.gg {
      background-image: url(#{$imagesPath}/flags/gg.svg);
    }

    &.gh {
      background-image: url(#{$imagesPath}/flags/gh.svg);
    }

    &.gi {
      background-image: url(#{$imagesPath}/flags/gi.svg);
    }

    &.gl {
      background-image: url(#{$imagesPath}/flags/gl.svg);
    }

    &.gm {
      background-image: url(#{$imagesPath}/flags/gm.svg);
    }

    &.gn {
      background-image: url(#{$imagesPath}/flags/gn.svg);
    }

    &.gp {
      background-image: url(#{$imagesPath}/flags/gp.svg);
    }

    &.gq {
      background-image: url(#{$imagesPath}/flags/gq.svg);
    }

    &.gr {
      background-image: url(#{$imagesPath}/flags/gr.svg);
    }

    &.gs {
      background-image: url(#{$imagesPath}/flags/gs.svg);
    }

    &.gt {
      background-image: url(#{$imagesPath}/flags/gt.svg);
    }

    &.gu {
      background-image: url(#{$imagesPath}/flags/gu.svg);
    }

    &.gw {
      background-image: url(#{$imagesPath}/flags/gw.svg);
    }

    &.gy {
      background-image: url(#{$imagesPath}/flags/gy.svg);
    }

    &.hk {
      background-image: url(#{$imagesPath}/flags/hk.svg);
    }

    &.hm {
      background-image: url(#{$imagesPath}/flags/hm.svg);
    }

    &.hn {
      background-image: url(#{$imagesPath}/flags/hn.svg);
    }

    &.hr {
      background-image: url(#{$imagesPath}/flags/hr.svg);
    }

    &.ht {
      background-image: url(#{$imagesPath}/flags/ht.svg);
    }

    &.hu {
      background-image: url(#{$imagesPath}/flags/hu.svg);
    }

    &.id {
      background-image: url(#{$imagesPath}/flags/id.svg);
    }

    &.ie {
      background-image: url(#{$imagesPath}/flags/ie.svg);
    }

    &.il {
      background-image: url(#{$imagesPath}/flags/il.svg);
    }

    &.im {
      background-image: url(#{$imagesPath}/flags/im.svg);
    }

    &.in {
      background-image: url(#{$imagesPath}/flags/in.svg);
    }

    &.io {
      background-image: url(#{$imagesPath}/flags/io.svg);
    }

    &.iq {
      background-image: url(#{$imagesPath}/flags/iq.svg);
    }

    &.ir {
      background-image: url(#{$imagesPath}/flags/ir.svg);
    }

    &.is {
      background-image: url(#{$imagesPath}/flags/is.svg);
    }

    &.it {
      background-image: url(#{$imagesPath}/flags/it.svg);
    }

    &.je {
      background-image: url(#{$imagesPath}/flags/je.svg);
    }

    &.jm {
      background-image: url(#{$imagesPath}/flags/jm.svg);
    }

    &.jo {
      background-image: url(#{$imagesPath}/flags/jo.svg);
    }

    &.jp {
      background-image: url(#{$imagesPath}/flags/jp.svg);
    }

    &.ke {
      background-image: url(#{$imagesPath}/flags/ke.svg);
    }

    &.kg {
      background-image: url(#{$imagesPath}/flags/kg.svg);
    }

    &.kh {
      background-image: url(#{$imagesPath}/flags/kh.svg);
    }

    &.ki {
      background-image: url(#{$imagesPath}/flags/ki.svg);
    }

    &.km {
      background-image: url(#{$imagesPath}/flags/km.svg);
    }

    &.kn {
      background-image: url(#{$imagesPath}/flags/kn.svg);
    }

    &.kp {
      background-image: url(#{$imagesPath}/flags/kp.svg);
    }

    &.kr {
      background-image: url(#{$imagesPath}/flags/kr.svg);
    }

    &.kw {
      background-image: url(#{$imagesPath}/flags/kw.svg);
    }

    &.ky {
      background-image: url(#{$imagesPath}/flags/ky.svg);
    }

    &.kz {
      background-image: url(#{$imagesPath}/flags/kz.svg);
    }

    &.la {
      background-image: url(#{$imagesPath}/flags/la.svg);
    }

    &.lb {
      background-image: url(#{$imagesPath}/flags/lb.svg);
    }

    &.lc {
      background-image: url(#{$imagesPath}/flags/lc.svg);
    }

    &.li {
      background-image: url(#{$imagesPath}/flags/li.svg);
    }

    &.lk {
      background-image: url(#{$imagesPath}/flags/lk.svg);
    }

    &.lr {
      background-image: url(#{$imagesPath}/flags/lr.svg);
    }

    &.ls {
      background-image: url(#{$imagesPath}/flags/ls.svg);
    }

    &.lt {
      background-image: url(#{$imagesPath}/flags/lt.svg);
    }

    &.lu {
      background-image: url(#{$imagesPath}/flags/lu.svg);
    }

    &.lv {
      background-image: url(#{$imagesPath}/flags/lv.svg);
    }

    &.ly {
      background-image: url(#{$imagesPath}/flags/ly.svg);
    }

    &.ma {
      background-image: url(#{$imagesPath}/flags/ma.svg);
    }

    &.mc {
      background-image: url(#{$imagesPath}/flags/mc.svg);
    }

    &.md {
      background-image: url(#{$imagesPath}/flags/md.svg);
    }

    &.me {
      background-image: url(#{$imagesPath}/flags/me.svg);
    }

    &.mf {
      background-image: url(#{$imagesPath}/flags/mf.svg);
    }

    &.mg {
      background-image: url(#{$imagesPath}/flags/mg.svg);
    }

    &.mh {
      background-image: url(#{$imagesPath}/flags/mh.svg);
    }

    &.mk {
      background-image: url(#{$imagesPath}/flags/mk.svg);
    }

    &.ml {
      background-image: url(#{$imagesPath}/flags/ml.svg);
    }

    &.mm {
      background-image: url(#{$imagesPath}/flags/mm.svg);
    }

    &.mn {
      background-image: url(#{$imagesPath}/flags/mn.svg);
    }

    &.mo {
      background-image: url(#{$imagesPath}/flags/mo.svg);
    }

    &.mp {
      background-image: url(#{$imagesPath}/flags/mp.svg);
    }

    &.mq {
      background-image: url(#{$imagesPath}/flags/mq.svg);
    }

    &.mr {
      background-image: url(#{$imagesPath}/flags/mr.svg);
    }

    &.ms {
      background-image: url(#{$imagesPath}/flags/ms.svg);
    }

    &.mt {
      background-image: url(#{$imagesPath}/flags/mt.svg);
    }

    &.mu {
      background-image: url(#{$imagesPath}/flags/mu.svg);
    }

    &.mv {
      background-image: url(#{$imagesPath}/flags/mv.svg);
    }

    &.mw {
      background-image: url(#{$imagesPath}/flags/mw.svg);
    }

    &.mx {
      background-image: url(#{$imagesPath}/flags/mx.svg);
    }

    &.my {
      background-image: url(#{$imagesPath}/flags/my.svg);
    }

    &.mz {
      background-image: url(#{$imagesPath}/flags/mz.svg);
    }

    &.na {
      background-image: url(#{$imagesPath}/flags/na.svg);
    }

    &.nc {
      background-image: url(#{$imagesPath}/flags/nc.svg);
    }

    &.ne {
      background-image: url(#{$imagesPath}/flags/ne.svg);
    }

    &.nf {
      background-image: url(#{$imagesPath}/flags/nf.svg);
    }

    &.ng {
      background-image: url(#{$imagesPath}/flags/ng.svg);
    }

    &.ni {
      background-image: url(#{$imagesPath}/flags/ni.svg);
    }

    &.nl {
      background-image: url(#{$imagesPath}/flags/nl.svg);
    }

    &.no {
      background-image: url(#{$imagesPath}/flags/no.svg);
    }

    &.np {
      background-image: url(#{$imagesPath}/flags/np.svg);
    }

    &.nr {
      background-image: url(#{$imagesPath}/flags/nr.svg);
    }

    &.nu {
      background-image: url(#{$imagesPath}/flags/nu.svg);
    }

    &.nz {
      background-image: url(#{$imagesPath}/flags/nz.svg);
    }

    &.om {
      background-image: url(#{$imagesPath}/flags/om.svg);
    }

    &.pa {
      background-image: url(#{$imagesPath}/flags/pa.svg);
    }

    &.pe {
      background-image: url(#{$imagesPath}/flags/pe.svg);
    }

    &.pf {
      background-image: url(#{$imagesPath}/flags/pf.svg);
    }

    &.pg {
      background-image: url(#{$imagesPath}/flags/pg.svg);
    }

    &.ph {
      background-image: url(#{$imagesPath}/flags/ph.svg);
    }

    &.pk {
      background-image: url(#{$imagesPath}/flags/pk.svg);
    }

    &.pl {
      background-image: url(#{$imagesPath}/flags/pl.svg);
    }

    &.pm {
      background-image: url(#{$imagesPath}/flags/pm.svg);
    }

    &.pn {
      background-image: url(#{$imagesPath}/flags/pn.svg);
    }

    &.pr {
      background-image: url(#{$imagesPath}/flags/pr.svg);
    }

    &.ps {
      background-image: url(#{$imagesPath}/flags/ps.svg);
    }

    &.pt {
      background-image: url(#{$imagesPath}/flags/pt.svg);
    }

    &.pw {
      background-image: url(#{$imagesPath}/flags/pw.svg);
    }

    &.py {
      background-image: url(#{$imagesPath}/flags/py.svg);
    }

    &.qa {
      background-image: url(#{$imagesPath}/flags/qa.svg);
    }

    &.re {
      background-image: url(#{$imagesPath}/flags/re.svg);
    }

    &.ro {
      background-image: url(#{$imagesPath}/flags/ro.svg);
    }

    &.rs {
      background-image: url(#{$imagesPath}/flags/rs.svg);
    }

    &.ru {
      background-image: url(#{$imagesPath}/flags/ru.svg);
    }

    &.rw {
      background-image: url(#{$imagesPath}/flags/rw.svg);
    }

    &.sa {
      background-image: url(#{$imagesPath}/flags/sa.svg);
    }

    &.sb {
      background-image: url(#{$imagesPath}/flags/sb.svg);
    }

    &.sc {
      background-image: url(#{$imagesPath}/flags/sc.svg);
    }

    &.sd {
      background-image: url(#{$imagesPath}/flags/sd.svg);
    }

    &.se {
      background-image: url(#{$imagesPath}/flags/se.svg);
    }

    &.sg {
      background-image: url(#{$imagesPath}/flags/sg.svg);
    }

    &.sh {
      background-image: url(#{$imagesPath}/flags/sh.svg);
    }

    &.si {
      background-image: url(#{$imagesPath}/flags/si.svg);
    }

    &.sj {
      background-image: url(#{$imagesPath}/flags/sj.svg);
    }

    &.sk {
      background-image: url(#{$imagesPath}/flags/sk.svg);
    }

    &.sl {
      background-image: url(#{$imagesPath}/flags/sl.svg);
    }

    &.sm {
      background-image: url(#{$imagesPath}/flags/sm.svg);
    }

    &.sn {
      background-image: url(#{$imagesPath}/flags/sn.svg);
    }

    &.so {
      background-image: url(#{$imagesPath}/flags/so.svg);
    }

    &.sr {
      background-image: url(#{$imagesPath}/flags/sr.svg);
    }

    &.ss {
      background-image: url(#{$imagesPath}/flags/ss.svg);
    }

    &.st {
      background-image: url(#{$imagesPath}/flags/st.svg);
    }

    &.sv {
      background-image: url(#{$imagesPath}/flags/sv.svg);
    }

    &.sx {
      background-image: url(#{$imagesPath}/flags/sx.svg);
    }

    &.sy {
      background-image: url(#{$imagesPath}/flags/sy.svg);
    }

    &.sz {
      background-image: url(#{$imagesPath}/flags/sz.svg);
    }

    &.tc {
      background-image: url(#{$imagesPath}/flags/tc.svg);
    }

    &.td {
      background-image: url(#{$imagesPath}/flags/td.svg);
    }

    &.tf {
      background-image: url(#{$imagesPath}/flags/tf.svg);
    }

    &.tg {
      background-image: url(#{$imagesPath}/flags/tg.svg);
    }

    &.th {
      background-image: url(#{$imagesPath}/flags/th.svg);
    }

    &.tj {
      background-image: url(#{$imagesPath}/flags/tj.svg);
    }

    &.tk {
      background-image: url(#{$imagesPath}/flags/tk.svg);
    }

    &.tl {
      background-image: url(#{$imagesPath}/flags/tl.svg);
    }

    &.tm {
      background-image: url(#{$imagesPath}/flags/tm.svg);
    }

    &.tn {
      background-image: url(#{$imagesPath}/flags/tn.svg);
    }

    &.to {
      background-image: url(#{$imagesPath}/flags/to.svg);
    }

    &.tr {
      background-image: url(#{$imagesPath}/flags/tr.svg);
    }

    &.tt {
      background-image: url(#{$imagesPath}/flags/tt.svg);
    }

    &.tv {
      background-image: url(#{$imagesPath}/flags/tv.svg);
    }

    &.tw {
      background-image: url(#{$imagesPath}/flags/tw.svg);
    }

    &.tz {
      background-image: url(#{$imagesPath}/flags/tz.svg);
    }

    &.ua {
      background-image: url(#{$imagesPath}/flags/ua.svg);
    }

    &.ug {
      background-image: url(#{$imagesPath}/flags/ug.svg);
    }

    &.um {
      background-image: url(#{$imagesPath}/flags/um.svg);
    }

    &.us {
      background-image: url(#{$imagesPath}/flags/us.svg);
    }

    &.uy {
      background-image: url(#{$imagesPath}/flags/uy.svg);
    }

    &.uz {
      background-image: url(#{$imagesPath}/flags/uz.svg);
    }

    &.va {
      background-image: url(#{$imagesPath}/flags/va.svg);
    }

    &.vc {
      background-image: url(#{$imagesPath}/flags/vc.svg);
    }

    &.ve {
      background-image: url(#{$imagesPath}/flags/ve.svg);
    }

    &.vg {
      background-image: url(#{$imagesPath}/flags/vg.svg);
    }

    &.vi {
      background-image: url(#{$imagesPath}/flags/vi.svg);
    }

    &.vn {
      background-image: url(#{$imagesPath}/flags/vn.svg);
    }

    &.vu {
      background-image: url(#{$imagesPath}/flags/vu.svg);
    }

    &.wf {
      background-image: url(#{$imagesPath}/flags/wf.svg);
    }

    &.ws {
      background-image: url(#{$imagesPath}/flags/ws.svg);
    }

    &.xk {
      background-image: url(#{$imagesPath}/flags/xk.svg);
    }

    &.ye {
      background-image: url(#{$imagesPath}/flags/ye.svg);
    }

    &.yt {
      background-image: url(#{$imagesPath}/flags/yt.svg);
    }

    &.za {
      background-image: url(#{$imagesPath}/flags/za.svg);
    }

    &.zm {
      background-image: url(#{$imagesPath}/flags/zm.svg);
    }

    &.zw {
      background-image: url(#{$imagesPath}/flags/zw.svg);
    }
  }
}
