$assets_icons: "../../assets/icons";
$assets_images: "../../assets/images";

.drop-file-input {
  position: absolute;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  z-index: 105;
  background: #ffffff;
  .drop-file-input--label {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8c8c8c;
    font-size: 32px;
    line-height: 21px;
    font-weight: bold;
  }
  .previous-file-view {
    padding: 0px 24px 24px 24px;
    background-color: #ffffff;
    .header-content {
      display: flex;
      justify-content: flex-end;
      margin-top: 21px;
      margin-bottom: 21px;
      .icon {
        cursor: pointer;
      }
    }
    .content-file {
      width: -webkit-fill-available;
      display: flex;
      justify-content: center;
      .center-file {
        display: flex;
        justify-content: center;
        .icon-file {
          width: 82px;
          height: 107px;
          background-image: url($assets_icons + "/draged-file.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .drop-file-name {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 16px;
        font-size: 15px;
        line-height: 21px;
        color: #201515;
        border: none;
      }
      img {
        max-width: 300px;
        max-height: 300px;
      }
    }
    .ico-spinner {
      width: 25px;
      height: 25px;
      background-image: url($assets_images + "/oval.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .button {
      margin-top: 35.4px;
      width: -webkit-fill-available;
      height: 37px;
      background-color: #1a1a1a;
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
      color: #fafafa;
      border: none;
    }
  }
}
