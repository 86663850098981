$main-color-dark: #120338;
$main-color-normal: #22075e;

$stripe-color: #ff7864;

$black-color: rgba(0, 0, 0, 0.85);
$gray-color: rgba(0, 0, 0, 0.45);

$light-gray-color: #f8f8f8;
$light-gray-color-selected: #f2f2f2;
$light-gray-border-color: #ebebeb;
$gray-color: #c4c4c4;

$purple-color: #9b9bff;
$green-color: #5ab978;
$red-color: #f06450;

$black: #000000;
$black-bis: #1a1a1a;
$black-light: #171717;
$gray-light: #e6e6e6;
$gray-dark: #767676;
$lavender: #9b9bff;

$white: #ffffff;

$tablet: 768px;
$desktop: 1024px;

$family-primary: "HKGrotesk";
