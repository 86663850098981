@import "../../styles/rootStyles.scss";

$assetsPath: "../../assets/images";

.editor-container {
  margin-bottom: 40px;
  &.is-inactive {
    .rdw-editor-toolbar {
      .rdw-inline-wrapper {
        border: none !important;
      }
      .icon-svg {
        display: none;
      }
    }
  }

  .textarea-wrapper {
    @import "../../styles/rootStyles.scss";
    margin: 24px;
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid #e9e9e9;

    @media (max-width: $tablet - 1) {
      .toolbar-class {
        .search-hsm-dropdown,
        .notes,
        .jumps {
          display: none !important;
        }
        .rdw-inline-wrapper {
          padding-left: unset !important;
          border-left: none !important;
        }
      }
      .mobile-toolbar {
        .search-hsm-dropdown,
        .notes,
        .jumps {
          display: flex !important;
          padding: 0 !important;
        }
      }
      &.read-only {
        .toolbar-class {
          .search-hsm-dropdown {
            display: block !important;
          }
        }
      }
    }

    .mobile-toolbar {
      display: none;
      @media (max-width: $tablet - 1) {
        display: flex;
      }
    }

    .rdw-editor-toolbar {
      justify-content: normal;
      .emoji,
      .record {
        margin-left: auto;
      }
      .bracket-vars {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        margin: 0px;
        .var {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          font-weight: 600;
          padding: 0px 11px;
          font-size: 15px;
          color: #456778;
          background: #dce6eb;
          border-radius: 8px;
          margin-right: 10px;
          text-transform: none;
          &:last-child {
            margin-right: 0px;
          }
        }
        &:hover {
          background: #ffffff !important;
        }
      }
      .search-hsm-dropdown {
        order: -2;
      }
      .jumps {
        display: flex;
        .left {
          transform: rotateY(180deg);
        }
      }
      .notes {
        .icon {
          margin-right: 8.62px;
        }
      }
      .emoji,
      .voice,
      .files,
      .hsm,
      .notes,
      .jumps {
        .icon {
          background: #9dacb9;
        }
      }
      .send .icon {
        width: 24px;
        height: 24px;
        background: #000000;
      }
      .search-hsm-dropdown {
        .dropdown-trigger {
          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &.read-only {
      background-color: #f7f8f9;
      .mobile-toolbar {
        display: none;
      }
      .toolbar-class {
        .rdw-inline-wrapper {
          display: none;
        }
        .rdw-option-wrapper {
          &.emoji,
          &.files,
          &.voice,
          &.jump,
          &.notes {
            display: none;
          }
        }
        .search-hsm-dropdown {
          display: flex !important;
          .dropdown-menu {
            transform: translateX(0%) translateY(-120%);
          }
        }
      }
    }

    &.note-tab {
      .search-hsm-dropdown {
        display: none;
      }
    }

    .toolbar-class {
      .record-items {
        display: flex;
        .item {
          padding-top: 5px;
          padding-bottom: 5px;
          height: 20px;
          margin-right: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          .audio-box {
            width: 144px;
            height: 24px;
            display: flex;
            align-items: center;
            border-radius: 2px;
            justify-content: space-between;
            padding: 0px 8px;
            &--on-play {
              background: #ecedee;
            }
            .icon {
              &--play {
                width: 8.33px;
                height: 10px;
              }
              &--stop {
                width: 14px;
                height: 14px;
              }
            }
            .time-limit {
              color: #f27563;
            }
          }

          button {
            border: none;
            background-color: transparent;
          }
        }

        .icon {
          width: 16px;
          height: 16px;
        }
        .icon:hover {
          cursor: pointer;
        }
      }

      .icon-svg {
        .icon {
          width: 16px;
          height: 16px;
        }
      }

      background-color: #fafafa;
      height: 32px;
      margin-bottom: 0;
      padding-top: 8px;
      border: none;

      .search-hsm-dropdown {
        overflow: hidden;

        &.is-active {
          overflow: visible;
        }

        .dropdown-menu-header {
          display: none;
        }

        .dropdown-menu {
          position: absolute;
          margin: 0;
          right: 0;
          transform: translateX(2%) translateY(-120%);
          height: 273px;
          width: 336px;

          .dropdown-content {
            height: 273px;
            display: flex;
            flex-direction: column;

            .hover-option-panel {
              position: absolute;
              background-color: $white;
              left: 340px;
              min-height: 240px;
              width: 336px;
              border-radius: 4px;
              padding: 16px;

              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

              p {
                color: $black;
                white-space: pre-line;
                font-family: $family-primary;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
              }
            }

            .searchable-dropdown-search-container {
              margin: 16px;
              color: $white-ter;
              display: flex;
              border: none;
              background-color: #f3f3f3;

              &:focus-within {
                outline: 1px solid #393939;
              }
              span {
                height: 100%;
              }

              input {
                background-color: transparent;
                color: $black;
                border: none;

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .dropdown-item {
              padding: 8px 12px;
              margin: 18px;
              &:hover {
                background: #fafafa;
              }
            }

            .searchable-items {
              overflow-y: scroll;
              text-overflow: ellipsis;
              white-space: nowrap;
              position: relative;

              @media (max-width: $desktop - 1) {
                max-height: fit-content;
                white-space: normal;
              }

              &::-webkit-scrollbar {
                width: 7px;
                background: #ffffff;
              }
              &::-webkit-scrollbar-thumb {
                background: #000000;
                border-radius: 800px;
              }

              .search-hsm-dropdown-item {
                width: 100%;
                .paused-hsm {
                  background: #eb4128;
                  width: fit-content;
                  padding: 4px 9px;
                  margin-bottom: 9px;
                  border-radius: 2px;
                  p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: #ffffff;
                  }
                }
                .search-hsm-dropdown-item-hover {
                  position: absolute;
                  left: 10px;
                }
              }
            }

            h1,
            h2 {
              color: $black;
              font-size: 15px;
            }

            h1 {
              line-height: 48px;
              font-weight: 600;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow-x: hidden;
            }

            h2 {
              font-weight: normal;
              max-height: 45px;
              height: 100%;
              line-height: 15px;
              word-wrap: break-word !important;
              overflow-y: hidden;
            }

            @media (min-width: $desktop) {
              .dropdown-item {
                .search-hsm-dropdown-item {
                  h1,
                  h2 {
                    color: #3c5a73 !important;
                  }
                  h1 {
                    line-height: 20px;
                    overflow: hidden;
                  }
                  h2 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 18px;
                  }
                }
              }
            }
          }
        }

        .search-hsm-dropdown__no-results {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          min-height: 200px;
          border-radius: 4px;

          .no-results-image {
            width: 100px;
            height: 100px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(#{$assetsPath}/noElements.svg);
            margin-top: 16px;
          }

          .no-result-title {
            font-family: $family-primary;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin-top: 8px;
          }

          div.subtitle {
            width: 320px;
            height: 40px;
            font-family: $family-primary;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 21px;
            color: $black;
            white-space: pre-line;
            text-align: center;
          }
        }

        @media (max-width: $desktop - 1) {
          .dropdown-menu {
            max-height: none;
            width: -webkit-fill-available;
            height: 100vh;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            padding: 0;
            transform: none !important;
            .dropdown-content-container {
              background: rgba(10, 11, 43, 0.63);
              height: 100vh;
              display: flex;
              justify-content: center;
              .dropdown-content {
                width: -webkit-fill-available !important;
                max-width: 398px;
                max-height: 565px;
                border-radius: 0;
                padding: 0;
                margin: auto;
                margin-left: 8px;
                margin-right: 8px;
                box-shadow: 0px 2px 8px rgba(106, 170, 170, 0.15);
                border-radius: 4px;
                .dropdown-menu-header {
                  display: flex;
                  position: relative;
                  h1 {
                    display: none;
                  }
                  .icon {
                    background: #ffffff;
                    position: absolute;
                    top: -41px;
                    right: 0;
                    margin-right: 10px;
                    height: 18px !important;
                    width: 18px !important;
                    -webkit-mask-size: contain;
                  }
                }
                .dropdown-item {
                  &:focus {
                    background: #eaf3f3;
                  }
                }
              }
            }
          }
        }
      }

      .push-top {
        margin: 0px 12px 3px 12px;
      }
      .push-bot {
        margin: 0px 12px -1px 12px;
      }

      .rdw-option-wrapper {
        background-color: #fafafa;
        border: none;
        cursor: default;
        .icon--templates {
          margin-right: 8px;
        }
        span {
          font-weight: 600;
          font-size: 15px;
          color: #9dacb9;
        }
      }

      .rdw-option-active {
        background-color: #e6e6e6;
        img {
          filter: brightness(0) saturate(100%);
        }
        box-shadow: unset;
      }

      .icon-svg:hover {
        div,
        img,
        label {
          filter: brightness(0) saturate(100%);
        }
      }

      .darken {
        background-color: #e6e6e6;
        div {
          filter: brightness(0) saturate(100%);
        }
        box-shadow: unset;
      }

      .rdw-option-wrapper:hover {
        background-color: #e6e6e6;
        border: none;
        box-shadow: unset;
        span {
          color: $black;
        }
      }

      .rdw-option-center {
        margin-bottom: 6px !important;
      }
    }

    .text-editor {
      min-height: 88px;
      max-height: 140px;
      margin: 0;
      padding: 10px 16px 10px 16px;
      border: none;
      .public-DraftStyleDefault-block {
        margin: 0;
      }
      color: #1a1a1a;
    }

    #popover-emoji {
      max-width: 100%;
    }

    .height-161 {
      height: 161px;
    }

    .height-100 {
      height: 72px;
    }
  }
}

.treble-popover {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(106, 170, 170, 0.15);
  padding: 0 !important;
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
  }
  .ant-popover-inner-content {
    padding: 19px;
    .hsm-preview {
      color: #3c5a73;
      padding-bottom: 17px;
      .header,
      .body,
      .footer {
        p,
        img {
          padding-top: 12px;
        }
      }
      .header {
        img {
          width: 100%;
          height: auto;
        }
        p {
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
        }
      }
      .body p {
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        white-space: break-spaces;
      }
      .footer {
        padding: 0;
        background-color: transparent;
        justify-content: flex-start;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .buttons {
        a,
        p {
          margin-top: 11px;
          width: -webkit-fill-available;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #3c5a73;
          border-radius: 6px;
          color: #ffffff;
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
  }
}
