@import "../../../assets/scss/variables.scss";

.details-panel-container {
  flex-grow: 1;
  display: flex;
  flex-flow: column;

  .tabs {
    padding: 0 27px;
    margin-bottom: 0;

    ul {
      justify-content: center;
      li {
        width: 50%;
      }
    }

    li.is-active a {
      color: #2dd881;
      border-bottom: 2px solid #2dd881;
    }
  }
  .panel-body {
    flex-grow: 1;
    flex-basis: 10px;

    overflow: auto;
  }
}
