$iconsPath: "../../../../../assets/icons";

.calendar-icon {
  width: 15px;
  height: 16px;
  background-image: url(#{$iconsPath}/calendar-grey.svg);

  &:hover {
    cursor: pointer;
  }
}

.date-selector-container {
  display: flex;
  border: 1px solid #dfe1e6;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 3px;

  .date-selector {
    border-radius: 2px;
    cursor: pointer;
    &--init {
      margin: 14px 16px 16px 20px;
    }
    &--end {
      margin: 14px 22px 16px 14px;
    }
    p {
      width: 77px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      font-style: normal;
    }
  }

  .icon--arrow {
    background-repeat: no-repeat;
    background: url(#{$iconsPath}/arrow_right.svg);
    width: 19px;
    height: 12px;
  }
}

.calendar-bar {
  display: flex;
  border: 1px solid #dfe1e6;
  padding: 8px 0px 8px 20px;
  height: 50px;
  width: max-content;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  div {
    flex-direction: column;
    margin-right: 15px;
    cursor: pointer;
    color: #767676;

    &:hover {
      color: #000000;
    }
  }

  .active {
    padding: 8px 18px;
    background: #000000;
    border-radius: 4px;
    color: white;

    &:hover {
      color: white;
    }
  }
}

.datepicker-container {
  display: flex;
  .dropdown {
    display: none;
    &.is-active {
      display: block;
      position: absolute;
      left: 350px;
      top: 25px;
      z-index: 10;
      width: 715px;
      height: 543px;
      background: #ffffff;
      box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.1);
      border-radius: 4px;
    }

    .calendar-head {
      display: flex;
      flex-direction: row;
      border-bottom: 1px dashed #dfe1e6;
      padding: 21px 30px 21px 30px;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        padding: 18px 15px 18px 0px;
        max-width: 220px;
      }

      .separator {
        padding: 0px;
        color: #b3b3b3;
        margin-left: 22px;
        margin-right: 4px;
      }

      .calendar-input {
        width: 92px;
        display: flex;
        flex-direction: row;
        input {
          border: none;
          height: 28px;
          text-align: center;
          &::placeholder {
            color: #b3b3b3;
          }
          &:focus {
            outline: none;
          }
        }
        span {
          padding: 0px;
          color: #b3b3b3;
        }
        .day {
          width: 22px;
        }
        .year {
          width: 44px;
        }
        .month {
          width: 30px;
        }

        &:hover {
          color: #6464ff;

          span {
            color: #6464ff;
          }
        }
        &:hover > input::placeholder {
          color: #6464ff;
        }
        &.error {
          color: #eb4128;
          span {
            color: #eb4128;
          }
        }
      }

      .delete-selection {
        display: flex;
        margin-left: auto;

        span {
          padding: 0px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          color: #b2b2b2;

          &:hover {
            color: #eb4128;
            cursor: pointer;
          }
        }
      }
    }

    .calendar-body {
      display: flex;
      justify-content: center;
      margin-top: 27px;
      border-bottom: 1px solid #dfe1e6;
    }

    .calendar-footer {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      margin-top: 19px;
      padding-right: 30px;

      .btn {
        width: 168px;
        height: 48px;
        &--cancel {
          color: black;
          border: none;
          background-color: transparent;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
        }
        &--save {
          background: #6464ff;
          border-radius: 4px;
          color: white;
          border: none;
          &.disabled {
            background-color: #d6d6d6;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }

      button {
        display: flex;
        flex-direction: column;
      }
    }

    .dropdown-content-calendar {
      background: #ffffff;
      border-radius: 4px;
      padding: 0px 24px 44px 24px;
      width: fit-content;
      display: inline-block;
      .date-and-calendar {
        width: 276px;
        height: 316px;
        .tail-datetime-calendar {
          .calendar-day {
            .span {
              width: 10px;
            }
            .day-container {
              padding-top: 2px;
              margin: 0px;
              border-radius: 24px;
              width: 24px;
              height: 24px;
            }
            .today {
              .day-container {
                background: #6464ff;
                color: #ffffff;
              }
            }
          }
        }
      }
      .save-and-cancel {
        float: right;
        margin-top: 16px;
        display: flex;
      }
    }
  }
}
