.record-modal-header {
  display: flex;
  align-items: center;
  .icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}

.record-modal-body {
  color: #767676;
  .icon {
    width: 20px;
    height: 20px;
  }
}
