@import "../../../../../../styles/rootStyles.scss";
$assets_icons: "../../../../../../assets/icons";

#response-time {
  padding-left: 48px;
  display: block !important;
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 400;
  height: 700px;

  .response-time-header {
    width: 98%;
    height: fit-content;

    .header-container {
      display: flex;
      height: 153px;

      .header-info {
        display: flex;
        flex-direction: row;
        flex: 1;
        overflow-x: auto;
        align-content: center;

        .header-info-item {
          min-width: 225px;
          margin: auto 0;

          &.left-margin {
            margin-left: 50px;
          }

          span {
            font-size: 16px;
            line-height: 21px;
          }

          p {
            margin-bottom: 0px;
            font-size: 27px;
            line-height: 35px;
          }

          hr {
            margin: 0px;
            margin-top: 8px;
          }

          &.disable {
            color: #b2b2b2;
          }
        }
      }

      .header-actions {
        display: flex;
        flex-direction: column;
        flex: 0;
        padding: 33px 30px 0px 38px;

        a {
          .icon {
            background-color: #b2b2b2;
            display: inline-flex;
            margin-top: 22px;
            cursor: pointer;

            &:hover {
              background-color: #000000;
            }
          }
        }

        .icon--filters {
          background-color: #b2b2b2;
          cursor: pointer;

          &:hover {
            background-color: #000000;
          }
        }

        @media (max-width: 968px) {
          box-shadow: -8px 0px 6px 0px rgba(85, 120, 155, 0.2);
        }
      }
    }
  }

  .response-time-body {
    display: flex;
    flex-direction: row;
    margin-top: 43px;
    padding-right: 107px;
    padding-bottom: 70px;

    .response-time-info {
      display: flex;
      flex-direction: column;
      flex: 0;

      .info-card {
        border: 1px solid #dfe1e6;
        padding: 16px 52px 13px 33px;

        .info-filter-dropdown {
          margin-bottom: 25px;
          .button {
            width: 250px;
            height: 43px;
            display: flex;
            align-items: center;
            border: none;
            padding: 11px 0px;
            margin-right: 17px;
            border-radius: inherit;

            .text {
              width: 220px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-style: normal;
              font-size: 15px;
              line-height: 20px;
              text-align: left;
              color: #3c5a73;
            }

            .icon {
              background: #3c5a73;
              margin-left: auto !important;

              &--dropdown-row {
                margin-top: 2px;
                margin-right: 6px;
                width: 10px;
                height: 6px;
              }
            }

            &:focus {
              .text {
                color: #6aaaaa;
              }

              .icon {
                background: #6aaaaa;
              }
            }

            &:hover {
              .text {
                color: #b2b2b2;
              }

              .icon {
                background: #b2b2b2;
              }
            }
          }

          .dropdown-content {
            ul {
              margin: 0;
            }

            width: 330px;
            padding: 12px 0px;
            box-shadow: 0px 7px 28px rgba(39, 39, 39, 0.15);
            border-radius: 4px;

            .dropdown-item {
              display: flex;
              align-items: center;
              padding: 9.5px 0px 9.5px 22px;
              text-transform: lowercase !important;

              .text-item {
                line-height: 16px;
                display: inline-block;
                width: 200px !important;
                color: #3c5a73;

                &::first-letter {
                  text-transform: uppercase;
                }
              }

              .icon {
                margin-left: auto;
                margin-right: 23px;
                width: 10.26px;
                height: 7.26px;

                &--sent {
                  background-image: url(#{$assets_icons}/sent.svg);
                }
              }
            }
          }
        }

        .info-items {
          .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 32px;

            .left-item {
              width: 85px;
            }
          }
        }
      }
    }

    .response-time-chart {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      margin-left: 30px;

      .chart-options {
        margin-left: auto;

        .ant-select {
          width: 124px;

          .ant-select-selection-placeholder {
            color: #232b30;
          }

          .ant-select-selector {
            border: 1px solid #d9d9d9;
            color: #232b30;
          }
        }

        .icon {
          background-color: #000000;
        }
      }

      .chart-body {
        margin-left: auto;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;

        .recharts-wrapper {
          margin: auto;
          .recharts-tooltip-wrapper {
            .custom-tooltip {
              display: flex;
              flex-direction: column;
              background: #ffffff;
              box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
              border-radius: 4px;

              .row {
                display: flex;
                flex-direction: row;
              }

              .col {
                display: flex;
                flex-direction: column;
              }

              .tooltip-header {
                p {
                  margin-left: 12px;
                  font-size: 16px;
                  font-weight: 500;
                }
                padding: 21px 25px 18px 22px;
                border-bottom: 1px dashed #dfe1e6;
              }

              .tooltip-body {
                padding: 22px 25px 26px 22px;
                font-family: "HK Grotesk";
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;

                .value {
                  margin-left: 40px;
                  color: #3c5a73;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}
