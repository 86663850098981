.treble-pill {
  width: fit-content;
  padding: 8px 21px;
  border-radius: 800px;
  display: flex;
  gap: 10px;
  align-items: center;

  &.bg-color {
    &-black {
      background-color: #272727;
    }
    &-green {
      background-color: #c5f8c6;
    }
  }

  &.color {
    &-white {
      color: #ffffff;
    }
  }

  .icon {
    &--reload {
      height: 18px;
      width: 21px;
    }
  }
}
