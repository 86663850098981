$assets_path: "../../../../assets/images";
$assets_icons: "../../../../assets/icons";
.contact-container {
  @import "../../../../styles/rootStyles.scss";
  display: flex;
  font-family: $family-primary !important;
  height: -webkit-fill-available;
  button {
    cursor: pointer;
  }
  .conversation-details {
    width: -webkit-fill-available;
    border-left: 1px solid #ebebeb;
    height: 100%;
    .conv-notes-container {
      overflow: auto;
      height: calc(100vh - 66px);
      background-image: url(#{$assets_path}/chat-background.svg);
      .notes-container {
        padding: 15px 23px;
        .note-container {
          border: none;
          padding: 17px 24px;
          h2 {
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
          p {
            color: #000000;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            &.note-date {
              font-size: 15px;
            }
          }
        }
      }
      .chat-messages {
        margin: 23px 0px;
        .bubble {
          background: transparent;
        }
        .new-date-label {
          display: block;
          text-align: center;
          margin: auto;
          padding: 0 15px;
          top: 36px;
          transform: translateY(-160%);
          position: sticky;
          background: transparent;
          font-size: 13px;
          font-weight: 400;
          text-transform: uppercase;
          z-index: 2;
          width: fit-content;
          background-color: $white;
          color: #3c5a73;
          border-radius: 10px;
        }
        .conversation-event {
          min-height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f9f9ff;
          line-height: 21px;
          font-weight: 500;
          margin: 10px 0px;
          padding: 16px;

          div.chat-info {
            div {
              color: #9dacb9;
              a {
                color: #9c9cff;
                &:hover {
                  color: #000000;
                }
              }
              span.conversation-tag {
                color: #5ab978 !important;
                padding: 0 !important;
              }
            }
          }

          &.hubspot {
            .chat-info {
              div {
                color: #f3773a;
              }
            }
          }
        }
        hr {
          display: none;
        }
      }
    }
    .details-options {
      display: flex;
      height: 66px;
      .empty-space {
        width: -webkit-fill-available;
        border-bottom: 1px solid #ebebeb;
      }
      button {
        background: transparent;
        border: none;
        border-bottom: 1px solid #ebebeb;
        padding: 0px 17px;
        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #9dacb9;
        }
        &.active {
          border-bottom: 2px solid #1a1a1a;
          p {
            color: #1a1a1a;
          }
        }
        &.close {
          p {
            color: #3c5a73;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            width: max-content;
          }
          padding: 0px 23px;
          display: flex;
          align-items: center;
          width: -webkit-fill-available;
          .icon {
            width: 13px;
            height: 13px;
            margin-left: 23px;
            background: #3c5a73;
          }
        }
      }
    }
  }
  .contacts-events {
    width: -webkit-fill-available;
    height: inherit;
    .title {
      border-bottom: 1px solid #ebebeb;
      height: 66px;
      align-items: center;
      padding-left: 23px;
      display: flex;
      p {
        color: #3c5a73;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
      }
    }
    .loading-events {
      h3 {
        color: #242424;
        font-weight: 500;
        font-size: 19px;
        line-height: 25px;
        margin-top: 47px;
      }
      display: flex;
      justify-content: center;
      margin-top: 126px;

      @keyframes spinner {
        0% {
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }
        100% {
          transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
      }
      .custom-treble-loader::before {
        animation: 1.5s linear infinite spinner;
        animation-play-state: inherit;
        border: 5px solid #dee5f7;
        border-bottom-color: #000000;
        border-radius: 50%;
        content: "";
        height: 40px;
        width: 40px;
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
        margin-left: 138px;
      }
    }
    .no-contact-events {
      text-align: -webkit-center;
      h6 {
        color: #8d9ba6;
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: 0.396px;
      }
      p {
        color: #8d9ba6;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        margin-top: 8px;
        margin-bottom: 21px;
      }
      .send-hsm-container {
        text-align: unset !important;
        margin: 0px !important;
      }
      button {
        p {
          margin: 0px;
          color: #ffffff;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
        }
      }
      .no-events-image {
        background-image: url(#{$assets_path}/no-events-img.svg);
        width: 99px;
        height: 183px;
        margin-top: 80px;
        margin-bottom: 24px;
      }
    }
    .events-container,
    .no-contact-events {
      height: calc(100vh - 118px);
      margin: 0px 22px 28px 22px;
      background: #f4f6fa;
      border-radius: 10px;
      padding: 22px;
      .events {
        height: 90%;
        overflow: auto;
      }
      .send-hsm-container {
        text-align: end;
        margin-top: 20px;
        .search-hsm-dropdown {
          overflow: hidden;

          &.is-active {
            overflow: visible;
          }

          .dropdown-menu-header {
            display: none;
          }

          .dropdown-menu {
            position: absolute;
            margin: 0;
            right: 0;
            transform: translateX(2%) translateY(-120%);
            height: 273px;
            width: 336px;
            left: -132px;
            .searchbar {
              .icon {
                width: 18px;
                height: 18px;
              }
            }
            .dropdown-content {
              height: 273px;
              display: flex;
              flex-direction: column;

              .hover-option-panel {
                position: absolute;
                background-color: $white;
                left: 340px;
                min-height: 240px;
                width: 336px;
                border-radius: 4px;
                padding: 16px;

                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

                p {
                  color: $black;
                  white-space: pre-line;
                  font-family: $family-primary;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 15px;
                }
              }

              .searchable-dropdown-search-container {
                margin: 16px;
                color: $white-ter;
                display: flex;
                border: none;
                background-color: #f3f3f3;

                &:focus-within {
                  outline: 1px solid #393939;
                }
                span {
                  height: 100%;
                }

                input {
                  background-color: transparent;
                  color: $black;
                  border: none;

                  &:focus {
                    box-shadow: none;
                  }
                }
              }

              .dropdown-item {
                padding: 8px 12px;
                margin: 0px 18px;
                &:hover {
                  background: #fafafa;
                }
              }

              .searchable-items {
                overflow-y: scroll;
                text-overflow: ellipsis;
                white-space: nowrap;

                position: relative;

                @media (max-width: $desktop - 1) {
                  max-height: fit-content;
                  white-space: normal;
                }

                .search-hsm-dropdown-item {
                  width: 100%;
                  text-align-last: left;
                  .paused-hsm {
                    background: #eb4128;
                    width: fit-content;
                    padding: 4px 9px;
                    margin-bottom: 9px;
                    border-radius: 2px;
                    p {
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 18px;
                      color: #ffffff;
                    }
                  }
                  .search-hsm-dropdown-item-hover {
                    position: absolute;
                    left: 10px;
                  }
                }
              }

              h1,
              h2 {
                color: $black;
                font-size: 15px;
              }

              h1 {
                line-height: 48px;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-x: hidden;
              }

              h2 {
                font-weight: normal;
                max-height: 45px;
                height: 100%;
                line-height: 15px;
                word-wrap: break-word !important;
                overflow-y: hidden;
              }

              @media (min-width: $desktop) {
                .dropdown-item {
                  .search-hsm-dropdown-item {
                    h1,
                    h2 {
                      color: #3c5a73 !important;
                    }
                    h1 {
                      line-height: 20px;
                      overflow: hidden;
                    }
                    h2 {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-height: 18px;
                    }
                  }
                }
              }
            }
          }

          .search-hsm-dropdown__no-results {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            min-height: 200px;
            border-radius: 4px;

            .no-results-image {
              width: 100px;
              height: 100px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url(#{$assets_path}/noElements.svg);
              margin-top: 16px;
            }

            .no-result-title {
              font-family: $family-primary;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 24px;
              color: $black;
              margin-top: 8px;
            }

            div.subtitle {
              width: 320px;
              height: 40px;
              font-family: $family-primary;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 21px;
              color: $black;
              white-space: pre-line;
              text-align: center;
            }
          }

          @media (max-width: $desktop - 1) {
            .dropdown-menu {
              max-height: none;
              height: 100vh;
              position: fixed;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              padding: 0;
              transform: none !important;
              width: 100%;
              .dropdown-content-container {
                @media (min-width: $tablet) {
                  background-color: rgba(0, 0, 0, 0.5);
                }
                .dropdown-content {
                  @media (min-width: $tablet) {
                    width: 500px;
                    margin-right: 0px;
                    margin-left: auto;
                  }
                  height: 100vh;
                  border-radius: 0;
                  padding-top: 0;
                  .dropdown-menu-header {
                    display: flex;
                    height: 24px;
                    background-color: $black;
                    padding: 16px 8px 16px 26px;
                    .icon {
                      height: 24px;
                      width: 24px;
                      margin-right: 16px;
                      cursor: pointer;
                    }
                    h1 {
                      line-height: 24px !important;
                      color: #fafafa !important;
                    }
                  }
                  .searchable-dropdown-search-container {
                    background-color: #f3f3f3;
                  }
                  .hover-option-panel {
                    display: none;
                  }
                }
              }
            }
          }
        }
        button.send-hsm {
          background: #6464ff;
          border-radius: 4px;
          height: 46px;
          filter: drop-shadow(4px 4px 14px #9b9bff);
          border: none;
          color: #ffffff;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          margin-left: auto;
          padding: 0px 14px;
          display: flex;
          gap: 10px;
          align-items: center;
          width: fit-content;
          .icon--book {
            background: #ffffff;
            -webkit-mask: url(#{$assets_icons}/HSM-Icon.svg) no-repeat center;
            mask: url(#{$assets_icons}/HSM-Icon.svg) no-repeat center;
          }

          .icon--info {
            background: #000000;
            -webkit-mask: url(#{$assets_icons}/error-black.svg) no-repeat center;
            mask: url(#{$assets_icons}/error-black.svg) no-repeat center;
          }

          &:hover {
            background: rgba(100, 100, 255, 0.8);
          }
        }
        button.send-hsm.disabled {
          background: #d6d6d6;
          cursor: not-allowed;
        }
      }
      .event {
        background: #ffffff;
        border-radius: 4px;
        padding: 18px;
        margin-bottom: 13px;
        cursor: pointer;
        h6 {
          color: #1d262c;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          margin-left: 15px;
          margin-right: auto;
        }
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #1d262c;
        }
        .event-details {
          .detail {
            h6 {
              margin: 0px;
              margin-bottom: 8px;
            }
            margin-left: 32px;
            margin-top: 28px;
            p {
              &.hubspot {
                color: #f3773a;
              }
            }
          }
          .button-container {
            text-align: end;
            margin-top: 18px;
            button {
              background: transparent;
              font-weight: 600;
              font-size: 16px;
              line-height: 21px;
              color: #6464ff;
              border: none;
              &:hover {
                color: rgba(100, 100, 255, 0.5);
              }
            }
            button:disabled,
            button[disabled] {
              color: #9dacb9;
              cursor: not-allowed;
            }
          }
          .compose-detail {
            display: flex;
            margin-top: 28px;
            .information {
              .event-history {
                max-height: 88px;
                min-height: 88px;
                h6 {
                  margin: 0px 0px 7px 0px;
                }
                .event-title,
                .transfer-container {
                  display: flex;
                  .icon {
                    background: #000000;
                    margin: 0px 14px;
                  }
                }
                .event-title {
                  p {
                    margin-left: 11px;
                  }
                }
              }
            }
            .ilustration-container {
              margin-left: 4px;
              margin-right: 20px;
              margin-top: 6px;
              .complete-ilustration {
                display: grid;
                justify-items: center;
              }
              .icon {
                &--dot {
                  height: 10px;
                  width: 10px;
                  background-image: url(#{$assets_icons}/dot.svg);
                }
                &--line {
                  height: 75px;
                  width: 3px;
                  margin-top: 3px;
                  background-image: url(#{$assets_icons}/dot-line.svg);
                }
              }
            }
          }
        }
        .visible-event-items {
          display: flex;
          align-items: center;
          p {
            line-height: 20px;
            margin-right: 40px;
          }
          .icon {
            width: 17px;
            height: 17px;
            background: #6aaaaa;
            &--DELIVERED {
              -webkit-mask: url(#{$assets_icons}/Icon-received.svg) no-repeat
                center;
              mask: url(#{$assets_icons}/Icon-received.svg) no-repeat center;
            }
            &--RECEIVED {
              -webkit-mask: url(#{$assets_icons}/Icon-Inprogress.svg) no-repeat
                center;
              mask: url(#{$assets_icons}/Icon-Inprogress.svg) no-repeat center;
            }
            &--FAILURE {
              -webkit-mask: url(#{$assets_icons}/Icon-Fail.svg) no-repeat center;
              mask: url(#{$assets_icons}/Icon-Fail.svg) no-repeat center;
            }
            &--SEND {
              -webkit-mask: url(#{$assets_icons}/Icon-Sent.svg) no-repeat center;
              mask: url(#{$assets_icons}/Icon-Sent.svg) no-repeat center;
            }
            &--READ {
              -webkit-mask: url(#{$assets_icons}/Icon-Read.svg) no-repeat center;
              mask: url(#{$assets_icons}/Icon-Read.svg) no-repeat center;
            }
            &--FINISHED {
              -webkit-mask: url(#{$assets_icons}/Icon-Sent.svg) no-repeat center;
              mask: url(#{$assets_icons}/Icon-Sent.svg) no-repeat center;
            }
            &--IN_PROGRESS,
            &--RESPONDED {
              height: 18px;
              -webkit-mask: url(#{$assets_icons}/Icon-Conversation.svg) no-repeat
                center;
              mask: url(#{$assets_icons}/Icon-Conversation.svg) no-repeat center;
            }
            &--cirle-arrow {
              width: 18px;
              height: 18px;
              background: #1d262c;
              -webkit-mask: url(#{$assets_icons}/Icon-show_more.svg) no-repeat
                center;
              mask: url(#{$assets_icons}/Icon-show_more.svg) no-repeat center;
            }
            &--FINISHED {
              -webkit-mask: url(#{$assets_icons}/FINISHED.svg) no-repeat center;
              mask: url(#{$assets_icons}/FINISHED.svg) no-repeat center;
            }
            &.is-active {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}

.contact-active-conversation-tooltip {
  @import "../../../../styles/rootStyles.scss";
  .ant-tooltip-content {
    width: 208px;
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content::before {
        background-color: #000000;
      }
    }
    .ant-tooltip-inner {
      background-color: #000000;
      padding: 6px 16px;
      color: #ffffff;
      font-family: $family-primary;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
    }
  }
}
