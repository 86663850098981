.table-icon-btn {
  border-radius: 100%;
  border: none;
  background-color: rgba(248, 249, 252, 1);
  width: 36px;
  height: 36px;
  padding: 10px;
  cursor: pointer;
  &.primary {
    &:hover {
      outline-color: #6464ff;
    }
    .icon {
      background-color: #6464ff;
    }
  }
  &.danger {
    &:hover {
      outline-color: rgba(245, 147, 133, 1);
    }
    .icon {
      background-color: rgba(245, 147, 133, 1);
    }
  }
  &:hover {
    outline: 1px solid;
  }

  .icon {
    height: 100%;
    width: 100%;
  }
}

.table-icon-btn-tooltip {
  &.show {
    opacity: 1;
  }
  padding: 5px 11px;
  border-radius: 4px;
  background-color: rgba(18, 18, 19, 1);
  color: #ffffff;
}
