@import "../../../styles/rootStyles.scss";

$assets_path: "../../../assets/images";
$assets_icons: "../../../assets/icons";

$color-bubble-user: #f5f5f5;
$color-bubble-treble: #dce7eb;
$color-bubble-note: $yellow-lighter;

.mobile {
  display: none;
  @media (max-width: $tablet - 1) {
    display: block !important;
  }
}

.tablet {
  display: none;
  @media (min-width: $tablet) {
    display: block !important;
  }
}

.desktop {
  display: none;
  @media (min-width: 1280px) {
    display: block !important;
  }
}
.channel-selector {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 2px;
  width: 400px;
  cursor: pointer;
  margin-bottom: 8px;
  p.bold {
    font-weight: 600;
    color: #1a1a1a;
  }
  h6 {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .icon {
    &--whatsapp-green {
      width: 24px;
      height: 24px;
      background-image: url($assets_icons + "/wha-green.svg");
      margin-right: 8px;
    }
  }
}
.channel-option {
  padding-left: 16px;
  h6 {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  p {
    color: #767676;
    margin-top: 2px;
  }
}
.cellphone,
.name,
.parameters,
.preview {
  margin-bottom: 16px;
  h6 {
    font-family: $family-primary;
    margin: 8px 0px;
  }
  .form-group {
    margin-bottom: 0px;
    .input-group {
      display: flex;
      flex-direction: row;
    }
    .input-group-prepend {
      background: #f5f5f5;
      border-radius: 2px 0px 0px 2px;
      height: 40px;
      a {
        padding-top: 13px;
      }
      .drop-content {
        display: flex;
        p.r {
          font-family: $family-primary;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      .country-item-name,
      .country-item-phone {
        font-family: $family-primary;
      }
    }
    .cellphone-input,
    .name-input,
    .parameter-input {
      height: 42px;
      outline: 0;
      flex-grow: 1;
      border: 1px solid #e6e6e6;
      border-radius: 2px;
      text-indent: 16px;
      width: 100%;
      &:focus {
        border-color: $black;
      }
      &.missing {
        border-color: $red;
      }
    }
    ::placeholder {
      color: #b2b2b2;
      font-family: $family-primary;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
    }
    input:not([value=""]) {
      font-family: $family-primary;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
    }
  }
}

.files-and-docs {
  .selector {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ebebeb;
    .files,
    .docs {
      width: 116px;
      display: flex;
      justify-content: center;
      padding-top: 24px;
      padding-bottom: 14px;
      color: #3c5a73;
      font-weight: 500;
      font-size: 16px;
    }
    .selected {
      border-bottom: 1px solid #3c5a73;
    }
  }
  .month-groups {
    margin-left: 26px;
    margin-right: 25px;
    padding-top: 14px;
    .month {
      color: #3c5a73;
      font-weight: 600;
      font-size: 16px;
      margin-top: 16px;
      text-transform: capitalize;
    }
    .files {
      margin-top: 14px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 8px;
      .image {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        width: 84px;
        height: 84px;
        overflow: hidden;
        margin: auto;
      }
    }
    .docs {
      .document,
      .audio {
        width: -webkit-fill-available;
        margin-top: 14px;
        height: 66px;
        border: 1px solid #c8d1d8;
      }
      .document {
        border: 1px solid #c8d1d8;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 22px 14px 22px 18px;
        .name {
          width: 186px;
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 0;
          color: #3c5a73;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon {
          background: #000000;
          cursor: pointer;
          margin-left: auto;
          width: 33px;
          height: 33px;
        }
      }
      .download-audio {
        margin-top: 6px;
        display: flex;
        justify-content: flex-end;
        .text {
          cursor: pointer;
          font-weight: 500;
          font-size: 15px;
          color: #c8d1d8;
          &:hover {
            color: #5087ff;
          }
        }
      }
      .audio {
        display: flex;
        align-items: center;
        gap: 12px;
        .icon {
          background-color: #ffffff;
          height: 15px;
          width: 13px;
          cursor: pointer;
        }
        .rhap_container {
          background-color: transparent;
          box-shadow: none;
          height: -webkit-fill-available;
          padding: 0;
          .rhap_main {
            .rhap_progress-section {
              .rhap_time {
                display: none;
              }
              .rhap_progress-container {
                width: 200px;
                .rhap_progress-bar {
                  .rhap_progress-indicator {
                    background-color: white;
                  }
                  .rhap_progress-filled {
                    background-color: black;
                  }
                }
              }
            }
            .rhap_additional-controls, .rhap_volume-controls {
              display: none;
            }
            .rhap_main-controls {
              .rhap_main-controls-button {
                color: black;
                svg {
                  background-color: white;
                  border-radius: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.snippets {
  overflow-y: scroll;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  .not-created {
    padding-top: 18px;
    margin-left: 25px;
    margin-right: 25px;
    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #6464ff;
    }
    .arrow {
      width: 32.98px;
      height: 32.98px;
      margin-left: 145px;
      margin-top: 5px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(#{$assets_path}/snippets-chats-arrow.svg);
    }
    .admin {
      width: 271px;
      height: 297px;
      margin-top: 5.04px;
      display: flex;
      .image {
        margin-left: 12px;
        width: 58px;
        height: 251px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(#{$assets_path}/snippets-admin-menu.svg);
      }
      .text {
        width: 165px;
        height: 135px;
        margin-top: 62px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #000000;
      }
      .bubble {
        display: flex;
        align-items: center;
        margin-left: 12px;
        .tail {
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-right: 4px solid #000000;
        }
        .message {
          height: 32px;
          padding-left: 12.5px;
          padding-right: 12.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #000000;
          color: #ffffff;
          font-weight: 700;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
  .searchbar {
    width: 271px;
    margin: auto;
    margin-top: 17px;
  }
  .down {
    transform: translateX(6px) translateY(6px) !important;
    box-shadow: none !important;
  }
  .snippet {
    cursor: pointer;
    margin: auto;
    margin-top: 20px;
    width: 271px;
    height: max-content;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    line-height: 21px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 0;
    box-shadow:
      6px 6px 0px rgba($color: #ffffff, $alpha: 1),
      5px 7px 0px rgba($color: #ebebeb, $alpha: 1),
      7px 7px 0px rgba($color: #ebebeb, $alpha: 1),
      7px 5px 0px rgba($color: #ebebeb, $alpha: 1);
    &.fixed {
      .header {
        .icon {
          background: #000000;
        }
      }
    }
    &:hover {
      box-shadow: 6px 6px 0px rgba($color: #ebebeb, $alpha: 1);
    }
    .header {
      display: flex;
      align-items: center;
      margin: 0px;
      padding: 0px;
      padding-left: 20px;
      padding-right: 20px;
      border: none;
      font-size: 16px;
      height: inherit;
      .text {
        font-weight: 600;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon {
        width: 11.08px;
        height: 16.83px;
        margin-left: auto;
        z-index: 10;
        background: rgba(0, 0, 0, 0.2);
        &:hover {
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .body {
      font-size: 12px;
      padding: 21px 23px;
      .text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        color: #1a1a1a;
        padding-right: 5px;
      }
    }
    .footer {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: 230px;
        height: 33px;
        color: #ffffff;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #000000;
        border-radius: 4px;
        .icon {
          width: 13.33px;
          height: 13.33px;
          margin-left: 9.33px;
        }
      }
    }
  }
}

.mobile-modal {
  .modal-content {
    padding: 0;
    width: -webkit-fill-available !important;
    height: fit-content !important;
    max-height: 565px;
    max-width: 376px;
    margin: auto;
    margin-left: 8px;
    margin-right: 8px;
    /* display: flex; */
    position: relative;
    border-radius: 4px;
    .close {
      display: flex;
      justify-content: flex-end;
      .icon-close {
        position: fixed;
        top: unset;
        right: unset;
        background: #ffffff;
        transform: translateY(-44px);
        margin-right: 10px;
      }
    }
    .modal-header {
      display: none;
    }
    .modal-body {
      padding: 0;
      .list-labels {
        max-height: 100%;
      }
    }
  }
}

.snippets-modal {
  .modal-content {
    .modal-body {
      .snippets {
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background: #000000;
          border-radius: 800px;
        }
        .searchbar {
          width: -webkit-fill-available;
          margin: 26px 21px 19px 21px;
        }
        .snippet {
          width: -webkit-fill-available;
          margin: 0px 21px;
          box-shadow: unset;
          border: none;
          padding: 9px 0px 9px 13px;
          font-size: 15px;
          color: #1a1a1a;
          &:focus {
            background: #dce7ff;
          }
          .header,
          .body {
            padding: 0;
          }
          .header {
            position: relative;
            .icon {
              position: absolute;
              top: 14px;
              right: 0;
              margin-right: 6px;
            }
          }
          .body .text {
            -webkit-line-clamp: 1;
          }
          .footer {
            display: none;
          }
        }
      }
    }
  }
}

.hubspot-panel-modal {
  &.option-selected {
    .modal-content {
      padding: 0;
      .close .icon {
        background: #ffffff;
        width: 14px;
        height: 14px;
        top: 25px;
      }
      .modal-header {
        height: 62px;
        background: #1a1a1a;
        color: #ffffff;
        justify-content: flex-end;
        font-weight: 500;
        font-size: 16px;
        padding-right: 55px;
      }
      .modal-body {
        padding: 0;
        margin: 0;
        height: -webkit-fill-available;
        iframe {
          width: -webkit-fill-available;
        }
      }
    }
  }
  .modal-content {
    .modal-body {
      margin: 58px;
      display: flex;
      justify-content: center;
      .hubspot-options {
        .hubspot-panel-category {
          width: 231px;
          height: 170px;
          justify-content: center;
          margin-bottom: 20px;
          flex-flow: column;
          background: rgba(243, 119, 58, 0.04);
          border: 1px solid #f3773a;
          border-radius: 5px;
          &:last-child {
            margin-bottom: 0;
          }
          img {
            width: 44px;
          }
          p {
            font-weight: 600;
            font-size: 16px;
            color: #3c5a73;
          }
        }
      }
    }
  }
}

.side-panel {
  height: 100vh;
  position: relative;
  width: 320px;
  min-width: 320px;

  &.hubspot-panel {
    &.extend {
      width: 658px;
    }

    &.collapse {
      width: 320px;
    }

    .toggle-side-panel {
      position: absolute;
      top: 50%;
      left: -8px;
      width: 16px;
      height: 32px;
      background-color: black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .treble-icon {
        height: 12px;
        width: 5px;
      }
    }

    .sp-header {
      height: 66px;
      padding: 23px 40px;
      display: flex;
      align-items: center;
      .treble-icon {
        margin-left: auto;
        height: 13px;
        width: 13px;
        align-items: center;
        cursor: pointer;
      }
      border-bottom: 1px solid #dddfe0;
    }

    .hubspot-iframe {
      height: calc(100vh - 72px);
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  &.has-hubspot {
    .dropdown-content {
      height: calc(100vh - 231px) !important;
    }
  }
  .option {
    .dropdown {
      width: -webkit-fill-available;
      display: block;
      &:hover {
        .dropdown-trigger {
          .icon {
            background: #1a1a1a !important;
          }
          .item {
            color: #1a1a1a !important;
          }
        }
      }
      &.is-active {
        .dropdown-trigger {
          .icon {
            background: #3c5a73 !important;
            &--dropdown-row {
              transform: rotate(180deg);
            }
          }
          .item {
            color: #3c5a73 !important;
          }
        }
      }
      .dropdown-trigger {
        width: inherit;
        .button {
          height: 50px;
          width: -webkit-fill-available;
          padding: 0px 37px 0px 40px;
          border: none;
          border-radius: 0;
          .item {
            color: #9dacb9;
            display: flex;
            align-items: center;
            .icon {
              width: 16.67px;
              height: 16.67px;
              margin: 0;
              margin-right: 20px;
            }
          }
          .icon {
            background: #9dacb9;
            margin-left: auto;
            width: 10px;
            height: 5px;
          }
        }
      }
      .dropdown-menu {
        padding: 0px;
        width: -webkit-fill-available;
        position: inherit;
        .dropdown-content {
          padding: 0px;
          border-radius: 0;
          height: calc(100vh - 150px);
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
          overflow-y: scroll;
          box-shadow: none;
        }
        .dropdown-content::-webkit-scrollbar {
          display: none;
        }

        .contact-info {
          max-height: 100%;
          padding: 24px 24px 0px 24px;
          .deals-container {
            margin-bottom: 400px;
            .icon.icon--down {
              width: 16px;
              height: 16px;
              margin-left: auto;
              background-image: url($assets_icons + "/chevron-down.svg");
            }
            .dropdown {
              width: -webkit-fill-available;
              .dropdown-trigger {
                width: -webkit-fill-available;
                .deal-selector {
                  cursor: pointer;
                  align-items: center;
                  display: flex;
                  width: -webkit-fill-available;
                  border: 1px solid #e9e9e9;
                  box-sizing: border-box;
                  border-radius: 2px;
                  padding: 8px 8px;
                  margin-top: 16px;
                }
              }
            }
            .dropdown-menu {
              p {
                color: rgba(0, 0, 0, 0.85);
              }
            }
          }
          .link-helpdesk {
            display: flex;
            justify-content: center;
            margin-top: 13px;
            align-items: center;
            a {
              color: #f3773a;
              font-family: "SpaceGrotesk";
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 21px;
            }
            .external-link {
              width: 16px;
              height: 16px;
              background-image: url($assets_path + "/external-link.svg");
              cursor: pointer;
              margin-left: 8px;
            }
          }
          .contact-header {
            text-align: center;
            .contact-photo {
              position: relative;
              .ant-avatar {
                display: inline-block;
                background-color: #1a1a1a;
                font-size: 36px !important;
                border-radius: 100%;
                color: #ffffff;
              }
              img {
                width: 120px;
                border-radius: 100%;
              }
              span {
                font-weight: 500;
              }
              .logo {
                width: 27px;
                height: 27px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                bottom: 0px;
                top: 59px;
                right: 94px;
                border-radius: 100%;
                &--hubspot-logo {
                  background-image: url($assets_path + "/hubspot.png");
                }
              }
            }
            h4 {
              margin-top: 20px;
              font-weight: 600;
              color: #3c5a73;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 400px;
            }
          }
          .contact-body,
          .contact-owner,
          .contact-custom-properties {
            h5 {
              font-size: 18px;
              font-weight: 500;
              line-height: 21px;
              color: #9dacb9;
            }
            h6 {
              margin-top: 24px;
              margin-bottom: 19px;
              font-family: "SpaceGrotesk";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 21px;
              color: rgba(0, 0, 0, 0.85);
            }
            p {
              font-weight: 500;
              font-size: 15px;
              color: #3c5a73;
              margin-bottom: 19px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 400px;
            }
          }
        }
      }
    }
  }
  .hubspot-dashboard {
    cursor: pointer;
    height: 66px;
    padding-left: 33px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    color: #3c5a73;
    &:hover {
      color: #f3773a;
      .icon {
        background: #f3773a;
      }
    }
    .icon {
      width: 33px;
      height: 33px;
      background-color: #3c5a73;
      margin-right: 10px;
    }
  }
  .scroll-info {
    position: absolute;
    top: 60px;
    height: 47px;
    background-color: black;
    width: 100%;
    padding: 8px 16px;
    align-items: center;
    display: flex;
    p {
      height: 33px;
      width: 211px;
      color: white;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      gap: 8px;
      align-items: center;
      font-family: "HKGrotesk";
    }
    .arrow-emoji {
      font-family:
        apple color emoji,
        segoe ui emoji,
        noto color emoji,
        android emoji,
        emojisymbols,
        emojione mozilla,
        twemoji mozilla,
        segoe ui symbol;
    }
    .icon {
      height: 8px;
      width: 8px;
      margin-left: auto;
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%)
        hue-rotate(173deg) brightness(106%) contrast(96%);
    }
  }
}

.main-content {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: calc(100% - 76px);
  @media (max-width: $desktop) {
    width: 100%;
  }
  flex-direction: column;

  .navbar {
    flex: 0 1 auto;
  }

  .scroll-loader {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 100%;
    }
  }

  .columns {
    height: 100%;
    flex: 1 1 auto;
    overflow: auto;

    .column {
      width: 100vw;
      @media (min-width: $tablet + 1) {
        width: 65vw;
      }
      @media (min-width: $desktop) {
        width: 70vw;
      }
    }

    .loading-chats-container {
      text-align-last: center;
      margin-top: 50px;
      @keyframes spinner {
        0% {
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }
        100% {
          transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
      }
      .custom-treble-loader::before {
        animation: 1.5s linear infinite spinner;
        animation-play-state: inherit;
        border: 5px solid #dee5f7;
        border-bottom-color: #000000;
        border-radius: 50%;
        content: "";
        height: 40px;
        width: 40px;
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
        margin-left: 162px;
      }
      p {
        padding-top: 34px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .no-results {
      margin-top: 18px;
      .image {
        width: 117.43px;
        height: 116.43px;
        margin: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(#{$assets_path}/noElements.svg);
      }
      .text {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-left: 26px;
        margin-right: 26px;
      }
    }

    .chats {
      &.hide {
        display: none;
      }
      flex: none;
      width: 338px;
      @media (max-width: $tablet - 1) {
        width: -webkit-fill-available;
        .tabs,
        .search-filter-conversations {
          justify-content: center !important;
        }
      }
      background: #ffffff;
      height: 100%;
      border-right: 1px solid $light-gray-border-color;
      display: flex;
      flex-direction: column;

      .tabs {
        display: flex;
        justify-content: inherit;
        overflow: visible;
        height: fit-content;
        margin: 0px;
        padding: 23px 23px 0px 23px;
        color: #9dacb9;
        @media (max-width: $tablet - 1) {
          border-bottom: 1px solid #ebebeb;
        }
        .text {
          display: flex;
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          padding-bottom: 14px;
          &:first-child {
            margin-right: 32px;
          }
          &:hover {
            color: #1a1a1a;
          }

          .count {
            margin-left: 9px;
            font-weight: 600;
          }
          &.is-active {
            color: #1a1a1a;
            border-bottom: 1px solid #1a1a1a;
          }
        }
      }

      .divider {
        margin: 20px 0px 0px 0px;
        border: 1px solid #e9e9e9;
      }

      .number-conversations,
      .number-contacts {
        font-size: 13px;
        margin: 24px 0px 8px 24px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 18px;
      }

      .search-filter-conversations {
        display: flex;
        align-items: center;
        padding: 30px 30px 25px 23px;
        border-bottom: 1px solid #ebebeb;
        @media (max-width: $tablet - 1) {
          padding: 23px 18px;
          background: #fafafa;
        }
        .find-options {
          width: -webkit-fill-available;
          display: flex;
          align-items: center;
          .contact-button {
            width: 250px;
            background: #6464ff;
            margin-right: 17px;
            border: none;
            border-radius: 4px;
            &:hover {
              background: rgba(100, 100, 255, 0.8);
            }
            button {
              margin: 0px;
              height: 43px;
              width: -webkit-fill-available;
            }
            .button,
            .btn {
              padding-top: 11px;
              padding-bottom: 11px;
              border: none;
              p {
                font-weight: 600;
                font-size: 16px;
                color: #ffffff;
                line-height: 0px;
              }
            }
            .icon {
              width: 19.25px;
              height: 14px;
              margin: 0px !important;
              margin-right: 14.75px !important;
            }
            @media (max-width: $tablet - 1) {
              width: -webkit-fill-available;
              button {
                height: 54px;
              }
            }
          }
          .lookup-icon {
            .icon {
              cursor: pointer;
              width: 18px;
              height: 18px;
              background: #3c5a73;
              &:hover {
                background: rgba(60, 90, 115, 0.5);
              }
            }
          }
        }
        .searchbar {
          background: #fcfcfc;
          .icon {
            &--search {
              margin-left: 11.25px;
              margin-right: 14.26px;
            }
          }
        }
      }

      .all-chats,
      .all-contacts {
        max-height: calc(100% - 112px);
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .no-active-conversations {
        width: -webkit-fill-available;
        margin: 17px;
        padding: 20px 25px;
        background: #fffae6;
        border: 1px solid #ffdb4c;
        .title {
          color: #202020;
          font-weight: 600;
          font-size: 15px;
        }
        .body {
          margin-top: 18px;
          color: #202020;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.396px;
        }
      }

      .select-container {
        &.active {
          padding: 1px;
          background: linear-gradient(to right, #afc3d2 50%, transparent 0%),
            linear-gradient(to bottom, #afc3d2 50%, transparent 0%),
            linear-gradient(to right, #afc3d2 50%, transparent 0%),
            linear-gradient(to bottom, #afc3d2 50%, transparent 0%);
          background-position:
            0 0,
            0 100%,
            100% 100%,
            100% 0;
          background-size:
            12px 1px,
            1px 12px,
            12px 1px,
            1px 12px;
          background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
          .chat,
          .contact {
            border: none;
          }
        }
      }

      .chat,
      .contact {
        display: flex;
        padding: 22px 0px 22px 23px;
        border-top: 1px solid $light-gray-border-color;
        border-bottom: 1px solid $light-gray-border-color;
        cursor: pointer;

        &:hover {
          background-color: #fafafa;
          margin: 0px;
        }

        .dropdown {
          margin-left: auto;
          display: none;
          .dropdown-menu {
            transform: translateX(-100%);
            .dropdown-item {
              &:active {
                color: black;
              }
              .chat-option {
                display: flex;
                flex-direction: row;
                line-height: 17px;

                .icon {
                  margin-right: 8px;
                }
              }
            }
          }
        }

        &:hover {
          .dropdown {
            position: relative;
            left: 0;
            top: 0;
            display: flex;
            .icon {
              &:hover {
                filter: brightness(0) saturate(100%);
              }
            }
          }
        }

        .user-logo {
          width: 48px;
          min-width: 48px;
          height: 48px;
          border-radius: 5px;
          margin-right: 16px;
          position: relative;
        }

        .messages,
        .contact-information {
          width: -webkit-fill-available;
          padding-right: 23px;
          .contact-main-info {
            display: flex;
            align-items: center;
            .user-name {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .deploy-label-status {
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #24446a;
              border: 1px solid #24446a;
              height: 28px;
              margin-left: auto;
              display: flex;
              align-items: center;
              padding: 0px 10px;
              min-width: fit-content;
            }
            .deploy-label-date {
              margin-left: auto;
              color: #778b9d;
              font-weight: 500;
              font-size: 13px;
              line-height: 17px;
            }
          }
          .contact-main-info:last-child {
            margin-top: 21px;
          }

          .user-name,
          .user-last-message {
            :last-child {
              margin-left: auto;
              margin-right: 30px;
            }
          }

          .user-name {
            font-size: 15px;
            color: #3c5a73;
            font-weight: 700;
            line-height: 21px;
            display: flex;
            align-items: center;
            .chat-is-redirected {
              position: relative;
              .tooltip {
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #282828;
                border-radius: 2px;
                color: #ffffff;
                font-weight: 500;
                font-size: 13px;
                padding: 0;
                padding-left: 11px;
                padding-right: 11px;
              }
              .tooltip::after {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top-color: #282828;
              }
            }
            .icon {
              width: 20.46px;
              height: 9px;
              margin-right: 15.77px;
            }
            p {
              width: 70%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .last-hour {
              font-size: 13px;
              color: #55789b;
              font-weight: 500;
            }
          }

          .user-last-message {
            display: flex;
            align-items: center;
            font-family: "SpaceGrotesk";
            font-size: 15px;
            color: #55789b;
            font-weight: 400;
            line-height: 16px;
            margin-top: 17px;

            /* new css */
            max-width: 100%;

            p {
              margin-left: 0 !important;
            }

            .message-status {
              .icon {
                width: 20.34px;
                height: 11.73px;
                margin: 0;
              }
            }

            .message-error {
              .icon {
                width: 20px;
                height: 20px;
                margin: 0;
                margin-right: 9px;
              }
            }

            p {
              max-width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .unread {
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #ebfff0;
              color: #19973c;
              border: 1px solid #19973c;
              font-weight: 600;
              font-size: 15px;
              border-radius: 50%;
            }
          }

          .user-cellphone {
            font-size: 15px;
            color: #55789b;
            font-weight: 400;
            line-height: 21px;
          }

          .chat-labels {
            display: flex;
            flex-wrap: wrap;
            .label-item {
              margin-top: 14px;
              width: fit-content;
              height: 28px;
              word-wrap: break-word;
              hyphens: auto;
              white-space: normal;
            }
          }
        }

        &.has-unread {
          .unread {
            opacity: 1;
          }
        }
      }

      .pending-bar {
        margin: 0px 27px;
        height: 32px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $light-gray-border-color;

        h1 {
          font-size: 15px;
          font-weight: 600;
          color: $black-color;
        }
      }

      .order-options {
        display: flex;
        width: -webkit-fill-available;
        .order-dropdown {
          width: -webkit-fill-available;
          margin-right: 17px;
          &.has-color {
            margin-right: 8px;
          }
          &:hover {
            .button {
              background: rgba(235, 235, 255, 0.19);
            }
          }
          .dropdown-trigger {
            width: -webkit-fill-available;
          }
          .button {
            width: -webkit-fill-available;
            height: 43px;
            display: flex;
            align-items: center;
            border: 1px solid #f5f5f5;
            padding: 11px 12px;
            background: #fcfcfc;
            border-radius: inherit;

            .text {
              max-width: 220px;
              margin-right: 18px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-style: normal;
              font-size: 15px;
              line-height: 20px;
              text-align: left;
              color: #3c5a73;
            }

            .icon {
              background: #3c5a73;
              margin-left: auto !important;
              -webkit-mask-size: contain;
              &--dropdown-row {
                margin-top: 2px;
                margin-right: 6px;
                width: 10px;
                height: 6px;
              }
            }

            &:focus {
              border: 1px solid #6464ff;
              .text {
                color: #6464ff;
              }
              .icon {
                background: #6464ff;
              }
            }
          }

          .dropdown-content {
            width: -webkit-fill-available;
            padding: 12px 0px;
            box-shadow: 0px 7px 28px rgba(39, 39, 39, 0.15);
            border-radius: 4px;
            .dropdown-item {
              display: flex;
              align-items: center;
              padding: 9.5px 0px 9.5px 22px;
              text-transform: lowercase !important;
              .text-item {
                line-height: 16px;
                display: inline-block;
                width: 200px !important;
                color: #3c5a73;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
              .icon {
                margin-left: auto;
                margin-right: 25px;
                width: 10.26px;
                height: 7.26px;
                &--sent {
                  background-image: url(#{$assets_icons}/sent.svg);
                }
              }
            }
          }

          // Mobile
          @media (max-width: $tablet - 1) {
            min-width: 185px;
            .button {
              height: 54px;
              background: #6464ff;
              border: none;
              .text {
                color: #ffffff;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
              }
              .icon {
                background: #ffffff;
              }
            }
            .dropdown-menu {
              width: -webkit-fill-available;
              .dropdown-content {
                width: -webkit-fill-available;
                min-width: 250px;
                .dropdown-item {
                  .text-item {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
        .color-label-dropdown {
          min-width: 67px;
          @media (max-width: $tablet - 1) {
            width: auto;
            .dropdown-trigger {
              .button {
                background: #ffffff;
                border: 1px solid #e8e8e8;
                border-radius: 2px;
                justify-content: start;
                padding: 16px 12px;
                .icon {
                  background: #3c5a73;
                }
                .color-selector {
                  width: -webkit-fill-available;
                  p {
                    display: block;
                    font-weight: 500;
                    font-size: 16px;
                  }
                }
              }
            }
            .dropdown-content {
              min-width: 91px !important;
              width: 91px !important;
            }
          }
          .color-selector {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          p {
            display: none;
          }
          .button .icon {
            margin: 0;
          }
          .color {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            &.default {
              background: #949494;
            }
            &.red {
              background: #f06450;
            }
            &.orange {
              background: #f88923;
            }
            &.yellow {
              background: #caaa03;
            }
            &.green {
              background: #4aaa99;
            }
            &.blue {
              background: #0046e1;
            }
            &.lavender {
              background: #6464ff;
            }
            &.classic {
              background: #115a71;
            }
          }
          .dropdown-content {
            width: 91px;
            max-height: 153px;
            padding: 0;
            overflow: auto;
            .color-selector {
              padding: 13px 17px;
              .icon {
                margin-left: auto;
                width: 10px;
                height: 7px;
              }
            }
          }
        }
      }
    }

    .chat-container {
      display: flex;
      height: 100%;

      .chat-window:focus {
        outline-width: 0;
      }

      .chat-window {
        height: 100%;
        border-right: 1px solid $light-gray-border-color;
        display: flex;
        align-items: stretch;
        flex: 1 1 auto;
        overflow: auto;
        flex-direction: column;
        position: relative;
        flex-flow: column;

        &.full-screen {
          .show-chat {
            margin: 0 !important;
            border: 18px solid #1a1a1a !important;
            border-radius: 0 !important;
            max-width: unset !important;
          }
          .chat-window-header {
            padding-left: 95px !important;
            min-height: 95px;
          }
        }

        .chat-window-header {
          width: -webkit-fill-available;
          padding-left: 23px;

          @media (max-width: $tablet  - 1) {
            padding: 0;
            color: white !important;
            background-color: #1a1a1a;
            min-height: 62px;
            .bar {
              display: none;
            }
            .chat-header {
              height: 100%;
            }
          }

          .chat-header {
            height: 66px;
            display: flex;
            width: -webkit-fill-available;
            align-items: center;
          }

          .mobile-chat-options {
            @media (max-width: $tablet - 1) {
              display: flex !important;
            }
            align-items: center;
            margin-left: auto;
            margin-right: 18px;
            .close-conversation {
              display: flex;
              padding: 0;
              border: none;
              background: unset;
              margin-right: 28px;
              .icon {
                width: 18px;
                height: 18px;
                -webkit-mask-size: contain;
                background: #ffffff;
              }
            }
            .dropdown {
              .dropdown-trigger {
                .button {
                  border: none;
                  padding: 0;
                  background-color: #1a1a1a;
                  .icon {
                    width: 16px;
                    height: 16px;
                    margin: 0;
                  }
                  span {
                    display: none;
                  }
                }
              }
              .dropdown-menu {
                box-shadow: 0px 2px 8px rgba(106, 170, 170, 0.15);
                border-radius: 2px;
                transform: translate(-94%, -13%);

                .dropdown-content {
                  width: 267px;
                  .dropdown {
                    &.is-active {
                      .dropdown-menu {
                        display: block;
                      }
                    }
                    .dropdown-menu {
                      display: none;
                    }
                  }
                  .mobile-option {
                    padding: 16px 20px;
                    display: flex;
                    align-items: center;
                    color: #3c5a73;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    .icon {
                      margin-right: 17px;
                      background: #9dacb9;
                    }
                  }
                }
              }
            }
          }

          &.bottom-line {
            min-height: 66px;
            border-bottom: 1px solid #ebebeb;
            .chat-header {
              height: 100%;
            }
          }

          .chat-labels {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            top: -10px;
            .label-item {
              margin-top: 8px;
            }
            @media (max-width: $tablet - 1) {
              display: none;
            }
          }

          .chat-information {
            margin: 0;
            display: flex;
            align-items: center;
            .bar {
              height: 28px;
              width: 1px;
              background: #ebebeb;
              margin: 0px 25px 0px 25px;
            }

            .session {
              @media (max-width: $tablet - 1) {
                display: none;
              }
              display: flex;
              align-items: center;
              .text {
                color: #3c5a73;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                margin: 0px 9px 0px 12px;
              }
              span {
                color: #3c5a73;
                font-weight: 700;
                font-size: 14px;
                margin-top: 3px;
              }
            }

            .title-chat {
              display: flex;
              align-items: center;
              max-width: 340px;

              .icon-hubspot {
                margin-left: 16px;
                margin-right: 8px;
                width: 24px;
                height: 24px;
                background-image: url($assets_path + "/hubspot.png");
                background-size: contain;
                border-radius: 100%;
              }
              h1 {
                color: #3c5a73;
                @media (max-width: $tablet - 1) {
                  color: white;
                }
                font-size: 16px;
                margin-bottom: 0px;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media (max-width: $tablet - 1) {
                  max-width: 130px;
                }
              }
            }
          }

          .chat-options {
            display: flex;
            flex-direction: row;
            margin-right: 28px;
            margin-left: auto;
            @media (max-width: $tablet - 1) {
              display: none;
            }
            .screen {
              display: flex !important;
              align-items: center;
              margin-left: 91.73px;
              cursor: pointer;
              p {
                color: #6aaaaa;
                font-weight: 500;
                font-size: 16px;
              }
              .icon {
                background: #6aaaaa !important;
              }
            }

            .treble-hover-button {
              width: 38px;
              .icon {
                width: 19.9px;
                height: 19.9px;
                &--tranfer-agent {
                  width: 21px;
                }
                &--edit-contact {
                  width: 23px;
                }
                &--finish-chat {
                  width: 19.9px;
                  height: 19.9px;
                }
                &--full-screen,
                &--collapse-screen {
                  width: 14px;
                  height: 14px;
                }
                -webkit-mask-size: contain;
                background: #9dacb9;
              }
              &:hover {
                background-color: transparent !important;
                .icon {
                  background: #6aaaaa;
                  filter: unset !important;
                }
              }
            }
            .dropdown {
              .dropdown-menu {
                transform: translateX(-93%) translateY(-6%);
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                border-radius: 4px;
                top: calc(100% + 15px);
                .dropdown-content {
                  max-height: 356px;
                  .filter-options {
                    .dropdown-content {
                      height: 171px;
                    }
                  }
                }
                .dropdown-item {
                  .chat-option {
                    display: flex;
                    flex-direction: row;
                    div {
                      color: $black;
                      line-height: 17px;
                      &.icon {
                        margin-right: 8px;
                      }
                    }
                  }
                }
              }
              &.is-active {
                .dropdown-trigger {
                  .icon {
                    background: #6aaaaa;
                  }
                }
              }
              .chat-option {
                .icon {
                  filter: brightness(0) saturate(100%);
                }
              }
            }
          }

          .back-chats .icon {
            margin-left: 27px;
            background: #ffffff;
          }

          .options-dropdown {
            margin-left: 0px;
            margin-right: 27px;
            &.purple-dropdown {
              button {
                display: none;
                @media (min-width: $tablet) {
                  display: flex;
                }
                background-color: $purple-color;
                border: none;
                color: white;
              }
            }
            .ellipsis {
              height: 22px;
            }
            .dropdown-menu {
              left: inherit;
              right: 0px;
            }
            img {
              margin-bottom: -2px;
              margin-right: 5px;
            }
          }
        }

        .cont {
          position: relative;
          flex: 1 1 auto;
          overflow: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          height: 100%;

          width: 100%;

          &:focus {
            outline: none;
          }

          .drop-file-input {
            &.drop-zone {
              background-image: url(#{$assets_path}/chat-background.svg);
            }
            .previous-file-view {
              padding: 0;
              .header-content {
                justify-content: flex-start;
                margin: 27px 0px 58px 32px;
              }
              .content-file {
                img,
                video {
                  max-width: 372px;
                }
                .drop-file-name {
                  margin-top: 30px;
                }
              }
              .button {
                width: 307px;
                height: 47px;
                display: flex;
                margin: auto;
                margin-top: 30px;
                background: #6464ff;
                border-radius: 4px;
                color: #ffffff;
                font-weight: 600;
                font-size: 18px;
              }
            }
          }

          &.show-chat {
            width: -webkit-fill-available;
            max-width: 1071px;
            margin: 15px;
            box-shadow: 0px 0px 14px rgba(85, 120, 155, 0.17);
            border-radius: 10px;

            background-image: url(#{$assets_path}/chat-background.svg);

            @media (min-width: 1835px) {
              margin: 15px auto 15px auto;
            }
            @media (max-width: $tablet - 1) {
              margin: 0;
            }
          }
          .chats-mobile-tabs {
            display: flex;
            height: 51px;
            border-bottom: 1px solid #dfe1e6;
            align-items: center;
            background: #fafafa;
            @media (min-width: $desktop) {
              display: none;
            }
            .header-item {
              width: -webkit-fill-available;
              position: relative;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &:first-child {
                border-right: 1px solid #dfe1e6;
              }
              .icon {
                background: #3c5a73;
                -webkit-mask-size: contain;
                margin-right: 15px;
                &--snippet-templates {
                  width: 18px;
                  height: 18px;
                }
                &--hubspot-dashboard {
                  width: 23px;
                  height: 23px;
                }
              }
              p {
                font-weight: 500;
                font-size: 17px;
                color: #3c5a73;
              }
            }
          }

          .scroll-down-button {
            position: relative;
            height: 0px;
            .scroll-down {
              width: 32px;
              height: 32px;
              background-image: url(#{$assets_path}/scroll-down.svg);
              position: absolute;
              right: 0;
              transform: translate(-20px, -44px);
              cursor: pointer;
              z-index: 1;
              &:hover {
                background-image: url(#{$assets_path}/scroll-down-green.svg);
              }
            }
          }

          .chat-channel-container {
            display: flex;
            background: #fafafa;
            align-items: center;
            padding: 15px 30px;
            p {
              color: #3c5a73;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
            }
            .icon {
              width: 18px;
              height: 18px;
              margin-right: 14px;
            }
          }

          .chat-main-window {
            max-height: 100%;
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column-reverse;

            &.no-chat-selected {
              overflow-y: hidden;
              display: flex;
              flex: 1;
              align-items: center;
              text-align: center;
              flex-direction: column;

              .no-chat-selected-image {
                width: 290.78px;
                height: 290.78px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url(#{$assets_path}/no-chat-selected.svg);
              }

              h1.no-chat-selected {
                font-weight: 400;
                font-size: 22px;
                color: #202020;
              }
            }

            .chat-info {
              text-align: center;
              span {
                background: rgba(245, 245, 245, 0.45);
                border-radius: 10px;
                padding: 5px 10px;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .chat-main-window-footer {
            position: relative;
            margin-top: auto;

            .chat-toolbar {
              width: calc(100% - 82px);
              position: absolute;
              left: 42px;
              bottom: 32px;
              display: flex;

              .icon {
                cursor: pointer;
                width: 16px;
                height: 16px;
                background-size: contain;
                background-repeat: no-repeat;
                &:hover {
                  opacity: 0.7;
                }
              }
              .ico-clip {
                background-image: url($assets_path + "/clip.svg");
                margin-right: auto;
              }
              .ico-send {
                background-image: url($assets_path + "/send-chat-gray.svg");
                &.blue {
                  background-image: url($assets_path + "/send-chat-blue.svg");
                }
              }
            }

            textarea {
              font-family: "HKGrotesk";
              height: 88px;
              margin: 8px 24px 16px 24px;
              border: 0px;
              padding: 10px;
              border-bottom: 30px solid transparent;
              outline: 2px solid #e6e6e6;
              font-size: 16px;
              color: $black-color;
              width: calc(100% - 48px);

              &::placeholder {
                color: rgba(0, 0, 0, 0.45);
              }

              &:focus {
                outline: 1px solid black;
              }
            }
          }

          .chat-tabs-notes {
            .session-expire {
              background: #111111;
              height: 78px;
              display: flex;
              align-items: center;
              padding: 17px 27px;
              .icon {
                width: 40px;
              }
              .text {
                margin-left: 15px;
                span {
                  color: #ffffff;
                  font-weight: 600;
                  font-size: 16px;
                }
                .green {
                  color: #5ab978;
                }
              }
            }
            .notes-out {
              height: 40px;
              background: #fff2b0;
              padding-left: 28px;
              display: flex;
              align-items: center;
              color: #21201b;
              font-weight: 400;
              font-size: 14px;
              .icon {
                cursor: pointer;
                width: 12px;
                height: 12px;
                margin-right: 12px;
                background: #21201b;
              }
            }
          }

          .editor-container {
            margin: 0;
            .textarea-wrapper {
              margin: 0;
              border: none;

              .mobile-toolbar {
                height: fit-content !important;
                padding-left: 16px !important;
                padding-top: 23px !important;
                .rdw-inline-wrapper {
                  padding: 0 !important;
                }
                .search-hsm-dropdown,
                .jumps,
                .hsm {
                  border: none !important;
                }
                .jumps {
                  margin-left: auto;
                }
              }

              &.read-only {
                .DraftEditor-root {
                  margin-top: 0 !important;
                }
                .jumps,
                .search-hsm-dropdown {
                  padding-left: 0 !important;
                  border-left: none !important;
                  overflow: unset;
                }
                .hsm {
                  &.icon-svg:hover {
                    filter: none !important;
                  }
                }
                .search-hsm-dropdown {
                  .rdw-option-wrapper {
                    background: #5ab978;
                    border-radius: 4px;
                    padding: 8px 16px;
                    .icon {
                      background: #000000;
                    }
                    span {
                      color: #000000;
                    }
                  }
                }
              }
              &.chat-tab {
                background: rgba(255, 255, 255, 0.6);
                &.writing {
                  background: #ffffff;
                }
                .DraftEditor-root {
                  margin-top: 20px;
                }
                .jumps,
                .search-hsm-dropdown,
                .rdw-inline-wrapper {
                  height: 25px;
                  padding-left: 20px;
                  border-left: 2px solid #dfe1e6;
                }
              }
              &.note-tab {
                background: #fffbe5;
                .emoji,
                .files,
                .hsm,
                .notes,
                .jumps {
                  span {
                    color: #e5dfba;
                  }
                  .icon {
                    background: #e5dfba;
                  }
                }
                .jumps,
                .search-hsm-dropdown,
                .rdw-inline-wrapper {
                  height: 25px;
                  border-left: 2px solid #e5dfba;
                  padding-left: 20px;
                }
                .send,
                .emoji {
                  .icon {
                    background: #807b67;
                  }
                }
                .voice,
                .files,
                .hsm,
                .notes,
                .jump {
                  &.rdw-option-wrapper {
                    cursor: initial;
                    .icon {
                      cursor: initial;
                    }
                  }
                  &.icon-svg:hover label,
                  &.icon-svg:hover div,
                  &.icon-svg:hover img {
                    filter: unset !important;
                  }
                }
                .rdw-inline-wrapper .icon-svg {
                  filter: invert(82%) sepia(23%) saturate(261%)
                    hue-rotate(14deg) brightness(102%) contrast(87%);
                }
                .icon-svg:hover {
                  filter: unset !important;
                }
                .tooltip {
                  display: none !important;
                }

                .text-editor {
                  .public-DraftEditorPlaceholder-inner {
                    font-weight: 500;
                    color: #262626;
                  }
                }
              }

              .tooltip {
                background: #282828;
                color: #ffffff;
                padding: 7px 22px;
              }
              .rdw-editor-toolbar {
                .icon {
                  -webkit-mask-size: contain;
                }

                .voice,
                .send {
                  margin-left: auto !important;
                }
                .emoji,
                .files {
                  order: -2;
                }
                .emoji {
                  margin: 0;
                }

                .jumps {
                  display: flex;
                  align-items: center;
                  .icon {
                    width: 21px !important;
                  }
                }

                .icon-svg:hover {
                  filter: invert(79%) sepia(13%) saturate(966%)
                    hue-rotate(131deg) brightness(78%) contrast(84%);
                }

                .rdw-option-wrapper {
                  cursor: pointer;
                  background: transparent;
                  padding: 0;
                  min-width: 0;
                  margin-right: 20px;
                  margin-left: 0px;
                  .icon {
                    cursor: pointer;
                    -webkit-mask-size: contain;
                    width: 17.5px;
                    height: 17.5px;
                    &--record {
                      width: 14px;
                      height: 20px;
                    }
                  }
                }
                .rdw-inline-wrapper,
                .rdw-option-center {
                  margin-bottom: 0 !important;
                }

                .search-hsm-dropdown {
                  display: flex;
                  align-items: center;
                  order: 0;
                  .icon {
                    width: 20px;
                    height: 20px;
                  }
                  .dropdown-menu {
                    transform: translateX(5%) translateY(-120%);
                    .dropdown-content {
                      width: 355px;
                      box-shadow: 0px 2px 8px rgba(106, 170, 170, 0.15);
                      border-radius: 2px;
                    }
                  }
                }
              }
              .toolbar-class {
                background: transparent;
                padding: 0;
                height: 60.27px;
                align-items: center;
                padding-left: 26px;
              }

              .text-editor {
                max-height: 150px;
                min-height: 45px;
                .public-DraftEditorPlaceholder-inner {
                  color: #3c5a73;
                  font-weight: 500;
                  font-size: 16px;
                }
              }
            }
          }

          .textarea-wrapper {
            margin-top: 0px;
          }

          .reply-bubble {
            border-radius: 0px 0px 2px 2px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            .reply-content {
              padding: 8px 17px;
              display: flex;
              align-items: center;
              width: -webkit-fill-available;
              min-width: 70px;

              .cut-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 230px;
              }
              .icon {
                width: 16px;
                height: 16px;
                margin-right: 10px;
                background-repeat: no-repeat;
              }
              .clip-icon {
                background-image: url($assets_icons + "/file.svg");
              }
              .voice-icon {
                background-image: url($assets_icons + "/voice.svg");
              }
              .img-icon {
                background-image: url($assets_icons + "/image.svg");
              }
              .video-icon {
                background-image: url($assets_icons + "/video.svg");
              }
              .not-found-icon {
                background-image: url($assets_icons + "/not-found.svg");
              }
            }
            .cut-image {
              overflow: hidden;
              max-width: 50px;
              min-width: 22px;
              height: 48px;
              margin-left: auto;
              .image-reply-box {
                height: 48px;
                max-width: initial;
              }
            }

            &.user-color {
              background: #dfe0e0;
            }
            &.agent-color {
              background: #d1dce0;
            }
          }

          .reply-message {
            padding: 8px 28px;
            display: flex;
            align-items: center;
            background: #fafafa;

            .cancel {
              cursor: pointer;
              .icon {
                width: 12px;
                height: 12px;
                background: #9dacb9;
              }
            }

            .box-style {
              margin-left: 15px;
              border-radius: 2px;
              width: -webkit-fill-available;

              &.user {
                background: #338869;
              }
              &.agent,
              &.ai {
                background: #24446a;
              }

              .content {
                margin: 5px 12px;
                display: flex;
                align-items: center;

                .text {
                  white-space: pre-wrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-wrap: break-word;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  flex: 1;
                  max-width: 400px;
                  color: #ffffff;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 18px;
                }
                .icon {
                  width: 20px;
                  height: 20px;
                  margin-right: 12px;
                  background: #ffffff;
                }

                .cut-image {
                  overflow: hidden;
                  max-width: 100px;
                  height: 47px;
                  border-radius: 4px;
                  margin-left: auto;
                  .image-reply-box {
                    height: 47px;
                    max-width: initial;
                  }
                }
              }
            }
          }
        }
      }
    }

    .user-panel-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .user-logo {
        width: 104px;
        height: 104px;
        min-height: 104px;
        border-radius: 5px;
        margin-top: 40px;
      }

      .user-name-container {
        display: flex;
        align-items: center;

        .reasign-pencil {
          width: 14px;
          height: 14px;
          background-image: url($assets_path + "/pencil.svg");
          background-size: contain;
          margin-bottom: 20px;
          margin-top: 7px;
          margin-left: 5px;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .user-name {
        margin-top: 7px;
        color: $black-color;
        font-size: 15px;
        margin-bottom: 20px;
      }

      .information-panel {
        border-radius: 4px;
        padding: 10px 24px;
        background-color: $light-gray-color;
        width: calc(100% - 48px);
        margin: 0px 24px;

        .user-id {
          margin-bottom: 12px;
          font-size: 15px;
          color: $green-color;
          font-weight: 600;
        }

        .panel-item {
          margin-bottom: 16px;
          font-size: 15px;
          color: $black-color;

          span {
            font-weight: 600;
          }
        }

        &.note-section {
          margin-top: 16px;
          margin-bottom: 16px;
          padding: 16px 24px;

          .note-header {
            display: flex;
            margin-bottom: 24px;

            h3 {
              font-weight: 800;
              color: $black-color;
              margin-right: auto;
            }
          }

          .ico-add-note {
            width: 20px;
            height: 20px;
            background-image: url($assets_path + "/note-add.svg");
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            border-radius: 2px;

            &:hover {
              background-color: #ececec;
            }

            &.no-hover {
              background-color: transparent;
              width: 12px;
              height: 12px;
            }
          }

          .no-note-yet {
            display: flex;
            align-items: center;
            padding: 8px 5px;
            margin: -8px -5px 0px -5px;
            border-radius: 2px;
            cursor: pointer;

            .ico-add-note {
              margin-right: 12px;
            }

            p {
              color: rgba(0, 0, 0, 0.45);
            }

            &:hover {
              background-color: #ececec;
            }
          }

          .notes {
            .note {
              padding: 15px;
              border-radius: 2px;
              border: 1px solid $light-gray-border-color;
              margin-bottom: 16px;

              .note-header-internal {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                h3 {
                  font-weight: 800;
                  color: $black-color;
                  margin-right: auto;
                }

                .ico-edit-note {
                  width: 16px;
                  height: 16px;
                  background-image: url($assets_path + "/three-dots.svg");
                  background-size: contain;
                  cursor: pointer;
                }
              }

              textarea {
                font-size: 15px;
                color: $black-color;
                background-color: transparent;
                border: none;
                width: 100%;
                margin-bottom: 16px;
                resize: none;

                &::placeholder {
                  color: rgba(0, 0, 0, 0.45);
                }

                &:focus {
                  outline: none;
                }
              }

              .note-footer {
                display: flex;
                align-items: center;

                .note-date {
                  font-size: 12px;
                  color: #c4c4c4;
                  margin-right: auto;
                  text-transform: capitalize;
                }

                .btn-cancel p {
                  font-size: 12px;
                }
              }

              .dropdown-menu {
                left: -160px;
              }
            }
          }
        }
      }
    }

    .pop-up-chat {
      @media (max-width: $tablet - 1) {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: white;
      }
    }
    .no-pop-up-chat {
      @media (max-width: $tablet - 1) {
        display: none !important;
      }
    }
  }
}

.preview {
  overflow-wrap: anywhere;
  .document {
    display: flex;
    align-items: center;

    .clip-icon {
      background-image: url($assets_path + "/clip.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 36px;
      height: 36px;
      background-position: center;
      margin-right: 10px;
    }
    margin-bottom: 8px;
  }

  video {
    max-width: 100%;
  }
  p {
    white-space: pre-line;
  }
  div.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 8px;
    width: 100%;
    text-align: center;
    a,
    p {
      margin-right: 8px;
      background-color: black;
      color: white;
      border: rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      padding: 8px;
    }
    a {
      width: 100%;
    }
    p {
      min-width: 60px;
    }
  }
}

.hubspot-panel-category {
  padding: 12px 28px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.ant-dropdown {
  &.hubspot-options {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        .ant-dropdown-menu-title-content {
          &:hover {
            color: #f3773a;
            .treble-icon {
              filter: brightness(0) saturate(100%) invert(50%) sepia(67%)
                saturate(560%) hue-rotate(334deg) brightness(98%) contrast(94%);
            }
          }
        }
      }
    }
  }
}
