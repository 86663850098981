@import "../../styles/rootStyles.scss";

.transfer-modal {
  .modal-content {
    width: -webkit-fill-available;
    max-width: 618px;
    height: fit-content;
    border: none;
    padding: 0;
    color: #262626;
    border-radius: 2px;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    .modal-header {
      font-weight: 400;
      font-size: 20px;
      .icon {
        background: #262626;
      }
    }
    .modal-body {
      padding: 0;
      .options,
      .filters {
        padding: 0px 36px;
      }
      .options {
        display: flex;
        border-bottom: 1px solid #dfe1e6;
        .option {
          cursor: pointer;
          padding: 0px 18px 14px 18px;
          font-weight: 500;
          font-size: 16px;
          &.is-active {
            border-bottom: 1px solid #000000;
          }
        }
      }
      .filters {
        padding: 23px 37px 0px 37px;
        display: flex;
        flex-wrap: wrap;
        .dropdown,
        .dropdown-trigger,
        .dropdown-menu,
        .button {
          width: -webkit-fill-available;
        }
        .dropdown {
          margin-top: 10px;
          &.is-active {
            .button {
              outline: 1px solid #6464ff;
            }
          }
          .button {
            height: 56px;
            display: flex;
            border: 1px solid #dfe1e6;
            padding: 17px 20px;
            justify-content: unset;
            font-weight: 500;
            font-size: 16px;
            .icon {
              margin-left: auto;
              background: #262626;
            }
          }
          .dropdown-menu,
          .dropdown-content-container,
          .dropdown-content {
            width: inherit;
          }
          .dropdown-menu {
            margin-top: 8px;
            .dropdown-content {
              box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
              border-radius: 2px;
              max-height: 363px;
              position: fixed;
              overflow: auto;
              &::-webkit-scrollbar {
                display: none;
              }
              .dropdown-item,
              .not-found {
                padding: 14px 25px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                .icon {
                  background: #000000;
                  margin-left: auto;
                  width: 15px;
                  height: 15px;
                  -webkit-mask-size: contain;
                }
              }
            }
          }
          .searchbar .icon {
            width: 22px;
            height: 22px;
          }
        }
        .search-dropdown {
          width: 544px;
        }
        &.has-tags .search-dropdown {
          width: 318px;
          margin-right: 12px;
        }
        .tag-dropdown {
          width: 214px;
          .dropdown-content {
            width: 318px;
            transform: translateX(-32%);
          }
        }
      }
    }
    .modal-footer {
      .buttons {
        margin-left: auto;
        .button {
          border: none;
          height: 47px;
          margin-left: 35px;
          &.cancel {
            padding: 0;
            color: #262626;
          }
          &.send {
            background: rgba(26, 26, 26, 0.25);
            padding: 10px 35px;
            color: #ffffff;
            font-weight: 600;
            font-size: 16px;
          }
          &.able {
            background: #1a1a1a;
          }
        }
      }
    }
    @media (max-width: $tablet - 1) {
      .modal-header {
        height: 75px;
        padding: 0px 17px;
        border-bottom: 1px solid #dfe1e6;
        font-weight: 600;
        font-size: 22px;
      }
      .modal-body {
        .options {
          padding: 0;
          justify-content: center;
          margin: 0;
          height: 75px;
          .option {
            display: flex;
            align-items: flex-end;
          }
        }
        .filters {
          margin: 35px 17px 0px 17px;
          padding: 0;
          padding-bottom: 26px;
          border-bottom: 1px dashed #dfe1e6;
          .search-dropdown {
            margin: 0 !important;
          }
          .tag-dropdown {
            .dropdown-content {
              width: -webkit-fill-available;
              transform: unset;
            }
          }
          .dropdown {
            width: -webkit-fill-available !important;
            flex-flow: column;
            margin-top: 12px;
            .dropdown-content {
              position: absolute !important;
              max-height: 310px !important;
            }
          }
        }
      }
      .modal-footer {
        padding: 0px 17px;
        .buttons {
          flex-flow: column;
          width: -webkit-fill-available;
          .button {
            height: 53px;
            margin: 0;
            margin-top: 41px;
            &.send {
              order: 0;
              width: -webkit-fill-available;
              font-weight: 600;
              font-size: 18px;
            }
            &.cancel {
              order: 1;
            }
          }
        }
      }
    }
  }
}
