@import "../../../../../styles/rootStyles.scss";

.hubspot-close-conversation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $family-primary;
  gap: 24px;

  button.ant-btn {
    width: 266px;
    height: 47px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    box-shadow: none;

    &.close {
      background: #6464ff;
      color: #ffffff;
      &[disabled] {
        background: #c3c3ff;
      }
    }
    &.cancel {
      background-color: transparent;
      color: #262626;
      border: none;
    }
  }

  .controls {
    @media (max-width: $tablet - 1) {
      padding: 0;
      width: 100%;
    }

    max-height: 220px;
    overflow-y: auto;
    padding-right: 8px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9dacb9;
      border-radius: 800px;
      cursor: pointer;
      user-select: none;
    }

    .flex-row {
      @media (max-width: $tablet - 1) {
        flex-direction: column;
      }

      display: flex;
      gap: 10px;
      .ant-select {
        height: 60px;
        font-weight: 500;
        font-size: 18px;
        .ant-select-arrow {
          right: 24px;
          .icon {
            background-color: #1a1a1a;
          }
        }
        .ant-select-selector {
          padding: 0px 24px;
          height: 100%;
          &:hover {
            border-color: #9b9bff;
          }
          .ant-select-selection-search {
            display: none;
          }
          .ant-select-selection-item {
            display: flex;
            align-items: center;
            .treble-icon {
              display: none;
            }
          }
        }
        &.stage-status {
          @media (max-width: $tablet - 1) {
            width: 100%;
          }
          width: 148px;
          .ant-select-selector {
            background-color: #232b30;
            color: #ffffff;
          }
          .ant-select-arrow {
            .icon {
              background-color: #ffffff;
            }
          }
        }
        &.pipeline {
          display: flex;
          flex-grow: 1;
        }
      }
    }

    .flex-col {
      @media (max-width: $tablet - 1) {
        width: 100%;
      }

      width: 421px;
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .ant-radio-wrapper {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          margin-right: 0;
          height: 60px;
          align-items: center;
          border: 1px solid #dfe1e6;
          border-radius: 2px;
          padding: 16px 22px;
          font-size: 18px;
          &:hover {
            border-color: #9b9bff;
            .ant-radio {
              border-color: #9b9bff;
            }
          }
          span {
            flex-grow: 1;
            display: flex;
          }
          .ant-radio {
            height: 24px;
            width: 24px;
            flex-grow: 0;
            margin-bottom: 4px;
            &:hover {
              .ant-radio-inner {
                border-color: #9b9bff;
              }
            }
            input,
            .ant-radio-inner {
              height: 100%;
              width: 100%;
              &::after {
                height: 24px;
                width: 24px;
                top: calc(50% - 4px);
                left: calc(50% - 4px);
              }
            }
          }
          .ant-radio-checked {
            &::after {
              border-color: #9b9bff;
            }
            .ant-radio-inner {
              border-color: #9b9bff;
              &::after {
                background-color: #9b9bff;
              }
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.ant-select-dropdown {
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item {
          display: flex;
          height: 44px;
          align-items: center;
          &:hover {
            background-color: #fcfcfc;
          }
          .ant-select-item-option-content {
            display: flex;
            .treble-icon {
              margin-left: auto;
              margin-right: 8px;
            }
          }
        }
        .ant-select-item-option-selected {
          background-color: #ffffff;
          font-weight: 400;
        }
      }
    }
  }
}
