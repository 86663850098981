$iconsPath: "../../../../../assets/icons";

.ant-dropdown {
  width: 330px !important;
  max-height: 400px !important;

  box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
  border-radius: 4px;
  background-color: #ffffff;
  z-index: 10;

  .treble-multiple-select-searchbar {
    padding: 16px;
    .ant-input-affix-wrapper-focused {
      &:hover,
      &:focus,
      &:active,
      &:focus-within {
        border-color: #6464ff !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }

    .ant-input-affix-wrapper {
      height: 42px;
      &:hover,
      &:focus,
      &:active {
        border-color: #6464ff !important;
        box-shadow: none !important;
        outline: none !important;
      }
      .ant-input-prefix {
        margin-right: 14px;
      }
      .ant-input-suffix {
        .ant-input-clear-icon {
          .anticon-close-circle {
            svg {
              fill: #000000;
            }
          }
        }
      }
    }
  }

  .treble-multiple-select-dropdown-container {
    ul {
      box-shadow: none !important;
      margin-left: 0;
      height: 116px;
      overflow-y: auto;
      margin-bottom: 8px;
    }
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        padding: 5px 23px;
        .ant-dropdown-menu-title-content {
          .treble-multi-select-option {
            display: flex;
            img {
              margin-top: 2px;
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  .ant-menu {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 226px;

    .ant-menu-item-active,
    .ant-menu-item-selected {
      color: #262626;
      background-color: transparent;
    }

    .ant-menu-item {
      display: block;
      margin: 0;
      padding: 0;
      min-height: 50px;

      .ant-menu-title-content {
        min-height: 50px;
        height: 100%;
        display: block;

        .treble-multi-select-option {
          padding: 10px 22px;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 50px;
          &:hover {
            background-color: #f5f5f5;
            color: #262626;
          }
          .label-text {
            flex-grow: 1;
            margin: 0;
            max-width: 259px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #000000;
      border-radius: 800px;
      height: 46px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0px 10px 0px 0px;
    }
  }

  &.has-search {
    .ant-input-prefix {
      display: none;
    }
  }

  .select-all {
    cursor: pointer;
    height: 40px;
    padding: 4px 30px 10px 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .label-text {
      flex-grow: 1;
      margin: 0;
    }
    border-bottom: 1px solid #dfe1e6;
  }

  .treble-empty {
    height: 50px;
    padding: 10px 22px;
    color: #262626;
    display: flex;
    align-items: center;
  }

  .clear-all {
    height: 54px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #dfe1e6;
    .clear {
      color: #6464ff;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
      &:hover {
        color: rgba(100, 100, 255, 0.5);
      }
    }
  }
}

.ant-dropdown-trigger:focus,
.ant-dropdown-trigger:active,
.ant-dropdown-trigger:hover,
.ant-dropdown-trigger .ant-btn:hover,
.ant-dropdown-trigger .ant-btn:focus,
.ant-dropdown-trigger .ant-btn:active {
  border-color: #6464ff !important;
  box-shadow: none !important;
  color: #767676 !important;
}

.ant-dropdown-trigger {
  height: 50px;
  &.has-value {
    .ant-btn {
      border-color: #6464ff;
      span {
        color: #6464ff;
      }
      .icon {
        background-color: #6464ff;
      }
    }
  }
  .ant-btn {
    height: 100%;
    width: 188px;
    display: flex;
    align-items: center;
    span {
      color: #c5c5c5;
      flex-grow: 1;
      text-align: left;
    }
    .icon {
      background-color: #767676;
      position: relative;
      right: -5px;
    }
  }
}

.ant-select {
  .ant-select-arrow {
    .icon {
      background-color: #767676;
    }
  }
  &.has-value {
    .ant-select-selector {
      border-color: #6464ff;
      color: #6464ff;
    }
    .ant-select-arrow {
      .icon {
        background-color: #6464ff;
      }
    }
  }
}
