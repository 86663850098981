@import "../../../styles/rootStyles.scss";

$assets_images: "../../../assets/images";
$assets_icons: "../../../assets/icons";

.bubble {
  &.highlight {
    animation: highlight 3s;
  }
  &.message {
    background-color: unset;
    padding-top: 18px;
    border-radius: 0;
    display: flex;
    &:not(:last-child) {
      margin: 0;
    }
    .bubble-body {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #ffffff;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      p {
        word-break: break-word;
        white-space: pre-line;
      }
      .reply-info {
        display: flex;
        margin-bottom: 6px;
        cursor: pointer;
        &:hover {
          .flag {
            width: 20px;
            .icon {
              display: block;
            }
          }
        }
        .flag {
          width: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            display: none;
          }
        }
        .reply-content {
          width: -webkit-fill-available;
          background: #ffffff;
          display: flex;
          align-items: center;
          height: 55px;
          padding: 5px 0px 5px 12px;
          .icon,
          .text {
            margin-right: 12px;
          }
          .icon {
            background: #9dacb9;
          }
          .text {
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            flex: 1;
            max-width: 300px;
            color: #000000;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
          }
          .cut-image {
            margin-right: 7px;
            overflow: hidden;
            max-width: 100px;
            height: 47px;
            border-radius: 4px;
            margin-left: auto;
            .image-reply-box {
              height: 47px;
            }
            img,
            video {
              border-radius: 0;
            }
          }
        }
      }
      .content {
        max-width: 516px;
        padding: 10px 19px;
        margin-bottom: 0;
        &.has-reply {
          min-width: 100px;
        }
        .upload-file {
          .ico-spinner {
            width: 25px;
            height: 25px;
            background-image: url($assets_images + "/oval.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .audio {
          display: flex;
          align-items: center;
          gap: 12px;
          .icon {
            background-color: #ffffff;
            height: 15px;
            width: 13px;
            cursor: pointer;
          }
          .rhap_container {
            background-color: transparent;
            box-shadow: none;
            height: -webkit-fill-available;
            padding: 0;
            .rhap_main {
              .rhap_progress-section {
                .rhap_time {
                  display: none;
                }
                .rhap_progress-container {
                  width: 200px;
                  .rhap_progress-bar {
                    .rhap_progress-indicator {
                      background-color: white;
                    }
                    .rhap_progress-filled {
                      background-color: black;
                    }
                  }
                }
              }
              .rhap_additional-controls, .rhap_volume-controls {
                display: none;
              }
              .rhap_main-controls {
                .rhap_main-controls-button {
                  color: black;
                  svg {
                    background-color: white;
                    border-radius: 100%;
                  }
                }
              }
            }
          }
        }
        .sticker {
          max-height: 200px;
          max-width: 200px;
        }
        .hsm {
          padding-top: 0px;
          .header,
          .body,
          .footer {
            a,
            p {
              padding-top: 12px;
            }
          }
          .header p {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
          }
          .body p {
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
          }
          .footer {
            padding: 0;
            background: unset;
            justify-content: unset;
            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
            }
          }
          .buttons {
            padding-top: 12px;
          }
        }
        .document {
          display: flex;
          align-items: center;
          .text {
            max-width: 280px;
            margin-left: 10px;
            margin-right: 29px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .icon {
            background: #ffffff;
            &--file-message {
              width: 16px;
              height: 20px;
            }
            &--download-file {
              cursor: pointer;
              width: 33px;
              height: 33px;
            }
          }
        }
        .contacts {
          .contact {
            .icon {
              background: #ffffff;
              &--contact-message {
                margin-right: 10px;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        .buttons {
          margin-bottom: 10px;
          a,
          p {
            width: -webkit-fill-available;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border-radius: 6px;
            color: #24446a;
            font-weight: 600;
            font-size: 15px;
            line-height: 28px;
          }
        }
      }
      .media-content {
        max-width: 329px;
        img {
          cursor: zoom-in;
        }
        .has-caption {
          padding: 19px 19px 12px 19px;
          img,
          video {
            width: 100%;
            height: auto;
            max-height: 500px;
            border-radius: 4px;
            margin-bottom: 6px;
          }
        }
        .location {
          .maps-container {
            width: 329px;
            height: 270px;
            border-radius: 0px 10px 10px 10px;
            -webkit-transform: translate3d(0px, 0px, 0px);
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            .icon.icon--map-pin-blue {
              background-image: url($assets_icons + "/blue-pointer.svg");
              width: 30px;
              height: 30px;
            }
            .gm-style {
              button {
                display: none;
              }
              .gmnoprint {
                display: none !important;
              }
              .gm-style-cc {
                a {
                  display: none;
                }
              }
            }
            .address-container {
              width: 296px;
              height: 44px;
              cursor: pointer;
              position: relative;
              right: -18px;
              top: -61px;
              background: #18855d;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                color: #ffffff;
                font-weight: 500;
                font-size: 15px;
                margin-right: 9px;
              }
              .icon {
                width: 11px;
                height: 11px;
                background: #ffffff;
              }
            }
          }
        }
      }
      .reply {
        cursor: pointer;
        font-size: 14px;
        color: rgba(85, 120, 155, 0.6);
        &:hover {
          color: #5087ff;
        }
      }
    }
    .date-and-status {
      display: flex;
      font-weight: 600;
      font-size: 13px;
      margin-top: 8px;
      .resend-link {
        cursor: pointer;
        color: #e54d36;
        font-weight: 600;
        font-size: 15px;
        text-decoration: underline #e54d36;
      }
    }
    .user {
      margin-right: auto;
      margin-left: 50px;
      border-radius: 0px 10px 10px 10px;
      .content,
      .has-caption,
      img,
      video {
        border-radius: 0px 10px 10px 10px;
      }
      .content,
      .has-caption {
        background: #338869;
        .audio {
          .flex {
            background: #338869 !important;
          }
        }
      }
      .reply-info {
        .flag {
          &--user {
            background: #43a481;
          }
          &--agent {
            background: #24446a;
          }
        }
      }
      .reply {
        margin-left: 13px;
        margin-right: 10px;
      }
      .date-and-status {
        color: #338869;
      }
    }
    .agent {
      margin-left: auto;
      margin-right: 50px;
      .content,
      .has-caption,
      img,
      video {
        border-radius: 10px 10px 0px 10px;
      }
      .content,
      .has-caption {
        background: #24446a;
        .audio {
          .flex {
            background: #24446a !important;
          }
        }
      }
      .reply-info {
        .flag {
          &--user {
            background: #338869;
          }
          &--agent {
            background: #55789b;
          }
        }
      }
      .reply {
        order: -1;
        margin-left: 10px;
        margin-right: 13px;
      }
      .date-and-status {
        justify-content: flex-end;
        color: #24446a;
        .icon {
          margin-left: 5.3px;
        }
      }
    }
  }
}

@keyframes highlight {
  from {
    background: #fffae6;
    border: 1px dashed #db980a;
  }
}
