@import "../../../../styles/rootStyles.scss";

.hsm-modal {
  .modal-content {
    width: 612px;
    color: #262626;
    .modal-header {
      font-weight: 600;
      font-size: 22px;
      border-bottom: 1px solid #dfe1e6;
      .icon {
        background: #262626;
      }
    }
    .modal-body {
      padding-bottom: 0;
      h6 {
        font-weight: 500;
        font-size: 16px;
        color: #1a1a1a;
        margin-top: 0;
        margin-bottom: 12px;
      }
      input,
      .channel-selector {
        height: 56px;
        border: 1px solid #dfe1e6;
        border-radius: 4px;
        margin-bottom: 34px;
      }
      .channel {
        font-weight: 600;
        font-size: 16px;
        .dropdown,
        .dropdown-trigger,
        .channel-selector {
          width: -webkit-fill-available;
        }
        .channel-selector {
          h6 {
            margin-bottom: 0;
            margin-left: 15px;
          }
        }
        .dropdown-menu {
          top: 57px;
          width: -webkit-fill-available;
          .dropdown-content {
            box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.1);
            border-radius: 4px;
            .dropdown-item {
              border-bottom: 1px solid #f0f0fe;
              &:last-child {
                border-bottom: none;
              }
              h6 {
                font-weight: 600;
                font-size: 16px;
                color: #5ab978;
              }
              p {
                font-weight: 500;
                font-size: 15px;
                color: #215162;
              }
            }
          }
        }
      }
      .parameters {
        margin-bottom: 0;
      }
      .preview {
        .hsm-preview {
          border: 1px solid #dfe1e6;
          padding: 0px 20px 20px 20px;
          border-radius: 4px;
          p,
          a,
          img {
            padding-top: 18px;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #1a1a1a;
          }
          .buttons {
            display: block;
            margin-top: 12px;
            &:first-child {
              margin-top: 0px;
            }
            a,
            p {
              margin: 0;
              margin-top: 12px;
              padding: 8px;
              border: 1px solid #9dacb9;
              border-radius: 2px;
              background: #ffffff;
              font-weight: 600;
              font-size: 15px;
              color: #3c5a73;
            }
          }
        }
      }
    }
    .modal-footer {
      .buttons {
        margin-left: auto;
        .button {
          height: 47px;
          font-size: 18px;
          margin: 0;
          margin-left: 35px;
          &.cancel {
            border: none;
            padding: 0;
            color: #262626;
            font-weight: 500;
          }
          &.send {
            border: none;
            background: #1a1a1a;
            padding: 9px 37px;
            color: #ffffff;
            font-weight: 600;
          }
        }
      }
    }
    @media (max-width: $tablet - 1) {
      padding: 0;
      .modal-body {
        margin: 29px 17px 0px 17px;
        padding: 0;
        padding-bottom: 26px;
        border-bottom: 1px dashed #dfe1e6;
      }
      .modal-footer {
        height: fit-content;
        justify-content: center;
        .buttons {
          width: -webkit-fill-available;
          display: flex;
          flex-flow: column;
          margin: 0;
          .button {
            margin: 0;
            margin-top: 41px;
            height: 53px;
            &.cancel {
              order: 1;
            }
            &.send {
              order: 0;
              width: -webkit-fill-available;
            }
          }
        }
      }
    }
  }
}
