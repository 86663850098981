.treble.inline-alert {
    background-color: #ffebe6;
    border-color: #eb4128;
    color: #eb4128;
    display: flex;
    flex-direction: row;
    padding: 10px 8px;
    border-radius: 4px;
    width: -webkit-fill-available;
    align-items: center;
    .icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
    }
  }