@import "../../../assets/scss/variables.scss";
$imagesPath: "../../../assets/images";
$iconsPath: "../../../assets/icons";

.agent-stats {
  background-color: #f8f8f8;
  overflow: auto;

  .header {
    height: 81px;
    background: #1a1a1a;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding-left: 40px;
  }

  .top-bar-container {
    padding: 35px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .assignment-method-dropdown {
      &.is-active {
        .dropdown-trigger {
          .dropdown-button {
            border-color: #6464ff;
          }
        }
      }

      .dropdown-trigger {
        .dropdown-button {
          display: flex;
          align-items: center;
          width: max-content;
          padding: 14px 29px;
          gap: 12px;
          height: 51px;
          background: #ffffff;
          border: 1px solid #e8ebf0;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            border-color: #6464ff;
          }

          p {
            font-family: HKGrotesk-Medium;
            font-size: 16px;
            line-height: 21px;
            color: #202020;
          }

          .icon {
            &--double-arrow {
              width: 21px;
              height: 17px;
              background: #000000;
            }
          }
        }
      }

      .dropdown-menu {
        margin-top: 8px;
        width: -webkit-fill-available;

        .dropdown-content-container {
          .dropdown-content {
            padding: 26px 26px 23px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .dropdown-item {
              text-transform: initial;
              padding: 0;

              &:hover {
                background-color: #ffffff;
              }

              &:has(.section) {
                pointer-events: none;
              }

              & > div {
                border-radius: 2px;
                width: -webkit-fill-available;
              }

              .section {
                &--title {
                  padding: 0 0 20px;
                  margin-bottom: 8px;
                  border-bottom: 1px solid #d6d6d6;

                  .title {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 23px;
                    color: #202020;
                    margin-bottom: 8px;
                  }

                  .description {
                    font-size: 14px;
                    line-height: 20px;
                    color: #767676;
                  }
                }

                &--hubspot {
                  margin: 8px 0 3px;
                  display: flex;
                  align-items: center;
                  gap: 12px;
                  padding: 12.5px 16px;
                  background: rgba(243, 119, 58, 0.03);

                  .icon {
                    &--hubspot-logo {
                      width: 15px;
                      height: 16px;
                      background: #ee6725;
                    }
                  }

                  .title {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                    color: #ee6725;
                  }
                }
              }

              .assignment-method-option {
                padding: 16px 19px;
                border: 1px solid #e8ebf0;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                &:hover {
                  border-color: #0a0a41;
                }

                p {
                  font-size: 15px;
                  line-height: 20px;
                  color: #0a0b2b;
                }

                & > div {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: -webkit-fill-available;

                  &.waiting-time-limit-edit {
                    justify-content: flex-start;
                    gap: 8px;

                    p {
                      color: #8787fd;
                    }
                  }
                }

                .icon {
                  &--check-circle {
                    width: 18px;
                    height: 18px;

                    &:not(.selected) {
                      background: #ffffff;
                      border: 1px solid #d6d6d6;
                      border-radius: 10px;
                    }
                  }

                  &--edit-label {
                    width: 14px;
                    height: 14px;
                    background: #b8b8b8;
                    cursor: pointer;

                    &:hover {
                      background: #000000;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .right-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      .add-button-container {
        .add-tag,
        .add-agent {
          display: flex;
          align-items: center;
          gap: 12px;
          width: max-content;
          padding: 12px 15px;
          border-radius: 4px;
          cursor: pointer;

          & > p {
            font-family: "HKGrotesk-Medium";
            font-size: 16px;
            line-height: 21px;
          }
        }

        .add-tag {
          background: #ffffff;
          border: 1px solid #e8ebf0;

          &:hover {
            border-color: #1a1a1a;
          }

          .icon--group {
            width: 22px;
            height: 14px;
            background: #1a1a1a;
          }

          & > p {
            color: #1a1a1a;
          }
        }

        .add-agent {
          background: #0f0f0f;
          border: 1px solid #0f0f0f;

          &:hover {
            background: #575757;
            border-color: #575757;
          }

          .icon--salesperson {
            width: 14px;
            height: 14px;
            background: #ffffff;
          }

          & > p {
            color: #ffffff;
          }
        }

        .tag-form {
          position: absolute;
          z-index: 50;
          background: #ffffff;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          padding: 16px;
          margin-top: 5px;
          display: none;

          &.is-active {
            display: flex;
          }

          input {
            outline: none;
            border-radius: 2px;
            height: 40px;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            margin-right: 16px;
            padding: 16px;
          }

          input:focus {
            border: 1px solid #000000;
          }

          .add-button {
            background: #9b9bff;
            border-radius: 3px;
            height: 40px;
            padding: 8px;
            color: #ffffff;
            cursor: pointer;
            border: none;

            &:disabled {
              background-color: #d6d6d6;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .waiting-owner-time-modal {
    .modal-content {
      margin-top: 250px;
      width: fit-content;

      .close {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        height: 90px;
        width: -webkit-fill-available;
        padding: 0 36px;

        .icon-close {
          position: static;
          width: 14.5px;
          height: 14.5px;
          margin-right: 6px;
        }
      }

      .modal-header {
        height: 90px;
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        color: #262626;
      }

      .modal-body {
        padding: 2px 36px 21px;
        border-bottom: 1px solid #dfe1e6;

        .body-content {
          .description {
            font-size: 16px;
            line-height: 23px;
            color: #0f0f0f;
            padding: 21px 16px;
            background: #f6f6ff;
          }

          .time-settings {
            margin-top: 20px;
            display: flex;
            align-items: center;
            gap: 12px;
            font-size: 16px;
            line-height: 21px;
            color: #202020;

            .button-style {
              padding: 15px 19px;
              border: 1px solid #d6d6d6;
              border-radius: 4px;
              color: #b2b2b2;
              cursor: pointer;

              &.selected {
                color: #202020;
              }

              &.selected,
              &:hover,
              &:focus {
                border-color: #0f0f0f;
              }
            }

            input {
              width: 60px;
              font-family: "HKGrotesk";
              color: #202020 !important;
              outline: none;

              &::placeholder {
                color: #b8b8b8;
                opacity: 1;
              }
            }
          }
        }
      }

      .modal-footer {
        height: fit-content;
        padding: 18px 38px 22px;

        .footer-buttons {
          width: -webkit-fill-available;
          display: flex;
          justify-content: flex-end;
          gap: 13px;

          button {
            font-family: "HKGrotesk";
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            padding: 12px 23px;
            border: none;
            color: #262626;
            background: transparent;
            cursor: pointer;
            outline: none;

            &.primary {
              color: #ffffff;
              border-radius: 4px;
              background: #6464ff;

              &:hover {
                background: #9393ff;
              }

              &:disabled {
                background: #d6d6d6;
              }
            }
          }
        }
      }
    }
  }

  .agent-info-modal {
    .modal-content {
      display: table;
      width: 552px;
      height: 626px;
      overflow: visible;
      .close {
        .icon-close {
          height: 16px;
          width: 16px;
          top: 42px;
          right: 46px;
        }
      }
      .modal-header {
        color: #121213;
        font-family: "HKGrotesk-Medium";
        font-size: 20px;
        line-height: 34px;
        border-bottom: 1px solid #dfe1e6;
        padding: 28px 45px;
      }
      .modal-body {
        padding: 28px 45px 0px 45px;
        display: flex;
        flex-direction: column;
        gap: 22px;
        .input-container {
          display: flex;
          gap: 18px;
          .title-and-dropdown {
            min-width: 222px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            p {
              color: #202020;
              font-size: 16px;
              font-family: "HKGrotesk";
              line-height: 20px;
            }

            .select-dropdown-main,
            .multiple-select-dropdown {
              width: 100%;
              &.is-active {
                outline: 1px solid #6464ff;
              }
              p {
                color: #1a1a1a;
                font-size: 15px;
                line-height: 21px;
                text-transform: none;
              }
              .dropdown-trigger {
                width: 100%;
                .button {
                  height: 55px;
                  width: 100%;
                  border: 1px solid #e6e6e6;
                  border-radius: 2px;
                  box-shadow: none;
                  p {
                    text-overflow: ellipsis;
                  }
                  .icon {
                    width: 16px;
                    height: 16px;
                    margin: 0;
                  }
                }
              }
              .dropdown-menu {
                padding: 0;
                width: 100%;
                top: 120%;
                .dropdown-content {
                  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                  border-radius: 4px;
                  max-height: 150px;
                  overflow: auto;
                  padding: 0;
                  margin-bottom: 24px;
                  .dropdown-item {
                    display: flex;
                    align-items: center;
                    padding: 10px 16px;
                    &:active {
                      background-color: initial !important;
                    }
                  }
                }
              }
            }
          }
          .add-agent-dropdown {
            width: -webkit-fill-available;
            .trigger-component {
              height: 40px;
              padding-left: 16px;
              padding-right: 16px;
              border: 1px solid #e6e6e6;
              border-radius: 2px;
              padding-top: 8px;
              cursor: pointer;
              .text-component {
                display: flex;
                .icon {
                  width: 16px;
                  height: 16px;
                  margin-left: auto;
                  margin-top: 3px;
                  &--down {
                    background-image: url(#{$iconsPath}/chevron-down.svg);
                  }
                }
              }
            }
            .agent-dropdown-menu {
              background: #ffffff;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
              border-radius: 4px;
              max-height: 100px;
              overflow: auto;
              display: none;
              .complete-container {
                cursor: pointer;
                display: flex;
                padding: 10px 16px;
                &.is-selected {
                  background: #f9f9f9;
                }
              }
              &.is-active {
                display: block;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      display: flex;
      justify-content: end;
      .agent-info-modal-buttons {
        display: flex;
        gap: 2px;
        button {
          padding: 9px 33px;
          cursor: pointer;
          border: none;
          font-size: 16px;
          font-family: "HKGrotesk-Medium";
          color: #262626;
          border-radius: 4px;
          &.cancel {
            background-color: transparent;
          }
          &.send {
            background-color: #d6d6d6;
            color: #ffffff;
            &:not(:disabled) {
              background-color: #0f0f0f;
              &:hover {
                background-color: #575757;
              }
            }
            &:disabled {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .agent-delete-modal {
    .modal-content {
      width: 546px;
      .close {
        .icon-close {
          height: 16px;
          width: 16px;
          top: 42px;
          right: 46px;
        }
      }
      .modal-header {
        height: 60px;
      }
      .modal-body {
        padding: 28px 50px 0px 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        p {
          margin: 0;
          text-align: center;
          font-family: "HKGrotesk";
          font-size: 16px;
          line-height: 23px;
        }
        p.title {
          text-align: center;
          font-family: "HKGrotesk-Medium";
          font-size: 18px;
          span.agent-name {
            color: rgba(255, 15, 107, 1);
          }
        }
      }
    }
    .modal-footer {
      display: flex;
      justify-content: end;
      .agent-delete-modal-buttons {
        display: flex;
        gap: 2px;
        button {
          padding: 9px 33px;
          cursor: pointer;
          border: none;
          font-size: 16px;
          font-family: "HKGrotesk-Medium";
          color: #262626;
          border-radius: 4px;
          &.cancel {
            background-color: transparent;
          }
          &.send {
            background-color: #0f0f0f;
            color: #ffffff;
            &:hover {
              background-color: #575757;
            }
          }
        }
      }
    }
  }

  .general-stats-container {
    margin-top: 3px;
    padding: 0 40px;
  }

  .columns {
    margin: 0;
    .column {
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
      .stat-box {
        padding: 20px 30px 22px;
        border-radius: 4px;
        background: #ffffff;
        p {
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          color: #a7a7a7;
          margin-bottom: 18px;
        }
        h1 {
          font-weight: 400;
          font-size: 23px;
          line-height: 30px;
          color: #0f0f0f;
        }
      }
    }
  }

  .container.is-fluid {
    margin-left: 0px;
    margin-right: 0px;
  }

  .dropdown-item {
    display: flex;
    align-items: center;

    &:active {
      background-color: initial !important;
    }
  }

  .table-container {
    margin: 10px 40px 40px;

    .table-header {
      background-color: #ffffff;
      padding: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      .searchbar {
        padding-left: 17px;
        padding-right: 17px;
        width: 317px;
        background-color: #ffffff;
        border: 1px solid #dfe1e6;
        box-sizing: border-box;
        border-radius: 2px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          outline: 1px solid #6464ff;
        }
        input {
          width: 100%;
          color: #202020;
          font-family: "HKGrotesk-Medium";
          font-size: 15px;
          line-height: 20px;
          border: none;
          background-color: #ffffff;
          box-shadow: none;
          cursor: pointer;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-family: "HKGrotesk-Medium";
            font-size: 15px;
            line-height: 20px;
            color: #767676;
          }
        }
        .icon {
          flex: none;
          &--search {
            width: 20px;
            height: 20px;
            margin-left: 0;
            margin-right: 11px;
            background: #000000;
          }
          &--search-cancel-grey {
            width: 16px;
            height: 16px;
          }
        }
      }

      h1 {
        min-width: 174px;
        margin-right: 7px;
        font-size: 22px;
        line-height: 29px;
        color: #202020;
        font-weight: 400;
      }

      .multiple-select-dropdown {
        margin: 0;
        .dropdown-trigger {
          button {
            height: fit-content;
            width: 176px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 14px;
            padding: 11px 15px;
            border: 1px solid #dfe1e6;
            border-radius: 2px;

            &:hover {
              border-color: #6464ff;
            }

            p {
              max-width: 160px;
              overflow: hidden;
              font-family: "HKGrotesk-Medium";
              font-size: 15px;
              line-height: 20px;
              color: #767676;
            }

            .icon--dropdown-row {
              background: #767676;
              margin: 0;
              width: 10px;
              height: 5px;
            }
          }
        }
        .dropdown-menu {
          min-width: unset;
          width: -webkit-fill-available;
        }
      }

      a {
        display: flex;
        margin-left: auto;

        button.download-btn {
          background-color: #ffffff;
          border: 1px solid #dfe1e6;
          border-radius: 4px;
          padding: 13px 16px;
          display: flex;
          align-items: center;
          gap: 12px;
          color: #202020;
          font-family: "HKGrotesk-Medium";
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          .icon {
            width: 14px;
            height: 20px;
            background-color: #6464ff;
          }
          &:hover {
            border-color: #6464ff;
          }
        }

        .icon--download-data {
          width: 18px;
          height: 22px;
          background: #d6d6d6;

          &:hover {
            background: #000000;
          }
        }
      }
    }

    .ant-table-wrapper {
      .ant-table {
        .ant-table-container {
          .ant-table-content {
            min-height: 304px;

            & > table {
              .ant-table-thead {
                tr {
                  .ant-table-cell {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 21px;
                    color: #1a1a1a;
                    background: #ffffff;
                    border-bottom: 2px solid #f5f5f5;

                    &:first-child {
                      padding-left: 30px;
                    }
                    &:last-child {
                      padding-right: 30px;
                    }
                    &::before {
                      display: none;
                    }

                    .ant-table-column-sorters {
                      .ant-table-column-sorter {
                        -webkit-mask: url(#{$iconsPath}/sort.svg) no-repeat
                          center;
                        mask: url(#{$iconsPath}/sort.svg) no-repeat center;
                        -webkit-mask-size: contain;
                        width: 14px;
                        height: 18px;
                        background: #b8b8b8;
                        margin-left: 11px;

                        .ant-table-column-sorter-inner {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }

              .ant-table-tbody {
                .ant-table-row {
                  .ant-table-cell {
                    font-size: 15px;
                    line-height: 20px;
                    color: #333335;
                    border-bottom: 1px solid #f5f5f5;

                    &:first-child {
                      padding-left: 30px;
                    }

                    &:last-child {
                      padding-right: 30px;
                    }

                    &.agent-data {
                      p:first-child {
                        margin-bottom: 10px;
                      }
                    }

                    &.agent-status {
                      .circle {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        margin-right: 10px;

                        &.green {
                          background: #3fa65f;
                        }
                        &.red {
                          background: #fc6550;
                        }
                      }

                      p {
                        display: inline-block;
                      }
                    }

                    &.centered-text {
                      text-align: center;
                    }
                  }
                }

                .empty-table {
                  height: 342px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  gap: 30px;

                  .img-no-results {
                    width: 162px;
                    height: 162px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url(#{$imagesPath}/noElements.svg);
                  }

                  p {
                    font-size: 22px;
                    line-height: 29px;
                    color: #202020;
                  }
                }
              }
            }
          }
        }
      }

      .ant-table-pagination {
        align-items: center;

        .ant-pagination-item {
          border: none;
          background: none;
          a {
            font-family: "HKGrotesk-Medium";
            font-size: 17px;
            color: #767676;
          }

          &:hover {
            a {
              color: #000000;
            }
          }

          &.ant-pagination-item-active {
            a {
              color: #000000;
              border-bottom: 1px solid #000000;
            }
          }
        }

        .ant-pagination-prev,
        .ant-pagination-next {
          .ant-pagination-item-link {
            border: none;
            background: none;

            .anticon {
              background: #333335;
              width: 8px;
              height: 12px;

              svg {
                display: none;
              }
            }

            .anticon-left {
              -webkit-mask: url(#{$iconsPath}/Prev.svg) no-repeat center;
              mask: url(#{$iconsPath}/Prev.svg) no-repeat center;
              -webkit-mask-size: contain;
            }

            .anticon-right {
              -webkit-mask: url(#{$iconsPath}/Next.svg) no-repeat center;
              mask: url(#{$iconsPath}/Next.svg) no-repeat center;
              -webkit-mask-size: contain;
            }
          }
        }

        .ant-pagination-jump-prev,
        .ant-pagination-jump-next {
          &:hover {
            .ant-pagination-item-ellipsis {
              opacity: 1;
            }
          }

          .ant-pagination-item-link {
            .ant-pagination-item-container {
              .anticon-double-left,
              .anticon-double-right {
                display: none;
              }
            }
          }
        }

        .ant-pagination-options {
          .ant-pagination-options-size-changer {
            border: none !important;

            .ant-select-selector {
              border: none !important;
              background: transparent;

              .ant-select-selection-item {
                font-family: "HKGrotesk-Medium";
                font-size: 14px;
                color: #767676;
              }
            }

            .ant-select-arrow {
              .anticon-down {
                -webkit-mask: url(#{$iconsPath}/dropdown-row.svg) no-repeat
                  center;
                mask: url(#{$iconsPath}/dropdown-row.svg) no-repeat center;
                -webkit-mask-size: contain;
                background: #333335;
                width: 10px;
                height: 5px;

                svg {
                  display: none;
                }
              }
            }

            .ant-select-dropdown {
              width: 115px !important;
            }
          }
        }
      }
    }
  }

  .chat-main-window {
    &.no-chat-selected {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h1.no-chat-selected {
        font-size: 18px;
        color: $black-color;
      }
    }

    .message-row {
      display: flex;
      flex-direction: column;
      padding: 10px 0px;

      &.is-external {
        .up-row {
          display: flex;
          margin-bottom: 8px;
          align-items: flex-end;

          .user-icon {
            min-width: 48px;
            height: 48px;
            border-radius: 4px;
          }

          .text-bubble {
            padding: 14px 18px;
            margin-left: 16px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            background-color: #f8f8f8;
            font-size: 15px;

            tt {
              font-family: monospace;
            }
          }
        }

        .down-row {
          p {
            margin-left: 64px;
            font-size: 12px;
          }
        }
      }

      &.is-company {
        align-items: flex-end;

        .up-row {
          display: flex;
          margin-bottom: 8px;
          align-items: flex-end;

          .user-icon {
            min-width: 48px;
            height: 48px;
            border-radius: 4px;
            background-size: contain;
            background-position: center center;
          }

          .text-bubble {
            padding: 14px 18px;
            margin-right: 16px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: black;
            font-size: 15px;
            color: white;

            tt {
              font-family: monospace;
            }
          }
        }

        .down-row {
          p {
            margin-right: 64px;
            text-align: right;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      background: #171717;
      box-shadow: 0px 7px 28px rgba(15, 29, 44, 0.08);
      border-radius: 2px;
    }
  }

  &.assignment-method-option-tooltip {
    padding-left: 34px;

    .ant-tooltip-content {
      width: 470px;

      .ant-tooltip-inner {
        display: flex;
        flex-direction: column;
        padding: 26px;
        gap: 8px;

        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: #ffffff;
          margin: 0;
        }

        .description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  &.edit-tooltip {
    padding-bottom: 5px;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        padding: 5px 11px;

        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

.dropdown-search {
  display: flex;
  height: 33px;
  border: 1px solid $light-gray-border-color;
  border-radius: 4px;

  .dropdown {
    .dropdown-trigger {
      button {
        // background-color: red;
        background-color: #fafafa;
        padding: 0px 16px;
        height: 31px;
        border: none;
        border-radius: 0px;
        font-size: 15px;
      }
    }
  }

  input {
    padding: 5px 16px;
    font-size: 15px;
    height: 31px;
    border: none;
  }
}
