@import "../../assets/scss/variables.scss";

.panel-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  justify-content: flex-end;

  .panel {
    width: 50%;
    background-color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;

    .panel-navbar {
      display: flex;
      align-items: center;
      padding: 17px 16px 17px 16px;
      border-bottom: 1px solid $light-gray-border-color;
      color: white;
      background-color: black;
      h1 {
        flex-grow: 1;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: white;
      }
      .chevron-left {
        width: 24px;
        height: 24px;
        background-image: url("../../assets/images/chevron-left.svg");
        background-size: contain;
        cursor: pointer;
      }
    }

    .panel-body {
      padding: 16px;
      height: 100%;
      max-height: 100%;
      overflow: auto;

      &.algolia-search {
        .ais-SearchBox-form {
          margin-bottom: 24px;

          button {
            display: none;
          }
        }

        .ais-SearchBox-form > input {
          font-family: "HKGrotesk";
          height: 40px;
          padding: 0px 16px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          -webkit-appearance: textfield;
          box-sizing: content-box;
          font-size: 15px;
          color: $black-color;
          width: 100%;
          width: calc(100% - 34px);

          &:focus {
            outline: none;
          }
        }
      }

      .panel-cont {
        align-items: center;
        margin-bottom: 24px;

        .dropdown,
        input,
        button,
        .dropdown-trigger {
          justify-content: start;
          height: 40px;
          width: 100%;
          margin-bottom: 0px;
        }

        .btn-dark {
          border: 0px;
          color: white;
          background-color: black;
          font-family: "HKGrotesk";
          cursor: pointer;
        }

        .dropdown {
          margin-bottom: 17px;
          .button {
            border-color: black;
          }
        }
        .dropdown-menu {
          width: 100%;
        }
      }

      .input {
        border-color: black;
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: $light-gray-border-color;
      }

      .company-users {
        margin-top: 32px;
        margin-bottom: 24px;

        .company-user {
          display: flex;
          align-items: center;
          height: 88px;
          border-bottom: 1px solid $light-gray-border-color;

          .circle {
            width: 16px;
            height: 16px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
            margin-right: 16px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            .selected {
              width: 8px;
              height: 8px;
              background-color: $green-color;
              opacity: 0;
              border-radius: 50%;
            }

            &.active {
              border-color: $green-color;

              .selected {
                opacity: 1;
              }
            }
          }

          .company-user-image {
            width: 48px;
            height: 48px;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            margin-right: 16px;
          }

          .company-user-info {
            cursor: pointer;

            h3 {
              color: $black-color;
              font-size: 15px;
              font-weight: 600;
            }

            p {
              color: $gray-color;
              font-weight: 400;
            }
          }
        }
      }
    }

    .panel-footer {
      margin-top: auto;
      padding: 17px 27px 17px 27px;
      border-top: 1px solid $light-gray-border-color;
      height: 59px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn.btn-green {
        margin-left: 16px;
      }
    }
  }
}
