.dropdown.select-dropdown-main {
  .dropdown-menu {
    overflow: visible;
    .dropdown-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .searchbar {
      margin: 18px;
    }
  }
}

.treble-popover {
  width: 300px;
}
