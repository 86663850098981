h1 {
  font-weight: bold;
  font-size: 55px;
  line-height: 57px;
}

h2 {
  font-weight: bold;
  font-size: 34px;
  line-height: 38px;
}

h3 {
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
}

h4 {
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
}

h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}
