.time-dropdown {
  .dropdown-trigger {
    button {
      height: 33px;
    }
    &:focus {
      border-color: black;
    }
  }
  &.is-active {
    .dropdown-trigger {
      button {
        border-color: black;
      }
    }
  }

  .dropdown-content {
    padding: 16px;
    width: 200px;
    input {
      border: none;
      outline: none;
      width: 24px;
    }
    hr {
      margin: 12px 0px;
    }

    .time-dropdown-units {
      .dropdown-content {
        display: none;
        width: 110px;
        padding: 8px;
        .dropdown-item {
          text-transform: lowercase;
          text-align: center;
          height: 18px;
          padding: 0;
          justify-content: center;
        }
      }
      &.is-active {
        .dropdown-content {
          display: block;
        }
      }
    }

    .select-dropdown-main {
      .dropdown-trigger {
        button {
          width: fit-content;
          border: none;
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .actions {
      display: flex;
      button {
        margin-left: auto;
        background-color: black;
        border-radius: 3px;
        color: white;
        border: none;
        font-size: 16px;
      }
    }
  }
}
