@import "../../styles/rootStyles.scss";

$assets_path: "../../assets/images";
$assets_icons: "../../assets/icons";

.agents-root {
  display: flex;
  height: 100%;
  min-height: 100%;

  &.background {
    .main-content {
      background-color: #f8f8f8;
    }
  }

  .main-content {
    margin-left: 0px;
    @media (min-width: $desktop) {
      margin-left: 76px;
    }
    &.full-screen {
      margin-left: 0px;
      width: -webkit-fill-available;
    }
    .general-modal {
      .notification-modal {
        .icon {
          background: #ffffff;
        }
        .modal-content {
          width: 926px;
          background: #1a1a1a;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: #2b2b2b;
          }
          &::-webkit-scrollbar-track {
            background: #1a1a1a;
            border-radius: 800px;
          }
          .modal-header {
            height: 106px;
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
          }
          .modal-body {
            padding: 34px 53px 31px 53px;
            border-top: 1px solid rgba(49, 49, 49, 0.4);
            border-bottom: 1px solid rgba(49, 49, 49, 0.4);
            color: #d1d1d1;
            .arrow {
              width: 56px;
              height: 56px;
              background-image: url(#{$assets_path}/modal-notification-arrow.svg);
            }
            .step {
              position: relative;
              .arrow,
              .text {
                position: absolute;
              }
              &--one {
                margin-bottom: 114px;
                .image {
                  width: 322px;
                  height: 80px;
                  background-image: url(#{$assets_path}/browser-padlock.svg);
                }
                .arrow {
                  left: 140px;
                  margin-top: 10px;
                }
                .text {
                  left: 215px;
                  margin-top: 52px;
                }
              }
              &--two {
                margin-bottom: 50px;
                .image {
                  width: 322px;
                  height: 241.92px;
                  background-image: url(#{$assets_path}/browser-site-options.svg);
                }
                .arrow {
                  left: 337px;
                  top: 86px;
                  transform: rotate(-75deg);
                }
                .text {
                  left: 410px;
                  top: 60px;
                }
              }
              &--three {
                .image {
                  width: 543px;
                  height: 32px;
                  background-image: url(#{$assets_path}/browser-reload.svg);
                }
                .arrow {
                  left: 472px;
                  top: -68px;
                  transform: rotate(-190deg) scaleX(-1);
                }
                .text {
                  top: -90px;
                  left: 534px;
                }
              }
            }
            .extra {
              margin-top: 42px;
              font-style: italic;
            }
          }
          .modal-footer {
            height: 101px;
            display: flex;
            justify-content: center;
            .button {
              width: 194px;
              height: 47px;
              font-weight: 600;
              background: #5ab978;
              border-radius: 4px;
              border: none;
              color: #ffffff;
            }
          }
        }
      }
      .end-all-chats-modal {
        &.hubspot {
          .modal-content {
            .modal-footer {
              display: none;
            }
          }
        }

        .modal-content {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: max-content;
          padding: 60px 130px;

          @media (max-width: $tablet - 1) {
            padding: 84px 16px;
          }

          .icon-close {
            display: none;
            @media (max-width: $tablet - 1) {
              display: block;
            }
          }

          &::-webkit-scrollbar {
            width: 7px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 800px;
            background: #9dacb9;
          }
          .modal-header {
            @media (max-width: $tablet - 1) {
              padding: 0px 50px;
            }
            height: fit-content;
            display: block;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            .emojis {
              font-size: 45px;
              margin-bottom: 22px;
            }
            .icon {
              display: none;
            }
          }
          .modal-body {
            @media (max-width: $tablet - 1) {
              width: 100%;
              padding: 0;
            }

            max-width: 528px;
            padding: 0px;
            padding-top: 24px;
            text-align: center;
            font-size: 18px;
            line-height: 31px;
            flex-grow: 1;
          }
          .modal-footer {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 0;
            .button {
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              &.end-all-chats {
                width: 266px;
                height: 47px;
                font-weight: 400;
                font-size: 18px;
                border-radius: 4px;
                color: #ffffff;
                background: #6464ff;
                &:hover {
                  background: rgba(100, 100, 255, 0.8);
                }
              }
              &.cancel {
                width: fit-content;
                background: transparent;
                font-weight: 500;
                color: #262626;
                &:hover {
                  color: #5087ff;
                }
              }
            }
          }
        }
      }
    }
  }

  .user-logo {
    position: relative;
    cursor: pointer;
    margin-top: 11px;
    margin-bottom: 12px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #303030;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #6464ff;
    }
    .state {
      width: 12px;
      height: 12px;
      position: absolute;
      left: 33px;
      top: 30px;
      border: 2px solid #1a1a1a;
      border-radius: 50%;
      background: #303030;
      &.active {
        background: #5ab978;
      }
    }
  }

  .agent-options {
    &.end-all-chats {
      .dropdown {
        .dropdown-menu {
          &.direction-right {
            top: 113px;
          }
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        box-shadow: 0px 2px 8px rgba(106, 170, 170, 0.15);
        width: 267px;
        &.direction-right {
          left: 0px;
          transform: translateY(-55%);
          top: 93px;
        }
        .dropdown-content {
          border-radius: 2px;
          box-shadow: 0px 2px 8px rgba(106, 170, 170, 0.15);
          .dropdown-item:first-child {
            padding-top: 10px;
            padding-bottom: 16px;
            border-bottom: 1px solid #e9e9e9;
            &:hover {
              background: none;
            }
          }
          .dropdown-item:nth-child(2) {
            padding-top: 20px;
          }
          .dropdown-item:last-child {
            padding-bottom: 15px;
          }
          .dropdown-item {
            color: #3c5a73;
            text-transform: none;
            padding: 11px;
            &:hover {
              background: none;
              color: #000000;
              .bell-notification,
              .exit {
                .icon {
                  background: #6464ff;
                }
              }
              .exit {
                color: #6464ff;
              }
            }
            .platform-options {
              font-size: 15px;
              line-height: 16px;
              display: flex;
              flex-direction: row;
              .icon {
                width: 16px;
                height: 16px;
                margin-right: 19.66px;
                margin-left: 14px;
              }
            }
            .bell-notification,
            .exit {
              .icon {
                background: #3c5a73;
              }
            }
          }
        }
      }
    }
  }

  .control-bar {
    z-index: 1000;
    position: fixed;
    min-height: 100%;
    height: 100%;
    width: 76px;
    background-color: #1a1a1a;

    display: none;
    @media (min-width: $desktop) {
      display: flex;
    }
    flex-direction: column;
    align-items: center;

    &.hide {
      display: none;
    }

    .control-bar-items {
      display: flex;
      flex-direction: column;
      width: 100%;

      .control-bar-item {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        .icon {
          background: #b2b2b2;
        }

        &:hover {
          .icon {
            background: #404040;
          }
        }

        &.active {
          .icon {
            background: #ffffff;
          }
        }

        .control-bar-icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    .notifications {
      margin-top: auto;
      width: 100%;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }

    .not-show-updates {
      display: none !important;
    }

    .nubo-trigger {
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: -webkit-fill-available;
      height: 54px;
      cursor: pointer;

      .bell-icon {
        width: 17px;
        height: 22px;
        background-image: url($assets_icons + "/bell.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }

      .nubo-counter {
        position: absolute;
        font-size: 0;
        min-width: 9px;
        width: 9px;
        min-height: 9px;
        height: 9px;
        padding: 0;
        margin: 0 0 16px 14px;
      }
    }

    .languages {
      margin-bottom: 32px;
      margin-top: 10px;
      .icon {
        cursor: pointer;
      }
    }
  }

  .navbar-desktop {
    width: 100%;
    min-height: 59px;
    box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
    display: none;
    @media (min-width: $desktop) {
      display: flex;
    }
    align-items: center;

    .navbar-title {
      margin-left: 30px;
      font-size: 18px;
      font-weight: 500;
      color: black;
    }

    .user-dropdown {
      margin-right: 22px;
      display: flex;
      align-items: center;
      margin-left: auto;
      color: black;

      .user-profile {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #9b9bff;
        margin-right: 8px;
        background-image: url("../../assets/images/user.svg");
        background-size: contain;
      }

      p {
        color: black;
      }

      .button {
        padding: 0px;
        border: none;
        background-color: transparent;
      }
    }

    .user-state {
      display: flex;
      align-items: center;
      border-left: 1px solid #e9e9e9;
      height: 100%;
      // width: 151px;

      p {
        padding-left: 29px;
        margin-right: 7px;
        color: black;
      }

      .switch {
        height: 17px;
        width: 34px;
        border-radius: 10px;
        background-color: #e3e4e5;
        margin-right: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .circle {
          width: 13px;
          height: 13px;
          background-color: #bcbec0;
          border-radius: 50%;
          margin-left: 3px;
          transition:
            margin-left 0.5s,
            background-color 0.5s;
        }

        &.active {
          background-color: $green-color;

          .circle {
            margin-left: 17px;
            background-color: white;
          }
        }
      }
    }
  }

  .navbar-mobile {
    height: 80px;
    display: flex;
    @media (min-width: $desktop) {
      display: none;
    }
    &.hide {
      @media (min-width: 767px) {
        display: none;
      }
    }
    align-items: center;
    background: #1a1a1a;
    padding: 13px 20px;
    .treble-logo {
      width: 30px;
      height: 30px;
      background-position: center center;
      background-size: contain;
    }
    .treble-label {
      font-size: 22px;
      margin-left: 5px;
      line-height: 1;
      color: white;
      margin-right: auto;
      flex-grow: 1;
    }
    .agent-options {
      .dropdown-menu {
        transform: translate(-83%, -44%) !important;
      }
    }
  }
}

.ant-modal-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 4, 0.7);
}
