@import "../../styles/rootStyles.scss";

$assets_path: "../../assets/images";
$assets_icons: "../../assets/icons";

$color-bubble-user: #f5f5f5;
$color-bubble-treble: #dce7eb;

.chat-main-window {
  max-height: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #9dacb9;
    border-radius: 800px;
    height: 96px;
  }
  &::-webkit-scrollbar-track {
    background: #f4f6fa;
    border-radius: 0px 10px 0px 0px;
  }

  .pill-container {
    display: flex;
    justify-content: center;
    .history-pill {
      cursor: pointer;
      background-color: #272727;
      color: #ffffff;
    }
    .up-to-date-pill {
      background-color: #c5f8c6;
      color: #2d855a;
    }
  }

  .chat-info {
    text-align: center;
    span {
      background: rgba(245, 245, 245, 0.45);
      border-radius: 10px;
      padding: 5px 10px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .chat-messages {
    hr {
      height: 1px;
      background-color: #3c5a73;
      width: 100%;
      margin: 30px 0;
      position: relative;
      top: 10px;
      z-index: 1;
    }

    div.new-date-label {
      display: block;
      text-align: center;
      margin: auto;
      padding: 0 15px;
      top: 36px;
      transform: translateY(-160%);
      position: sticky;
      background: transparent;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
      z-index: 2;
      width: fit-content;
      background-color: $white;
      color: #3c5a73;
      border-radius: 10px;
    }

    div.conversation-event {
      min-height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f9f9ff;
      line-height: 21px;
      font-weight: 500;
      margin: 10px 0px;
      padding: 16px;

      div.chat-info {
        div {
          color: #9dacb9;
          a {
            color: #9c9cff;
            &:hover {
              color: #000000;
            }
          }
          span.conversation-tag {
            color: #5ab978 !important;
            padding: 0 !important;
          }
        }
      }

      &.hubspot {
        .chat-info {
          div {
            color: #f3773a;
          }
        }
      }
    }

    .message-row {
      display: flex;
      flex-direction: column;
      padding: 10px 27px;
      white-space: pre-wrap;
      word-break: break-word;

      .text-overflow {
        max-width: 230px;
        overflow: hidden;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dropdown-options {
        display: none;
        &.show {
          display: block;
        }
        .dropdown {
          button {
            height: 22px;
            width: 25px;
            z-index: 1;
            background: transparent;
            border-color: transparent;
          }
          .dropdown-menu {
            z-index: 100;
          }
          .dropdown-content {
            ul {
              li {
                padding-left: 18px;
                padding-top: 10px;
                padding-bottom: 10px;
                cursor: pointer;
              }
              li:hover {
                background-color: #f5f5f5;
              }
            }
          }
        }
      }
    }
  }
}

.notes-container {
  padding: 24px 9px 24px 24px;
  .note-container {
    padding: 15px;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    margin-bottom: 16px;
    background: #fffbe5;

    h2 {
      font-size: 15px;
      line-height: 21px;

      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;

      margin-bottom: 14px;
    }

    .note-content {
      font-size: 15px;
      line-height: 21px;
      word-break: break-word;
      color: #000000;
      margin-bottom: 14px;
    }

    .note-date {
      font-size: 12px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
