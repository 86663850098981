@import "./assets/scss/classes";
@import "./assets/scss/fonts";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "HKGrotesk";
}

html,
body,
#root {
  height: 100%;
}

.input-text {
  font-size: 15px;
  width: 100%;
  height: 40px;
  box-shadow: none;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-family: "HKGrotesk";
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  &.show {
    opacity: 0.5;
  }
}

.treble-component-inline-alert {
  margin: 8px 0px;
}

.treble-component-modal {
  background: rgba(10, 11, 43, 0.5);

  @import "./styles/rootStyles.scss";
  &.show {
    opacity: 1;
  }
  &.modal {
    z-index: 1050;

    div.modal-dialog {
      &.default,
      .large {
        max-width: 100% !important;
      }
      &-centered {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .modal-content {
        font-family: $family-primary !important;
        margin: 0px auto;
        background-color: #ffffff;
        width: 448px;
        overflow-y: auto;
        p {
          font-family: $family-primary !important;
        }
        button {
          font-family: $family-primary !important;
        }
        .alert-title {
          p {
            line-height: 23px;
          }
        }
        .flag-span {
          display: flex;
          flex-direction: row;
          .flag {
            width: 20px;
            height: 16px;
          }
          p {
            line-height: 18px;
            margin-left: 4px;
          }
        }

        .transfer-agent-dropdown {
          width: 100%;
          .dropdown-trigger {
            width: 100%;
            button {
              width: 100%;
            }
          }
          .dropdown-menu {
            width: 100%;
            .dropdown-item {
              &:active {
                color: black;
              }
            }
          }
        }

        .search-container {
          margin: 16px 0;
          color: $white-ter;
          display: flex;
          border: none;
          background-color: #f3f3f3;
          height: 40px;

          &:focus-within {
            outline: 1px solid #393939;
          }
          span {
            height: 100%;
          }

          .icon--search {
            margin: 12px 4px 0px 8px;
          }

          input {
            height: 40px;
            font-family: $family-primary;
            background-color: transparent;
            color: $black;
            border: none;
            &:focus {
              outline: none;
              border: none;
              box-shadow: none;
            }
          }
        }

        .company-users {
          margin-top: 24px;
          .company-user {
            display: flex;
            align-items: center;
            height: 88px;
            border-bottom: 1px solid $light-gray-border-color;

            .circle {
              width: 16px;
              height: 16px;
              border: 1px solid #d9d9d9;
              border-radius: 50%;
              margin-right: 16px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;

              .selected {
                width: 8px;
                height: 8px;
                background-color: $green-color;
                opacity: 0;
                border-radius: 50%;
              }

              &.active {
                border-color: $green-color;

                .selected {
                  opacity: 1;
                }
              }
            }

            .company-user-image {
              width: 48px;
              height: 48px;
              border-radius: 5px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              margin-right: 16px;
            }

            .company-user-info {
              cursor: pointer;

              h3 {
                color: $black-color;
                font-size: 15px;
                font-weight: 600;
              }

              p {
                color: $gray-color;
                font-weight: 400;
              }
            }
          }
        }

        .missing {
          border: 1px solid $red;
        }
        .modal-footer {
          display: flex;
          justify-content: flex-end;
          button {
            cursor: pointer;
            &.treble {
              background-color: $black;
              color: $white;
            }
            &.danger {
              padding-left: 0px;
              margin-right: auto;
              margin-left: 0px;
              background-color: white;
              color: $red;
              .delete-container {
                display: flex;
                flex-direction: row;

                p {
                  line-height: 22px;
                  margin-left: 8px;
                }
              }
              .icon {
                filter: brightness(0) saturate(100%) invert(57%) sepia(68%)
                  saturate(2769%) hue-rotate(326deg) brightness(95%)
                  contrast(98%);
              }
            }
          }
        }
      }
    }
    @media (max-width: $desktop) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 0;

      @media (min-width: $tablet) {
        &.fade {
          &.show {
            .modal-dialog {
              transition: all 1s linear;
              right: 0;
              left: 0;
            }
          }
        }
      }

      div.modal-dialog {
        &-centered {
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          transform: none;
          @media (min-width: $tablet) {
            left: 800px;
          }
        }

        height: 100vh;
        .modal-content {
          width: 100%;
          @media (min-width: $tablet) {
            width: 500px;
            margin-right: 0px;
            margin-left: auto;
          }
          max-height: none;
          height: 100vh;
          padding: 0px;
          margin: 0px 0px 0px 0px;
          .modal-header {
            .modal-title {
              font-weight: 600;
              font-size: 15px;
            }
            flex-direction: row-reverse;
            justify-content: flex-end;
            height: 56px;
            background-color: $black;
            color: $white;
            padding: 16px 8px 16px 26px;
            .close-button {
              margin-left: 0;
              margin-right: 16px;
            }
          }
          .modal-body {
            padding: 24px 20px 0px 20px;
          }
          .modal-footer {
            margin: 8px 20px 0px 20px;
            padding: 0;
            button.default {
              display: none;
            }
            button.treble {
              position: absolute;
              top: 10px;
              right: 16px;
            }
          }
        }
      }
    }
  }
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

$purple-color: #9b9bff;
$green-color: #5ab978;

.btn {
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  p {
    font-size: 15px;
  }

  &.btn-cancel {
    border: 1px solid #d9d9d9;

    p {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &.btn-green {
    border: 1px solid $green-color;
    background-color: $green-color;

    p {
      color: white;
    }
  }

  &.btn-dark {
    background: #000000;
    border-radius: 2px;
    p {
      color: white;
    }
  }

  &.btn-inverse {
    background-color: white;

    p {
      color: $green-color;
    }
  }
}

.dropdown {
  .dropdown-trigger {
    button {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .dropdown-menu {
    .dropdown-content {
      .dropdown-item {
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
          background-color: #f4f6fa;
        }
      }
    }
  }
}

#popover-emoji {
  z-index: 1050;
  inset: -60px auto auto -238px !important;
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

.columns {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.columns:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .container.is-fluid {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.Toastify {
  .Toastify__toast-container {
    width: max-content;
    .Toastify__toast {
      min-height: 48px;
      background-color: #000000;
      &.Toastify__toast--error {
        background-color: #ff533a;
      }
      font-family: hk-grotesk;
      color: #ffffff;
      line-height: 24px;
      size: 18px;
      font-weight: 500;
      svg {
        height: 16px;
        width: 16px;
        margin-right: 8px;
        fill: #ffffff;
      }
      button {
        align-self: center;
        height: 21px;
        margin-left: 8px;
        svg {
          margin: 0px;
        }
      }
    }
  }
}