@import "../../../../styles/rootStyles.scss";

.snippet-modal {
  .modalv2 {
    .modal-content {
      width: -webkit-fill-available;
      max-width: 729px;
      border-radius: 4px;
      .modal-header {
        color: #262626;
        font-size: 22px;
        font-weight: 600;
        line-height: 34px;
        .icon {
          background: #262626;
        }
      }
      .modal-body {
        border-top: 1px solid #dfe1e6;
        font-size: 16px;
        padding: 28px 36px 0px 36px;
        .editor-container {
          margin-bottom: 0;
          font-weight: 400;
          &:focus-within {
            .textarea-wrapper {
              outline: 1px solid #6464ff;
            }
          }
          .textarea-wrapper {
            margin: 0;
            border-radius: 4px;
            .toolbar-class {
              height: 56px;
              padding: 0;
              background: #ffffff;
              border-top: 1px solid #dfe1e6;
              .rdw-inline-wrapper {
                margin-bottom: 0px;
                margin-left: 15px;
              }
              .rdw-option-center {
                margin-top: 14px;
              }
              .rdw-option-wrapper {
                cursor: pointer;
                background: #ffffff;
                .icon {
                  width: 20px;
                  height: 20px;
                }
                &:hover {
                  background: #fafafa;
                }
              }
              .bracket-vars {
                order: -1;
                border-right: 1px solid #dfe1e6;
                .var {
                  font-weight: 500;
                }
              }
            }
          }
        }
        p {
          color: #767676;
          margin-bottom: 5px;
        }
        input {
          border: 1px solid #dfe1e6;
          box-sizing: border-box;
          border-radius: 4px;
        }

        .name-tag-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 39px;
          .name {
            display: block;
            margin-bottom: 0px;
            max-width: 383px;
            width: -webkit-fill-available;
            margin-right: 18px;
            input {
              width: 100%;
              height: 56px;
              color: #000000;
              font-size: 16px;
              line-height: 22px;
              padding-left: 22px;
              &:focus {
                outline: 1px solid #6464ff;
              }
            }
          }
          .tags {
            display: block;
            margin: 0;
            .dropdown {
              .button {
                width: 256px;
                height: 56px;
                border: 1px solid #dfe1e6;
                box-sizing: border-box;
                border-radius: 4px;
                .item {
                  color: #262626;
                  font-size: 16px;
                }
              }
              .dropdown-content {
                width: 256px;
                .item {
                  font-size: 14px;
                  .icon {
                    width: 15px;
                    height: 15px;
                    background: #000000;
                    -webkit-mask-size: contain;
                  }
                }
                .create-tag {
                  height: 36px;
                  padding-left: 9px;
                  margin-bottom: 19px;
                  display: flex;
                  align-items: center;
                  input {
                    height: inherit;
                    width: 161px;
                    margin-right: 8px;
                    font-size: 12px;
                    padding-left: 13px;
                    &:focus {
                      outline: 1px solid #6464ff;
                    }
                  }
                  .button {
                    width: 69px;
                    font-size: 12px;
                    color: #ffffff;
                    background: #b3b3b3;
                    display: flex;
                    justify-content: center;
                    padding: 0;
                    height: inherit;
                    &--black {
                      background: #272727;
                    }
                  }
                }
              }
            }
          }
        }
        .textarea-wrapper {
          .emoji {
            margin-right: 10px;
          }
        }
        .character-counter {
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-left: auto;
          font-size: 13px;
          color: #767676;
        }
      }
      .modal-footer {
        justify-content: flex-end;
        a {
          color: #262626;
        }
        .button {
          height: 3em;
          margin-left: 35px;
          font-weight: 400;
          border: none;
          color: #ffffff;
          background: #6464ff;
          padding: 0px 27px;
        }
      }
    }
  }
}
